import React, { useState } from 'react';
import ConfirmModal from 'components/modal/Confirm';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';
import Api from 'api';
import { selectActiveYardCase } from 'store/yardCase/selectors';
import { useDispatchBind } from 'hooks';
import { pushModal } from 'store/modal/actions';
import { useDispatch, useSelector } from 'react-redux';
import ErrorModal from 'components/modal/Error';
import {
  setActiveYardCase,
  setYardCaseEditStatus,
} from 'store/yardCase/actions';
import YardCaseEditStatus from 'store/yardCase/YardCaseEditStatus';
import { copyJSON } from 'utils';
import TextArea from 'components/inputs/TextArea';
import YardCaseCommentModel from 'gen/YardCaseCommentModel';
import CommentHeaderType from 'gen/CommentHeaderType';
import { makeSelectTemplates } from 'store/basicData/selectors';

const MyModal = styled(ConfirmModal)`
  align-items: stretch;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: ${Sizes.padding.medium}px;
  border-color: ${Colors.background.mainDark};
`;

const CancelYardCaseModal: React.SFC = () => {
  const [cancelComment, setCancelComment] = useState('');

  const yardCase = useSelector(selectActiveYardCase);
  const templates = useSelector(makeSelectTemplates);

  const dispatch = useDispatch();
  const onSetEditStatus = useDispatchBind(setYardCaseEditStatus, dispatch);
  const setYardCase = useDispatchBind(setActiveYardCase, dispatch);
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const handleCancelYardCase = async () => {
    if (!yardCase) return;

    onSetEditStatus(YardCaseEditStatus.blockedSaving);
    try {
      const newComment = copyJSON<YardCaseCommentModel>(
        templates!.yardCaseCommentModel
      );
      newComment.commentHeaderType = CommentHeaderType.CancelCase;
      newComment.text = cancelComment;

      const updatedCase = await Api.cancelYardCase(yardCase.id, newComment);
      setYardCase(updatedCase);
      onSetEditStatus(YardCaseEditStatus.saved);
    } catch (err) {
      onPushModal(
        <ErrorModal exception={err}>
          Ett fel inträffade när uppdraget skulle makuleras.
        </ErrorModal>
      );
      onSetEditStatus(YardCaseEditStatus.formInvalid);
    }
  };

  return (
    <MyModal
      title="Makulera uppdraget"
      confirmLabel="Makulera uppdrag"
      cancelLabel="Avbryt"
      onConfirm={handleCancelYardCase}
      disableConfirm={!(cancelComment.trim().length > 0)}
    >
      <InputWrap>
        Makuleringskommentar
        <TextArea
          value={cancelComment}
          onChange={(eve) => setCancelComment(eve.target.value)}
        />
      </InputWrap>
    </MyModal>
  );
};

export default CancelYardCaseModal;
