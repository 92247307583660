import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';

export const InputRowsContainer = styled.div`
  margin-bottom: ${Sizes.padding.large}px;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${Sizes.padding.medium}px;
  border-color: ${Colors.background.mainDark};
`;
