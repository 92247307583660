import React, { ReactInstance } from 'react';
import styled, { css, keyframes } from 'styled-components';
import ReactDOM from 'react-dom';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';

const Anchor = styled.div`
  height: 0;
  width: 100%;
`;

interface IWrapper {
  left?: number;
  right?: number;
  top: number;
  width?: number;
  height?: number;
}

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const FloatyWrapper = styled.div<IWrapper>`
  overflow: hidden;
  z-index: 10;
  position: fixed;
   ${({ left }: IWrapper) =>
     left &&
     css`
       left: ${left}px;
     `}
   ${({ right }: IWrapper) =>
     right &&
     css`
       right: calc(100vw - ${right}px);
     `}
  top: ${({ top }) => top}px;
  height: ${({ height }) => height}px;
  text-align: left;
  font-weight: normal;
  font-size: small;
  background-color: ${Colors.background.mainLight};
  box-shadow: ${Colors.boxShadow.modal};
  border-radius: ${Sizes.radius.inputElement}px;
  animation: ${fadeIn} 0.2s;

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `};
`;

interface PropTypes {
  children: React.ReactNode;
  visible: boolean;
  fitChildren?: boolean;
  /** only useful if fitChildren == true */
  alignRight?: boolean;

  ignoreClickOutsideClassName?: string;
  onClickOutside(): void;
  onClickInside?(): void;
}

class DropdownSection extends React.Component<PropTypes> {
  anchorRef: React.RefObject<HTMLDivElement> = React.createRef();
  floatyRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: PropTypes) {
    super(props);
    this.handleClickOutSide = this.handleClickOutSide.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutSide);
  }

  componentWillUnmount() {
    document.addEventListener('mouseup', this.handleClickOutSide);
  }

  handleClickOutSide(event: MouseEvent) {
    if (this.floatyRef.current) {
      const domNode = ReactDOM.findDOMNode(this.floatyRef.current);

      const eventTarget = event.target;
      if (eventTarget instanceof Element) {
        // ignore clicks from element with parent that has specified classname
        if (
          this.props.ignoreClickOutsideClassName &&
          eventTarget.closest(`.${this.props.ignoreClickOutsideClassName}`)
        ) {
          return;
        }

        if (!domNode!.contains(eventTarget)) {
          this.props.onClickOutside();
        } else if (this.props.onClickInside) {
          this.props.onClickInside();
        }
      }
    }
  }

  render() {
    const { children, visible, fitChildren, alignRight } = this.props;

    let parentPos = null;
    if (visible && this.anchorRef.current) {
      parentPos = this.anchorRef.current.getBoundingClientRect();
    }

    return (
      <React.Fragment>
        <Anchor ref={this.anchorRef}>
          {parentPos && (
            <FloatyWrapper
              left={!alignRight ? parentPos.left - 5 : undefined}
              right={alignRight ? parentPos.right + 5 : undefined}
              top={parentPos.bottom + 5}
              width={fitChildren ? undefined : parentPos.width + 10}
              ref={this.floatyRef}
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
              {children}
            </FloatyWrapper>
          )}
        </Anchor>
      </React.Fragment>
    );
  }
}
export default DropdownSection;
