import React from 'react';
import styled, { css } from 'styled-components';
import { Column, Cell, Head, Rowclickers, RowClicker } from './components';
import Colors from 'constants/Colors';

interface WrapperProps {
  borderColor: string;
  rowHeight: number;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  position: relative;

  ${({ borderColor, rowHeight }) => css`
    border-color: ${borderColor};

    .table-cell,
    .table-rowclicker,
    .table-head {
      height: ${rowHeight}px;
    }

    .table-rowclickers {
      margin-top: ${rowHeight}px;
    }
  `}
`;

export interface CellRendererProps<Obj> {
  rowIndex: number;
  row: Obj;
}

export interface ColumnSetting<Obj extends Object, Attr extends keyof Obj> {
  header: React.ReactChild;
  attribute?: Attr;
  cellRenderer?: React.SFC<CellRendererProps<Obj>>;
  style?: React.CSSProperties;
  formatter?(value: Obj[Attr]): string;
  onHeaderClick?(): void;
}

export interface Props<Obj extends Object> {
  columnSettings: ColumnSetting<Obj, keyof Obj>[];
  rows: Obj[];
  onRowClick?(row: Obj, index: number): void;
  rowHeight?: number;
  borderColor?: string;
  className?: string;
}

const Table = <Obj extends Object>({
  columnSettings,
  rows,
  rowHeight,
  borderColor,
  className,
  onRowClick,
}: Props<Obj>) => {
  const height = rowHeight || 40;
  const borderColour = borderColor || Colors.background.mainDark;

  return (
    <Wrapper
      className={className}
      borderColor={borderColour}
      rowHeight={height}
    >
      {onRowClick && (
        <Rowclickers className="table-rowclickers">
          {rows.map((row, index) => (
            <RowClicker
              className="table-rowclicker"
              key={index}
              onClick={() => onRowClick(row, index)}
            />
          ))}
        </Rowclickers>
      )}
      {columnSettings.map((column: ColumnSetting<Obj, keyof Obj>, index) => (
        <Column className="table-column" style={column.style} key={index}>
          <Head
            onClick={column.onHeaderClick}
            clickable={!!column.onHeaderClick}
            className="table-head"
          >
            {column.header}
          </Head>
          {rows.map((row, index) => (
            <Cell className="table-cell" key={index}>
              {column.attribute
                ? column.formatter
                  ? column.formatter(row[column.attribute])
                  : row[column.attribute]
                : column.cellRenderer &&
                  column.cellRenderer({ rowIndex: index, row })}
            </Cell>
          ))}
        </Column>
      ))}
    </Wrapper>
  );
};

export default Table;
