import React, { useEffect, useState } from 'react';
import Api from 'api';
import SortedTable from 'components/Table/SortedTable';
import YardCasePreviewModel from 'gen/YardCasePreviewModel';
import LoadingSpinner from 'components/LoadingSpinner';
import { useSelector } from 'react-redux';
import {
  makeSelectCustomers,
  makeSelectYardLocations,
  selectBasicData,
} from 'store/basicData/selectors';
import PageHead from 'components/PageHead';
import { useHistory } from 'react-router';
import Routes from 'constants/Routes';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Card from 'components/Card';
import yardCasePreviewColumnSettings from 'utils/yardCasePreviewColumnSettings';
import Container from 'components/Container';

const MyContainer = styled(Container)`
  padding-top: ${Sizes.padding.large}px;
  padding-bottom: ${Sizes.padding.large}px;
`;

const LatestPage: React.SFC = () => {
  const [fetching, setFetching] = useState(true);
  const [latestYardCases, setLatestYardCases] = useState<
    YardCasePreviewModel[]
  >();
  const history = useHistory();

  const customers = useSelector(makeSelectCustomers);
  const locations = useSelector(makeSelectYardLocations);
  const basicData = useSelector(selectBasicData);

  useEffect(() => {
    fetchLatest();
  }, []);

  const fetchLatest = async () => {
    setFetching(true);
    try {
      const response = await Api.getLatestYardCases();
      setFetching(false);
      setLatestYardCases(response);
    } catch (err) {
      setFetching(false);
    }
  };

  if (fetching) {
    return <LoadingSpinner>Hämtar...</LoadingSpinner>;
  } else if (latestYardCases) {
    return (
      <>
        <PageHead left="Senaste uppdragen" />
        <MyContainer>
          <Card>
            <SortedTable
              columnSettings={yardCasePreviewColumnSettings(
                customers,
                locations,
                basicData && basicData.yardCaseStatuses
              )}
              defaultSortingColumn={0}
              sortDescendingByDefault
              rows={latestYardCases}
              onRowClick={(yardCase) =>
                history.push(`${Routes.edit}/${yardCase.id}`)
              }
              borderColor="#ddd"
              rowsPerPage={50}
            />
          </Card>
        </MyContainer>
      </>
    );
  } else {
    return (
      <b>Något gick fel, pröva att ladda om sidan eller kontakta suppport</b>
    );
  }
};

export default LatestPage;
