import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faFile } from '@fortawesome/free-solid-svg-icons';

interface Props {
  fileEnding: string;
}

const FileIcon: React.SFC<Props> = ({ fileEnding }) => {
  switch (fileEnding.replace('.', '').toLowerCase()) {
    case 'png':
    case 'jpeg':
    case 'jpg':
      return <FontAwesomeIcon icon={faImage} />;
    default:
      return <FontAwesomeIcon icon={faFile} />;
  }
};

export default FileIcon;
