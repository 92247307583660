import React, { useRef } from 'react';
import {
  IFormValues,
  IFormValidations,
} from 'components/Form/types/IFormValues';
import { FormApi, AnyObject } from 'final-form';
import YardCaseModel from 'gen/YardCaseModel';
import { IFieldMetaData } from 'components/Form/FormChangeHandler';
import Api from 'api';
import { Dispatch } from 'redux';
import YardCaseType from 'gen/YardCaseType';
import { pushModal } from 'store/modal/actions';
import ValueAssertedCheckedModal from './ValueAssertedCheckedModal';

export interface AdvancedFormLogicParams {
  formValues: IFormValues<keyof YardCaseModel>;
  formValidations: IFormValidations;
  formApi: FormApi<AnyObject>;
  dispatch: Dispatch;
}

/** Put your advanced form logic in here, things that can't be configured in formSections.ts.
 * This class should persist as long as the EditYardCasePage form is mounted, making it fine to cache data in class members.
 */
class AdvancedFormLogic {
  private yardCaseIdsUsingSameExtRef: number[] = [];
  private yardCaseIdsUsingSameRegNumber: number[] = [];

  private _previousFormValues: IFormValues<keyof YardCaseModel> | undefined;
  private _previousFormValidations: IFormValidations | undefined;

  public get previousFormValues() {
    return this._previousFormValues;
  }
  public get previousFormValidations() {
    return this._previousFormValidations;
  }

  /** Reset AdvancedFormLogic to its initial state */
  public reset() {
    this._previousFormValidations = undefined;
    this._previousFormValues = undefined;
    this.yardCaseIdsUsingSameExtRef = [];
    this.yardCaseIdsUsingSameRegNumber = [];
  }

  /** Meant to run after each form change */
  public async run(
    formValues: IFormValues<keyof YardCaseModel>,
    formValidations: IFormValidations,
    formApi: FormApi<AnyObject>,
    dispatch: Dispatch
  ) {
    this.subTypeAsOtherIfTypeIsOther(formValues, formApi);
    this.handleValueAssertedChecked(formValues, dispatch);
    await this.checkExtRefUsage(formValues, formValidations, formApi);
    await this.checkRegNumberUsage(formValues, formValidations, formApi);

    this._previousFormValidations = formValidations;
    this._previousFormValues = formValues;
  }

  private subTypeAsOtherIfTypeIsOther(
    formValues: IFormValues<keyof YardCaseModel>,
    formApi: FormApi<AnyObject>
  ) {
    if (formValues.yardCaseType === YardCaseType.Other) {
      formApi.change('yardCaseSubType', YardCaseType.Other);
    }
  }

  private handleValueAssertedChecked(
    formValues: IFormValues<keyof YardCaseModel>,
    dispatch: Dispatch
  ) {
    if (
      formValues.valueAsserted &&
      this._previousFormValues &&
      !this._previousFormValues.valueAsserted
    ) {
      dispatch(pushModal(<ValueAssertedCheckedModal />));
    }
  }

  /** Make backend call to check if the value in 'externalReference' has been used before */
  private async checkExtRefUsage(
    formValues: IFormValues<keyof YardCaseModel>,
    formValidations: IFormValidations,
    formApi: FormApi<AnyObject>
  ) {
    const currentYardCaseId = Number(formValues.id);
    const previousExtRef =
      this._previousFormValues && this._previousFormValues.externalReference;

    const currentExtRef = formValues.externalReference;
    if (
      currentExtRef === null ||
      currentExtRef === undefined ||
      currentExtRef === ''
    ) {
      this.yardCaseIdsUsingSameExtRef = [];
    } else {
      // only update cache if ext. ref. has changed
      if (previousExtRef !== currentExtRef) {
        this.yardCaseIdsUsingSameExtRef = await Api.idsFromExternalReference(
          String(currentExtRef)
        );
      }
    }

    const filteredIds = this.yardCaseIdsUsingSameExtRef.filter(
      (id) => id !== currentYardCaseId
    );

    if (filteredIds.length > 0) {
      const fieldMetadata: IFieldMetaData = {
        validation: formValidations.externalReference,
      };

      if (filteredIds.length >= 5) {
        fieldMetadata.validation!.warning = `Referensen har använts tidigare i ${filteredIds.length} andra uppdrag.`;
      } else {
        fieldMetadata.validation!.warning =
          'Referensen har använts tidigare i uppdrag ' + filteredIds.join(', ');
      }

      formApi.mutators.setFieldData('externalReference', fieldMetadata);
    }
  }

  /** Make backend call to check if the value in 'regNumber' has been used before */
  private async checkRegNumberUsage(
    formValues: IFormValues<keyof YardCaseModel>,
    formValidations: IFormValidations,
    formApi: FormApi<AnyObject>
  ) {
    const currentYardCaseId = Number(formValues.id);
    const previousRegNumber =
      this._previousFormValues && this._previousFormValues.regNumber;

    const currentRegNumber = formValues.regNumber;
    if (
      currentRegNumber === null ||
      currentRegNumber === undefined ||
      currentRegNumber === ''
    ) {
      this.yardCaseIdsUsingSameRegNumber = [];
    } else {
      // only update cache if regNumber has changed
      if (previousRegNumber !== currentRegNumber) {
        this.yardCaseIdsUsingSameRegNumber = await Api.idsFromRegNumber(
          String(currentRegNumber)
        );
      }
    }

    const filteredIds = this.yardCaseIdsUsingSameRegNumber.filter(
      (id) => id !== currentYardCaseId
    );

    if (filteredIds.length > 0) {
      const fieldMetadata: IFieldMetaData = {
        validation: formValidations.regNumber,
      };

      if (filteredIds.length >= 5) {
        fieldMetadata.validation!.warning = `Registreringsnumret har använts tidigare i ${filteredIds.length} andra uppdrag.`;
      } else {
        fieldMetadata.validation!.warning =
          'Registreringsnumret har använts tidigare i uppdrag ' +
          filteredIds.join(', ');
      }

      formApi.mutators.setFieldData('regNumber', fieldMetadata);
    }
  }
}

const useAdvancedFormLogic = () => {
  const ref = useRef(new AdvancedFormLogic());
  return ref.current;
};

export default useAdvancedFormLogic;
