import React from 'react';
import styled from 'styled-components';
import Colors from 'constants/Colors';
import Container from 'components/Container';

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colors.background.mainDark};
`;

const Header = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  font-size: 20px;
  font-weight: 500;
`;

interface HTPropTypes {
  align: string;
}
const HeaderThirds = styled.div`
  display: inline-block;
  text-align: ${(props: HTPropTypes) => props.align};
  flex: 1;
`;

interface headPropTypes {
  left?: React.ReactChild;
  middle?: React.ReactChild;
  right?: React.ReactChild;
  className?: string;
}

const PageHead: React.SFC<headPropTypes> = ({
  left,
  middle,
  right,
  className,
}) => (
  <Wrapper>
    <Header className={className}>
      <HeaderThirds align="start">{left}</HeaderThirds>
      <HeaderThirds align="center">{middle}</HeaderThirds>
      <HeaderThirds align="end">{right}</HeaderThirds>
    </Header>
  </Wrapper>
);

export default PageHead;
