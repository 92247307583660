import { ApplicationState } from 'store';
import { createSelector } from 'reselect';

export const selectYardCase = (state: ApplicationState) => state.yardCase;

export const selectActiveYardCase = createSelector(
  selectYardCase,
  state => state.activeYardCase
);

export const selectYardCaseEditStatus = createSelector(
  selectYardCase,
  state => state.editStatus
);
