import React, { useState } from 'react';
import { IFormValue, IFormValues } from 'components/Form/types/IFormValues';
import DefaultModal from 'components/modal/Default';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { copyJSON } from 'utils';
import { useDispatch } from 'react-redux';
import { useDispatchBind } from 'hooks';
import { popModal } from 'store/modal/actions';
import IFormSectionField from 'components/Form/types/IFormSectionField';
import Button from 'components/inputs/Button';
import Table from 'components/Table';
import SelectedValueButton from './SelectValueButton';

const MyModal = styled(DefaultModal)`
  padding: ${Sizes.padding.large}px 0;
`;

const SelectAllButtonHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 400px;
  gap: ${Sizes.padding.small}px;
`;

const SelectAllButton = styled(Button)`
  min-width: 0;
`;

interface Props<FieldName extends string = string> {
  formValues: IFormValues<FieldName>;
  conflictingFields: Partial<IFormValues<FieldName>>;
  fieldsByFieldName: Map<FieldName, IFormSectionField>;
  onMergeCompleted(mergedFormValues: IFormValues<FieldName>): void;
  onAbort(): void;
}

const MergeIntoFormModal = <FieldName extends string = string>({
  formValues,
  conflictingFields,
  fieldsByFieldName,
  onMergeCompleted,
  onAbort,
}: Props<FieldName>) => {
  const dispatch = useDispatch();
  const onPopModal = useDispatchBind(popModal, dispatch);

  const [selectedOverrides, setSelectedOverrides] = useState(
    new Set<FieldName>()
  );

  const handleCompleteMerge = () => {
    const newFormValues = copyJSON(formValues);

    selectedOverrides.forEach((selectedOverride) => {
      newFormValues[selectedOverride] = conflictingFields[selectedOverride];
    });

    onMergeCompleted(newFormValues);
    onPopModal();
  };

  return (
    <MyModal
      title="Hantera fältkonflikter"
      buttons={[
        {
          label: (
            <>
              <FontAwesomeIcon icon={faCheck} /> Uppdatera formulär
            </>
          ),
          onClick: handleCompleteMerge,
        },
        {
          label: 'Avbryt',
          onClick: () => {
            onAbort();
            onPopModal();
          },
        },
      ]}
    >
      <p>
        Sökningen gav tillbaka värden som inte kan läggas in i formuläret
        automatiskt. <br /> Välj de värden som du vill behålla genom att klicka
        på dem.
      </p>

      <Table
        columnSettings={[
          {
            header: 'Formulärfält',
            cellRenderer: ({ row: { fieldName } }) => (
              <>{fieldsByFieldName.get(fieldName)?.label ?? fieldName}</>
            ),
          },
          {
            header: (
              <SelectAllButtonHeader>
                Nuvarande värde
                <SelectAllButton
                  smaller
                  onClick={() => {
                    setSelectedOverrides(new Set());
                  }}
                >
                  <FontAwesomeIcon icon={faCheckDouble} /> Välj alla
                </SelectAllButton>
              </SelectAllButtonHeader>
            ),
            cellRenderer: ({ row: { fieldName, originalFieldValue } }) => (
              <SelectedValueButton
                value={originalFieldValue}
                selected={!selectedOverrides.has(fieldName)}
                onClick={() => {
                  const newSelectedOverrides = new Set(selectedOverrides);
                  newSelectedOverrides.delete(fieldName);
                  setSelectedOverrides(newSelectedOverrides);
                }}
                fieldType={fieldsByFieldName.get(fieldName)?.type}
              />
            ),
          },
          {
            header: (
              <SelectAllButtonHeader>
                Värde från VTR/Assist
                <SelectAllButton
                  smaller
                  onClick={() => {
                    setSelectedOverrides(
                      new Set(Object.keys(conflictingFields) as FieldName[])
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faCheckDouble} /> Välj alla
                </SelectAllButton>
              </SelectAllButtonHeader>
            ),
            cellRenderer: ({ row: { fieldName, conflictingFieldValue } }) => (
              <SelectedValueButton
                selected={selectedOverrides.has(fieldName)}
                value={conflictingFieldValue}
                onClick={() => {
                  const newSelectedOverrides = new Set(selectedOverrides);
                  newSelectedOverrides.add(fieldName);
                  setSelectedOverrides(newSelectedOverrides);
                }}
                fieldType={fieldsByFieldName.get(fieldName)?.type}
              />
            ),
          },
        ]}
        rows={Object.entries<IFormValue>(conflictingFields).map(
          ([fieldName, conflictingFieldValue]) => ({
            fieldName: fieldName as FieldName,
            originalFieldValue: formValues[fieldName as FieldName],
            conflictingFieldValue,
          })
        )}
      />
    </MyModal>
  );
};

export default MergeIntoFormModal;
