import styled, { css } from 'styled-components';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';

interface ButtonProps {
  smaller?: boolean;
}

export default styled.button<ButtonProps>`
  position: relative;
  border: none;
  background-color: ${Colors.background.accent};
  color: ${Colors.foreground.accent};
  font-weight: 500;
  border-radius: ${Sizes.radius.inputElement}px;
  box-shadow: ${Colors.boxShadow.element};
  min-width: 100px;

  ${({ smaller }) =>
    smaller
      ? css`
          padding: ${Sizes.padding.nano}px ${Sizes.padding.small}px;
        `
      : css`
          padding: ${Sizes.padding.mini}px ${Sizes.padding.small}px;
        `}

  &:active {
    background-color: ${Colors.background.accentDark};
    color: ${Colors.foreground.accentDark};
  }

  &:focus {
    outline: none;
  }

  &:focus::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid ${Colors.focus};
    border-radius: inherit;
  }

  &:disabled {
    background-color: ${Colors.background.mainDark};
    box-shadow: none;
  }
`;
