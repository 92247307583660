import React from 'react';
import styled from 'styled-components';
import Card from 'components/Card';
import Colors from 'constants/Colors';
import TabView from 'components/TabView';
import Sizes from 'constants/Sizes';
import PageHead from 'components/PageHead';
import CustomersTab from './CustomersTab';
import YardLocationsTab from './YardLocationsTab';
import Container from 'components/Container';
import UsersTab from './UsersTab';
import ExportDataTab from './ExportDataTab';

const MyCard = styled(Card)`
  background-color: ${Colors.background.mainLight};
  margin: ${Sizes.padding.large}px 0;
`;

const AdminPage = () => (
  <>
    <PageHead left="Admin" />
    <Container>
      <MyCard>
        <TabView
          tabsAboveContent
          tabs={[
            {
              label: 'Kunder',
              content: <CustomersTab />,
            },
            {
              label: 'Gårdar',
              content: <YardLocationsTab />,
            },
            {
              label: 'Export',
              content: <ExportDataTab />,
            },
            {
              label: 'Användare',
              content: <UsersTab />,
            },
          ]}
        />
      </MyCard>
    </Container>
  </>
);

export default AdminPage;
