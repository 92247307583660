import YardCaseActionTypes from './ActionTypes';
import YardCaseModel from 'gen/YardCaseModel';
import YardCaseEditStatus from './YardCaseEditStatus';

export const setActiveYardCase = (yardCase: YardCaseModel) =>
  ({
    type: YardCaseActionTypes.SetActiveYardCase,
    yardCase,
  } as const);

export const clearActiveYardCase = () =>
  ({
    type: YardCaseActionTypes.ClearActiveYardCase,
  } as const);

export const setYardCaseEditStatus = (status: YardCaseEditStatus) => ({
  type: YardCaseActionTypes.SetYardCaseEditStatus,
  status,
});
