import React from 'react';
import PageHead from 'components/PageHead';
import Card from 'components/Card';
import styled from 'styled-components';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';
import Button from 'components/inputs/Button';
import Container from 'components/Container';
import TabView from 'components/TabView';
import MonthlyInvoiceTab from './MonthlyInvoiceTab';
import FinalInvoiceTab from './FinalInvoiceTab';

const MyCard = styled(Card)`
  margin-top: ${Sizes.padding.medium}px;
  margin-bottom: ${Sizes.padding.large}px;
`;

const Section = styled.section`
  padding: ${Sizes.padding.small}px;
  border-bottom: 1px solid ${Colors.background.mainDark};
  border-color: ${Colors.background.mainDark};

  &:last-child {
    border-bottom-width: 0;
  }
`;

const MyButton = styled(Button)`
  margin: ${Sizes.padding.mini}px ${Sizes.padding.small}px;
`;

const InvoicePage = () => {
  return (
    <>
      <PageHead left="Underlag" />
      <Container>
        <MyCard>
          <TabView
            tabsAboveContent
            tabs={[
              {
                content: <MonthlyInvoiceTab />,
                label: 'Månadsunderlag',
              },
              {
                content: <FinalInvoiceTab />,
                label: 'Fakturaunderlag',
              },
            ]}
          />
        </MyCard>
      </Container>
    </>
  );
};

export default InvoicePage;
