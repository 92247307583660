import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors, { translucent } from 'constants/Colors';

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 85px);
  padding: ${Sizes.padding.large}px;
  font-size: 15px;
`;

export const TabHeader = styled.h2`
  font-size: 30px;
  margin-bottom: ${Sizes.padding.medium}px;
`;

export const EmptyMessage = styled.div`
  color: ${translucent(Colors.foreground.main)};
  text-align: center;
  margin-bottom: ${Sizes.padding.small}px;
`;
