import React from 'react';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface DotProps {
  active: boolean;
}
const Dot = styled.div<DotProps>`
  margin: ${Sizes.padding.mini}px;
  background-color: ${({ active }) => (active ? '#000' : '#000A')};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

const DotClick = styled.button`
  border: 0;
  padding: 0;
  background: transparent;

  &:focus {
    outline: none;
  }
`;

interface Props {
  pages: number;
  currentPage: number;
  onPageClick?(page: number): void;
}

const PageIndicator: React.SFC<Props> = ({
  pages,
  currentPage,
  onPageClick,
}) => {
  const dots = [];
  for (let i = 0; i < pages; i++) {
    dots.push(
      <DotClick onClick={onPageClick ? () => onPageClick(i) : undefined}>
        <Dot active={i === currentPage} />
      </DotClick>
    );
  }

  return <Wrapper>{dots}</Wrapper>;
};

export default PageIndicator;
