import React, { useState, TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';

type Props = React.HTMLProps<HTMLTextAreaElement>;

function textAreaAdjust(e: HTMLTextAreaElement | null) {
  if (e) {
    e.style.height = '1px';
    e.style.height = `${e.scrollHeight + 10}px`;
  }
}

const AutoSizedTextArea: React.SFC<Props> = props => (
  <textarea
    {...props}
    ref={textAreaAdjust}
    onKeyUp={eve => textAreaAdjust(eve.target as any)}
  />
);

export default AutoSizedTextArea;
