import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectActiveYardCase,
  selectYardCaseEditStatus,
} from 'store/yardCase/selectors';
import Button from 'components/inputs/Button';
import YardCaseStatus from 'gen/YardCaseStatus';
import YardCaseEditStatus from 'store/yardCase/YardCaseEditStatus';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarCrash, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useDispatchBind } from 'hooks';
import FinalizeYardCaseModal from './FinalizeYardCaseModal';
import { pushModal } from 'store/modal/actions';
import YardCaseActionButton from './YardCaseActionButton';

export const ReturnYardCaseButtonText = 'Markera som hämtad';
export const ScrapYardCaseButtonText = 'Markera som skrotad';

interface Props {
  scrapYardCase: boolean;
}

const FinalizeYardCaseButton: React.FC<Props> = ({ scrapYardCase }) => {
  const yardCase = useSelector(selectActiveYardCase);
  const editStatus = useSelector(selectYardCaseEditStatus);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const handleFinalizeYardCase = (eve: React.MouseEvent) => {
    eve.preventDefault();
    eve.stopPropagation();

    onPushModal(<FinalizeYardCaseModal scrapYardCase={scrapYardCase} />);
  };

  if (!yardCase) {
    return null;
  }

  const disableButton = editStatus !== YardCaseEditStatus.saved;

  switch (yardCase.status) {
    case YardCaseStatus.Active:
      return (
        <YardCaseActionButton
          disabled={disableButton}
          onClick={handleFinalizeYardCase}
        >
          {scrapYardCase ? (
            <>
              <FontAwesomeIcon icon={faCarCrash} /> {ScrapYardCaseButtonText}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCheck} /> {ReturnYardCaseButtonText}
            </>
          )}
        </YardCaseActionButton>
      );

    default:
      return null;
  }
};

export default FinalizeYardCaseButton;
