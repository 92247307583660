import YardCasePreviewModel from 'gen/YardCasePreviewModel';
import {
  DeepReadonly,
  ActionReturns,
  ImmutableReducer,
} from 'utils/utilityTypes';
import * as SearchActions from './actions';
import SearchActionTypes from './ActionTypes';

export interface SearchState {
  /** if null, no search has been made yet, empty array == no results */
  results: YardCasePreviewModel[] | null;
  searchString: string;

  enableDateInput: boolean;
  dateSpanStart?: Date;
  dateSpanEnd?: Date;
}

const initialState: DeepReadonly<SearchState> = {
  results: null,
  searchString: '',

  enableDateInput: false,
};

type actions = ActionReturns<typeof SearchActions>;

const searchReducer: ImmutableReducer<SearchState, actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SearchActionTypes.SetResults:
      return { ...state, results: action.results };

    case SearchActionTypes.SetSearchString:
      return { ...state, searchString: action.searchString };

    case SearchActionTypes.SetEnableDateInput:
      return { ...state, enableDateInput: action.enableDateInput };

    case SearchActionTypes.SetDateSpanStart:
      return { ...state, dateSpanStart: action.dateSpanStart };

    case SearchActionTypes.SetDateSpanEnd:
      return { ...state, dateSpanEnd: action.dateSpanEnd };

    default:
      return state;
  }
};

export default searchReducer;
