import React, { useState, useEffect, useRef } from 'react';
import useApiResponse, { RequestStatus } from 'hooks/useApiResponse';
import Api from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { useDispatchBind } from 'hooks';
import ErrorModal from 'components/modal/Error';
import SortedTable from 'components/Table/SortedTable';
import Colors from 'constants/Colors';
import { toUserDateTimeString, gotoLogin } from 'utils';
import LoadingSpinner from 'components/LoadingSpinner';
import { pushModal } from 'store/modal/actions';
import {
  makeSelectUserNames,
  makeSelectCustomers,
} from 'store/basicData/selectors';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/inputs/IconButton';
import DownloadLink from '../DownloadLink';
import CreateMonthlyInvoiceModal from './CreateMonthlyInvoiceModal';
import Rolling from 'components/LoadingSpinner/Rolling';
import {
  selectCreatingMonthlyInvoiceStatus,
  selectMonthlyInvoiceBatches,
} from 'store/invoice/selectors';
import { setMonthlyInvoiceBatches } from 'store/invoice/actions';

const TableAction = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${Sizes.padding.small}px;
  border-color: ${Colors.background.mainDark};
`;

const MyRolling = styled(Rolling)`
  color: ${Colors.background.accent};
  margin: 0 ${Sizes.padding.small}px;
`;

const MonthlyInvoiceTab: React.SFC = () => {
  const users = useSelector(makeSelectUserNames);
  const customers = useSelector(makeSelectCustomers);
  const creatingInvoiceStatus = useSelector(selectCreatingMonthlyInvoiceStatus);
  const monthlyInvoices = useSelector(selectMonthlyInvoiceBatches);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);
  const onSetMonthlyInvoiceBatches = useDispatchBind(
    setMonthlyInvoiceBatches,
    dispatch
  );

  const monthlyInvoicesRequest = useApiResponse(Api.getMonthlyInvoiceBatches, {
    errorMessage: 'Ett fel inträffade när månadsunderlagen skulle hämtas.',
  });

  useEffect(() => {
    if (
      monthlyInvoicesRequest.status === RequestStatus.Fetched &&
      monthlyInvoicesRequest.response
    ) {
      onSetMonthlyInvoiceBatches(monthlyInvoicesRequest.response);
    }
  }, [monthlyInvoicesRequest.status]);

  const handleCreateMonthlyInvoceClick = async () => {
    onPushModal(<CreateMonthlyInvoiceModal />);
  };

  if (!users || !customers) {
    gotoLogin();
    return null;
  }

  return monthlyInvoicesRequest.response &&
    monthlyInvoicesRequest.status === RequestStatus.Fetched ? (
    <>
      <TableAction>
        {creatingInvoiceStatus === RequestStatus.Fetching && (
          <>
            Skapar månadsunderlag... <MyRolling />
          </>
        )}
        <IconButton icon={faPlus} onClick={handleCreateMonthlyInvoceClick}>
          Skapa månadsunderlag
        </IconButton>
      </TableAction>
      <SortedTable
        borderColor={Colors.background.mainDark}
        columnSettings={[
          {
            header: 'Månadsunderlag',
            cellRenderer: ({ row }) => (
              <DownloadLink href={Api.monthlyInvoiceBatchDownloadLink(row.id)}>
                {row.yearMonth}_Fakturaunderlag.zip
              </DownloadLink>
            ),
            sorter: (a, b) => (a.yearMonth > b.yearMonth ? 1 : -1),
          },
          { attribute: 'yearMonth', header: 'För månad' },
          {
            attribute: 'dateCreated',
            header: 'Skapad',
            formatter: (dateStr) =>
              toUserDateTimeString(new Date(dateStr as string)),
          },
          {
            attribute: 'createdByID',
            header: 'Skapad av',
            formatter: (userId: number) => users[userId],
          },
        ]}
        rows={monthlyInvoices.map((mi) => ({
          ...mi,
          yearMonth: `${String(mi.year).padStart(4, '0')}-${String(
            mi.month
          ).padStart(2, '0')}`,
        }))}
        defaultSortingColumn={2}
        sortDescendingByDefault={true}
        rowsPerPage={15}
      />
    </>
  ) : (
    <LoadingSpinner />
  );
};

export default MonthlyInvoiceTab;
