const Routes = {
  register: '/register',
  edit: '/edit',
  search: '/search',
  latest: '/latest',
  invoice: '/invoice',
  admin: '/admin',
  test: '/test',
} as const;

export default Routes;
