import React, { useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import styled from 'styled-components';
import IFormSection from './types/IFormSection';
import FormSection from './FormSection';
import { IFormValues, IFormValidations } from './types/IFormValues';
import { FormApi, AnyObject } from 'final-form';
import FormChangeHandler from './FormChangeHandler';
import IFormSectionField from './types/IFormSectionField';

const _form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface OwnProps {
  formSections: IFormSection[];
  initialValues?: IFormValues;

  autoFocusedField?: IFormSectionField;

  /** Callback for when user starts modifying form state. */
  changeBegin?(): void;
  /** Callback for when user input delay has passed */
  changeEnd(
    formValues: IFormValues,
    formValidations: IFormValidations,
    formApi: FormApi<AnyObject>
  ): void;
}

const Form: React.FunctionComponent<OwnProps> = (props) => {
  const {
    formSections,
    initialValues,
    changeBegin,
    changeEnd,
    autoFocusedField,
  } = props;

  const autoFocusedFieldName = autoFocusedField && autoFocusedField.name;
  useEffect(() => {
    if (autoFocusedFieldName) {
      const inputElements = document.getElementsByName(autoFocusedFieldName);
      if (inputElements.length > 0) {
        inputElements[0].focus();
      }
    }
  }, [autoFocusedFieldName]);

  const formSectionIdProp = (formSection: IFormSection) =>
    `formsection-${formSection.id}`;

  // This runs when form is submitted and all inputs are valid (not used ATM)
  const handleSubmit = (values: IFormValues, FormAPI: FormApi<IFormValues>) => {
    console.log(
      'this runs when form is submitted and all inputs are valid',
      values,
      FormAPI
    );
  };

  return (
    <FinalForm
      onSubmit={handleSubmit}
      mutators={{ setFieldData }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <_form onSubmit={handleSubmit}>
          {formSections.map((formSection, index) => (
            <FormSection
              id={formSectionIdProp(formSection)}
              key={formSection.id}
              formSection={formSection}
              changeBegin={changeBegin}
            />
          ))}
          <FormChangeHandler
            formChanged={changeEnd}
            formSections={formSections}
          />
        </_form>
      )}
    />
  );
};

export default Form;
