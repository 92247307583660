import React, { useState } from 'react';
import PaginatedTable, { Props as TableProps } from './PaginatedTable';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { ColumnSetting } from '.';

const Icon = styled(FontAwesomeIcon)`
  margin-left: auto;
`;

export interface SortedTableColumnSetting<Obj extends Object>
  extends ColumnSetting<Obj, keyof Obj> {
  /** passed to Array.sort() */
  sorter?(a: Obj, b: Obj): number;
}

interface Props<Obj extends Object> extends TableProps<Obj> {
  columnSettings: SortedTableColumnSetting<Obj>[];

  /** index of column that should be sorted by default */
  defaultSortingColumn?: number;
  /** sort descending by default instead of ascending */
  sortDescendingByDefault?: boolean;
}

const SortedTable = <Obj extends Object>({
  columnSettings,
  defaultSortingColumn,
  sortDescendingByDefault,
  rows,
  ...props
}: Props<Obj>) => {
  const [sortingColumn, setSortingColumn] = useState(defaultSortingColumn || 0);
  const [sortAscending, setSortAscending] = useState(!sortDescendingByDefault);

  const safeSortingColumn =
    sortingColumn >= columnSettings.length ? 0 : sortingColumn;

  const sortingAttribute = columnSettings[safeSortingColumn].attribute;
  const formatter =
    columnSettings[safeSortingColumn].formatter || ((value: any) => value);

  const sorter = columnSettings[safeSortingColumn].sorter;
  const sortedRows = sorter
    ? rows.sort((a, b) => sorter(a, b) * (sortAscending ? 1 : -1))
    : sortingAttribute
    ? rows.sort(
        (a, b) =>
          (formatter(a[sortingAttribute]) > formatter(b[sortingAttribute])
            ? 1
            : -1) * (sortAscending ? 1 : -1)
      )
    : rows;

  const handleHeaderClick = (index: number) => {
    if (index === safeSortingColumn) {
      setSortAscending(!sortAscending);

      if (index !== sortingColumn) {
        setSortingColumn(index);
      }
    } else {
      setSortingColumn(index);
    }
  };

  return (
    <PaginatedTable
      columnSettings={columnSettings.map((setting, index) => ({
        ...setting,
        onHeaderClick: () => handleHeaderClick(index),
        header: (
          <>
            {setting.header}
            {
              <Icon
                style={safeSortingColumn === index ? undefined : { opacity: 0 }}
                icon={sortAscending ? faAngleUp : faAngleDown}
              />
            }
          </>
        ),
      }))}
      rows={sortedRows}
      {...props}
    />
  );
};

export default SortedTable;
