import React from 'react';
import Card from 'components/Card';
import styled from 'styled-components';
import Colors, { translucent } from 'constants/Colors';
import Sizes from 'constants/Sizes';
import TabView from 'components/TabView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faFolder } from '@fortawesome/free-solid-svg-icons';
import MetadataTab from './MetadataTab';
import AttachmentsTab from './AttachmentsTab';
import { useSelector } from 'react-redux';
import {
  selectYardCaseEditStatus,
  selectActiveYardCase,
} from 'store/yardCase/selectors';
import YardCaseEditStatus from 'store/yardCase/YardCaseEditStatus';
import { EmptyMessage } from 'components/TabView/components';

const MyCard = styled(Card)`
  position: sticky;
  top: 0;
  width: 33%;
  min-width: 300px;
  max-width: 450px;
  height: calc(100vh - 160px);
  background-color: ${Colors.background.mainLight};
  margin-left: ${Sizes.padding.large}px;
  z-index: 2;
`;

const UnsavedMessage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${translucent(Colors.foreground.mainLight)};
`;

const UnsavedIcon = styled(FontAwesomeIcon)`
  font-size: 100px;
  color: ${Colors.background.mainDark};
  margin-bottom: ${Sizes.padding.large}px;
`;

const SideTray: React.FC = () => {
  const yardCase = useSelector(selectActiveYardCase);
  const editStatus = useSelector(selectYardCaseEditStatus);

  if (
    !yardCase &&
    (editStatus === YardCaseEditStatus.formInvalid ||
      editStatus === YardCaseEditStatus.typing)
  ) {
    return (
      <MyCard>
        <UnsavedMessage>
          <UnsavedIcon icon={faInfoCircle} />
          Fyll i alla rödmarkerade fält för att spara
        </UnsavedMessage>
      </MyCard>
    );
  }

  return (
    <MyCard>
      <TabView
        tabs={[
          {
            label: <FontAwesomeIcon icon={faFolder} />,
            content: <AttachmentsTab />,
          },
          {
            label: <FontAwesomeIcon icon={faInfoCircle} />,
            content: <MetadataTab />,
          },
        ]}
      />
    </MyCard>
  );
};

export default SideTray;
