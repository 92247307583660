import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

interface TabsGrooveProps {
  tabCount: number;
}

const TabsGroove = styled.div<TabsGrooveProps>`
  display: flex;
  flex-direction: row;
  margin: ${Sizes.padding.small}px;
  background-color: ${Colors.background.main};
  border-radius: ${Sizes.radius.element}px;
  flex: 1;
  max-width: ${({ tabCount }) => tabCount * 250}px;
`;

interface TabsGrooveBGProps {
  bottomAlign?: boolean;
}

const TabsGrooveBG = styled.div<TabsGrooveBGProps>`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 85px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${({ bottomAlign }) =>
    bottomAlign
      ? css`
          background: linear-gradient(
            transparent,
            ${Colors.background.mainLight} 40%
          );
          align-items: flex-end;
        `
      : css`
          background: linear-gradient(
            ${Colors.background.mainLight} 40%,
            transparent
          );
          align-items: flex-start;
        `}
`;

interface TabProp {
  selected: boolean;
}
const Tab = styled.div<TabProp>`
  background-color: transparent;
  color: ${Colors.foreground.mainLight};
  border-radius: ${Sizes.radius.element}px;
  flex: 1;
  padding: ${Sizes.padding.mini}px;
  padding-left: 0;
  margin: ${-Sizes.padding.mini}px 0;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.2s, background-color 0.2s;

  ${({ selected }) =>
    selected
      ? css`
          background-color: ${Colors.background.accent};
          color: ${Colors.foreground.accentLight};
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        `
      : css`
          &:hover {
            background: #0000001a;
          }
        `}
`;

const TabSpacer = styled.div<{ index: number; selectedTab: number }>`
  flex: 1;
  margin: ${Sizes.padding.mini * 2}px 0;
  margin-left: 0;
  margin-right: ${Sizes.padding.mini}px;
  padding-right: ${Sizes.padding.mini}px;

  border-right: 1px solid;
  border-color: #0000001a;
  transition: border-color 0.1s;

  &:first-child {
    margin-left: ${Sizes.padding.mini}px;
  }
  &:last-child {
    border-color: transparent;
    margin-right: 0;
  }

  ${({ index, selectedTab }) =>
    (index === selectedTab || index === selectedTab - 1) &&
    css`
      border-color: transparent;
    `}
`;

interface TabSetting {
  label: React.ReactChild;
  content: React.ReactChild;
}

interface Props {
  tabs: TabSetting[];
  /** if the tabs should render above the content of the tabs */
  tabsAboveContent?: boolean;
}

const TabView: React.SFC<Props> = ({ tabs, tabsAboveContent }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <Wrapper>
      {!tabsAboveContent && tabs[selectedTab] && tabs[selectedTab].content}
      <TabsGrooveBG bottomAlign={!tabsAboveContent}>
        <TabsGroove tabCount={tabs.length}>
          {tabs.map((tab, index) => (
            <TabSpacer index={index} selectedTab={selectedTab} key={index}>
              <Tab
                selected={index === selectedTab}
                onClick={() => handleTabClick(index)}
              >
                {tab.label}
              </Tab>
            </TabSpacer>
          ))}
        </TabsGroove>
      </TabsGrooveBG>
      {tabsAboveContent && tabs[selectedTab] && tabs[selectedTab].content}
    </Wrapper>
  );
};

export default TabView;
