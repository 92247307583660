import React, { useState, useEffect, useRef } from 'react';
import Form from 'components/Form';
import { useParams, useHistory, Prompt } from 'react-router';
import Api from 'api';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveYardCase,
  clearActiveYardCase,
  setYardCaseEditStatus,
} from 'store/yardCase/actions';
import LoadingSpinner from 'components/LoadingSpinner';
import { pushModal } from 'store/modal/actions';
import DefaultModal from 'components/modal/Default';
import ErrorModal from 'components/modal/Error';
import {
  IFormValues,
  IFormValidations,
} from 'components/Form/types/IFormValues';
import {
  selectActiveYardCase,
  selectYardCaseEditStatus,
} from 'store/yardCase/selectors';
import { useDispatchBind } from 'hooks';
import YardCaseModel from 'gen/YardCaseModel';
import {
  selectBasicData,
  makeSelectYardCaseStatuses,
} from 'store/basicData/selectors';
import { gotoLogin, copyJSON } from 'utils';
import getFormSections from './formSections';
import PageHead from 'components/PageHead';
import SavingIndicator from 'components/statusIndicators/SavingIndicator';
import IFormSection from 'components/Form/types/IFormSection';
import Routes from 'constants/Routes';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import SideTray from './SideTray';
import EditStatus from 'store/yardCase/YardCaseEditStatus';
import Colors, { translucent } from 'constants/Colors';
import { AnyObject, FormApi } from 'final-form';
import useAdvancedFormLogic from './advancedFormLogic';
import {
  toFormDateString,
  toReadonlyFormSections,
} from 'components/Form/utils';
import Container from 'components/Container';
import VTRSearchModal from './VTRSearchModal';
import YardCaseStatus from 'gen/YardCaseStatus';
import YardCaseStatusText from './YardCaseStatusText';
import YardCaseActionButtons from './YardCaseActionButtons';

const Scroll = styled.div`
  overflow: auto;
  padding-top: ${Sizes.padding.large}px;
  flex: 1;
`;

const MyContainer = styled(Container)`
  display: flex;
  flex-direction: row;
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const OverlaySpinnerContainer = styled(Container)`
  position: relative;
`;

const OverlaySpinner = styled(LoadingSpinner)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: 2;
  background-color: ${translucent(Colors.background.main)};
  height: calc(100vh - 130px);
  padding-right: 33%;
`;

function confirmExit() {
  return 'Det finns osparade ändringar, är du säker på att du vill lämna?';
}

/* == User input has priority over responses from backend, example: ==
   === Case 1 ===
   user start typing
   user stop typing

   save 1 begins, saveCallCount = 1
   user start typing
   save 1 ends, saveCallCount == 1, backend response is put into redux store
   effect watching store yardCase runs, but editStatus == typing, so don't update initialValues

   user stops typing

   save 2 begins, saveCallCount = 2
   save 2 ends, saveCallCount == 2, puts response in store
   Yardcase watching effect runs & editStatus != typing -> initialValues are updated

   === Case 2 ===
   user start typing
   user stop typing
   save 3 begins, saveCallCount = 3

   user start typing
   user stop typing
   save 4 begin, saveCallCount = 4

   save 3 ends, ignores response because saveCallCount == 4
   save 4 ends, saves to store because saveCallCount == 4
*/

const EditYardCasePage: React.FunctionComponent = () => {
  const [fetchingYardCase, setFetchingYardCase] = useState(true);
  const [initialValues, setInitialValues] = useState<IFormValues>();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const ignoreNextFormChange = useRef(false);
  const saveCallCount = useRef(0);
  const advancedFormLogic = useAdvancedFormLogic();

  const dispatch = useDispatch();
  const onSetEditStatus = useDispatchBind(setYardCaseEditStatus, dispatch);
  const onClearActiveYardCase = useDispatchBind(clearActiveYardCase, dispatch);
  const onPushModal = useDispatchBind(pushModal, dispatch);
  const onSetActiveYardCase = useDispatchBind(setActiveYardCase, dispatch);

  const basicData = useSelector(selectBasicData);
  const yardCase = useSelector(selectActiveYardCase);
  const editStatus = useSelector(selectYardCaseEditStatus);
  const yardCaseStatuses = useSelector(makeSelectYardCaseStatuses);

  useEffect(() => {
    if (id) {
      const idNumber = parseInt(id);

      if (yardCase && yardCase.id !== idNumber) {
        onClearActiveYardCase();
        advancedFormLogic.reset();
      }

      if (id == 'new') {
        newYardCase();
      } else {
        // if loaded yardCase has same id as in uri, don't reload
        if (yardCase && idNumber === yardCase.id) {
          return;
        }

        if (isNaN(idNumber)) {
          showIdError();
        } else {
          fetchYardCase(idNumber);
        }
      }
    } else {
      showIdError();
    }
  }, [id]);

  useEffect(() => {
    // Don't change initialValues while the user is typing.
    // Another save will be triggered once the user is done typing
    if (yardCase && editStatus !== EditStatus.typing) {
      ignoreNextFormChange.current = true;
      setInitialValues(yardCase as any as IFormValues);
      history.replace(`${Routes.edit}/${yardCase.id}`);
      onSetEditStatus(EditStatus.saved);
    }
  }, [yardCase]);

  useEffect(() => {
    if (yardCase && editStatus !== EditStatus.saved) {
      window.onbeforeunload = confirmExit;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [editStatus, yardCase]);

  useEffect(
    () => () => {
      onClearActiveYardCase();
    },
    []
  );

  if (!basicData) {
    gotoLogin();
    return null;
  }

  const showIdError = () => {
    onPushModal(<DefaultModal>ID är fel</DefaultModal>);
  };

  const newYardCase = () => {
    onSetEditStatus(EditStatus.formInvalid);
    setFetchingYardCase(false);

    const newYardCase = copyJSON<YardCaseModel>(
      basicData.templates.yardCaseModel
    );

    // set activeFrom to current date
    (newYardCase.activeFrom as any) = toFormDateString(new Date());
    setInitialValues(newYardCase as any as IFormValues);
  };

  const fetchYardCase = async (id: number) => {
    setFetchingYardCase(true);
    try {
      const yardCase = await Api.getYardCase(id);
      onSetActiveYardCase(yardCase);

      ignoreNextFormChange.current = true;
      setInitialValues(yardCase as any as IFormValues);
      onSetEditStatus(EditStatus.saved);
    } catch (err) {
      console.error('could not fetch YardCase:', err);
      onPushModal(
        <ErrorModal exception={err}>
          Det gick inte att hämta uppdrag {id}
        </ErrorModal>
      );
    }
    setFetchingYardCase(false);
  };

  const handleFormChanged = (
    formValues: IFormValues<keyof YardCaseModel>,
    formValidations: IFormValidations,
    formApi: FormApi<AnyObject>
  ) => {
    advancedFormLogic.run(formValues, formValidations, formApi, dispatch);

    if (ignoreNextFormChange.current) {
      ignoreNextFormChange.current = false;
      return;
    }

    if (
      yardCase &&
      (yardCase.status === YardCaseStatus.Returned ||
        yardCase.status === YardCaseStatus.Scrapped)
    ) {
      return;
    }

    let hasErrors = false;
    for (const key in formValidations) {
      if (formValidations[key] && formValidations[key].invalid) {
        hasErrors = true;
        break;
      }
    }

    if (hasErrors) {
      onSetEditStatus(EditStatus.formInvalid);
    } else {
      saveYardCase(formValues as any as YardCaseModel);
    }
  };

  const saveYardCase = async (yardCase: YardCaseModel) => {
    saveCallCount.current++;
    const mySaveCallPlace = saveCallCount.current;

    onSetEditStatus(EditStatus.saving);
    try {
      const response = await Api.saveYardCase(yardCase);

      // if there are other save requests started after this one, then ignore response
      if (mySaveCallPlace === saveCallCount.current) {
        onSetActiveYardCase(response);
      }
    } catch (err) {
      onSetEditStatus(EditStatus.formInvalid);
      onPushModal(
        <ErrorModal exception={err}>
          Det gick inte att spara uppdraget
        </ErrorModal>
      );
    }
  };

  const handleVTRClick = () => {
    if (
      (editStatus === EditStatus.saved ||
        editStatus === EditStatus.formInvalid) &&
      (advancedFormLogic.previousFormValues || initialValues)
    ) {
      onPushModal(
        <VTRSearchModal
          formValues={advancedFormLogic.previousFormValues! || initialValues!}
          onResultPicked={(yc) => setInitialValues(yc)}
          searchAssistMode={false}
        />
      );
    }
  };

  const handleSearchAssistClick = () => {
    if (
      (editStatus === EditStatus.saved ||
        editStatus === EditStatus.formInvalid) &&
      (advancedFormLogic.previousFormValues || initialValues)
    ) {
      onPushModal(
        <VTRSearchModal
          formValues={advancedFormLogic.previousFormValues! || initialValues!}
          onResultPicked={(yc) => setInitialValues(yc)}
          searchAssistMode={true}
        />
      );
    }
  };

  if (fetchingYardCase) {
    return <LoadingSpinner>Hämtar uppdrag...</LoadingSpinner>;
  }

  let formSections = getFormSections(
    basicData,
    handleVTRClick,
    handleSearchAssistClick
  ) as IFormSection[];

  if (
    yardCase &&
    (yardCase.status === YardCaseStatus.Returned ||
      yardCase.status === YardCaseStatus.Scrapped ||
      yardCase.status === YardCaseStatus.Cancelled)
  ) {
    formSections = toReadonlyFormSections(
      formSections,
      yardCase as any as IFormValues
    );
  }

  return (
    <>
      <Prompt
        when={!!yardCase && editStatus !== EditStatus.saved}
        message={(location) =>
          yardCase && location.pathname === `${Routes.edit}/${yardCase.id}`
            ? true
            : 'Det finns osparade ändringar, är du säker på att du vill lämna?'
        }
      />
      <PageHead
        left={
          id == 'new' ? (
            'Nytt uppdrag'
          ) : (
            <>
              Uppdrag {id} -{' '}
              <YardCaseStatusText
                yardCase={yardCase}
                yardCaseStatuses={yardCaseStatuses}
              />
            </>
          )
        }
        right={<SavingIndicator editStatus={editStatus} />}
      />
      {editStatus === EditStatus.blockedSaving && (
        <OverlaySpinnerContainer>
          <OverlaySpinner>Sparar</OverlaySpinner>
        </OverlaySpinnerContainer>
      )}
      <Scroll>
        <MyContainer>
          <FormWrap>
            <Form
              formSections={formSections}
              initialValues={initialValues}
              changeBegin={() => onSetEditStatus(EditStatus.typing)}
              changeEnd={handleFormChanged}
              autoFocusedField={
                !yardCase ? formSections[0].fields[0] : undefined
              }
            />
            <YardCaseActionButtons />
          </FormWrap>
          <SideTray />
        </MyContainer>
      </Scroll>
    </>
  );
};

export default EditYardCasePage;
