import React from 'react';
import {
  ImmutableReducer,
  ActionReturns,
  DeepReadonly,
} from 'utils/utilityTypes';
import * as ModalActions from './actions';
import ModalActionTypes from './ActionTypes';

export interface ModalState {
  modalStack: React.ReactChild[];
}

const initialState: DeepReadonly<ModalState> = {
  modalStack: [],
};

type actions = ActionReturns<typeof ModalActions>;

const modalReducer: ImmutableReducer<ModalState, actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ModalActionTypes.PushModal:
      return { ...state, modalStack: [...state.modalStack, action.modal] };

    case ModalActionTypes.PopModal:
      const newStack = [...state.modalStack];
      newStack.pop();
      const ret = { ...state, modalStack: newStack };
      return ret;

    default:
      return state;
  }
};

export default modalReducer;
