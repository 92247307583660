import React, { useState } from 'react';
import styled from 'styled-components';
import Card from 'components/Card';
import Colors, { translucent } from 'constants/Colors';
import Sizes from 'constants/Sizes';
import PageHead from 'components/PageHead';
import IconButton from 'components/inputs/IconButton';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import SortedTable from 'components/Table/SortedTable';
import YardCasePreviewModel from 'gen/YardCasePreviewModel';
import { useDispatch, useSelector } from 'react-redux';
import { useDispatchBind } from 'hooks';
import { pushModal } from 'store/modal/actions';
import ErrorModal from 'components/modal/Error';
import Api from 'api';
import yardCasePreviewColumnSettings from 'utils/yardCasePreviewColumnSettings';
import {
  makeSelectCustomers,
  makeSelectYardLocations,
  selectBasicData,
} from 'store/basicData/selectors';
import LoadingSpinner from 'components/LoadingSpinner';
import { EmptyMessage } from 'components/TabView/components';
import { useHistory } from 'react-router';
import Routes from 'constants/Routes';
import Container from 'components/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInfoModal from './SearchInfoModal';
import Input from 'components/inputs/Input';
import DateSpanToggle from './DateSpanToggle';
import { toFormDateString } from 'components/Form/utils';
import {
  setSearchResults,
  setSearchString,
  setEnableDateInput,
  setDateSpanStart,
  setDateSpanEnd,
} from 'store/search/actions';
import {
  makeSelectSearchResults,
  makeSelectSearchString,
  makeSelectEnableDateInput,
  makeSelectDateSpanStart,
  makeSelectDateSpanEnd,
} from 'store/search/selectors';

const MyCard = styled(Card)`
  background-color: ${Colors.background.mainLight};
  margin: ${Sizes.padding.large}px 0;
  border-color: ${Colors.background.mainDark};
`;

const SearchInput = styled.input`
  background-color: ${Colors.background.main};
  border-radius: ${Sizes.radius.element}px;
  border: 2px solid transparent;
  width: 100%;
  padding: ${Sizes.padding.mini}px ${Sizes.padding.small}px;
  margin-right: ${Sizes.padding.small}px;
  flex: 1;

  &:focus {
    outline: none;
    border-color: ${Colors.focus};
  }
`;

const DateInput = styled(Input)`
  height: auto;
  margin: 0 ${Sizes.padding.small}px;
  border-color: ${Colors.background.mainDark};
  color: ${Colors.foreground.mainLight};
`;

const DateInputWrap = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ expanded }) => (expanded ? '410px' : 0)};
  overflow: hidden;

  transition: width 0.3s;
`;

const SearchForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${Sizes.padding.small}px;
`;

const InfoButton = styled.button`
  border: none;
  background: transparent;
  margin: 0;
  margin-left: ${Sizes.padding.small}px;
  padding: ${Sizes.padding.mini}px ${Sizes.padding.small}px;
  font-size: 20px;
  color: ${translucent(Colors.foreground.mainLight)};
`;

const SearchPage = () => {
  const [searching, setSearching] = useState(false);
  const history = useHistory();

  const customers = useSelector(makeSelectCustomers);
  const locations = useSelector(makeSelectYardLocations);
  const basicData = useSelector(selectBasicData);

  const searchResults = useSelector(makeSelectSearchResults);
  const searchString = useSelector(makeSelectSearchString);

  const enableDateInput = useSelector(makeSelectEnableDateInput);
  const fromDate = useSelector(makeSelectDateSpanStart);
  const toDate = useSelector(makeSelectDateSpanEnd);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const onSetSearchResults = useDispatchBind(setSearchResults, dispatch);
  const onSetSearchString = useDispatchBind(setSearchString, dispatch);

  const onSetEnableDateInput = useDispatchBind(setEnableDateInput, dispatch);
  const onSetDateSpanStart = useDispatchBind(setDateSpanStart, dispatch);
  const onSetDateSpanEnd = useDispatchBind(setDateSpanEnd, dispatch);

  const onSearchClick = async () => {
    setSearching(true);
    try {
      const response = await Api.searchYardCases(
        searchString,
        fromDate,
        toDate
      );
      onSetSearchResults(response);
    } catch (err) {
      onPushModal(
        <ErrorModal exception={err}>
          Ett fel inträffade när sökningen skulle utföras.
        </ErrorModal>
      );
    }
    setSearching(false);
  };

  const handleRowClick = (item: YardCasePreviewModel) => {
    history.push(`${Routes.edit}/${item.id}`);
  };

  const handleInfoClick = (eve: React.MouseEvent) => {
    eve.preventDefault();
    onPushModal(<SearchInfoModal />);
  };

  return (
    <>
      <PageHead left="Sök" />
      <Container>
        <MyCard>
          <SearchForm
            onSubmit={(eve) => {
              eve.preventDefault();
              onSearchClick();
            }}
          >
            <SearchInput
              type="text"
              placeholder="Sök..."
              onChange={(eve) => onSetSearchString(eve.target.value)}
              value={searchString}
              autoFocus
            />

            <DateSpanToggle
              value={enableDateInput}
              onToggle={(enable) => {
                onSetEnableDateInput(enable);
                onSetDateSpanStart(undefined);
                onSetDateSpanEnd(undefined);
              }}
            >
              <FontAwesomeIcon icon={faCalendar} />
            </DateSpanToggle>

            <DateInputWrap expanded={enableDateInput}>
              Från:
              <DateInput
                disabled={!enableDateInput}
                type="date"
                value={toFormDateString(fromDate)}
                onChange={(eve) =>
                  onSetDateSpanStart(new Date(eve.currentTarget.value))
                }
                max={toFormDateString(toDate)}
              />
              Till:
              <DateInput
                disabled={!enableDateInput}
                type="date"
                value={toFormDateString(toDate)}
                onChange={(eve) =>
                  onSetDateSpanEnd(new Date(eve.currentTarget.value))
                }
                min={toFormDateString(fromDate)}
              />
            </DateInputWrap>

            <IconButton icon={faSearch}>Sök</IconButton>
            <InfoButton title="Vad kan jag söka på?" onClick={handleInfoClick}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </InfoButton>
          </SearchForm>
          {searching ? (
            <LoadingSpinner>Söker...</LoadingSpinner>
          ) : searchResults === null ? undefined : searchResults.length <= 0 ? (
            <EmptyMessage>Inga resultat</EmptyMessage>
          ) : (
            <SortedTable
              columnSettings={yardCasePreviewColumnSettings(
                customers,
                locations,
                basicData && basicData.yardCaseStatuses
              )}
              defaultSortingColumn={0}
              sortDescendingByDefault
              rowsPerPage={50}
              borderColor={Colors.background.mainDark}
              rows={searchResults}
              onRowClick={handleRowClick}
            />
          )}
        </MyCard>
      </Container>
    </>
  );
};

export default SearchPage;
