import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import Layout from './components/Layout';
import EditYardCasePage from './components/pages/EditYardCasePage';
import SearchPage from './components/pages/SearchPage';
import InvoicePage from 'components/pages/InvoicePage';
import AdminPage from './components/pages/AdminPage';
import './custom.css';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import Routes from './constants/Routes';
import LatestPage from 'components/pages/LatestPage';
import TestPage from './components/pages/TestPage';
import { useSelector } from 'react-redux';
import { makeSelectCurrentUserIsAdmin } from 'store/basicData/selectors';

export default () => (
  <Layout>
    <Switch>
      <Redirect exact path="/" to={Routes.latest} />
      <AuthorizeRoute path={Routes.latest} component={LatestPage} />
      <AuthorizeRoute path={Routes.search} component={SearchPage} />

      <Redirect path={Routes.register} to={`${Routes.edit}/new`} />
      <AuthorizeRoute
        path={`${Routes.edit}/:id`}
        component={EditYardCasePage}
      />

      <AdminAuthorizedRoute path={Routes.invoice} component={InvoicePage} />
      <AdminAuthorizedRoute path={Routes.admin} component={AdminPage} />

      <Route path={Routes.test} component={TestPage} />
      <Route
        path={ApplicationPaths.ApiAuthorizationPrefix}
        component={ApiAuthorizationRoutes}
      />
      <Route path="" component={() => <h1>Not found</h1>} />
    </Switch>
  </Layout>
);

const AdminAuthorizedRoute: React.SFC<{
  path: string;
  component: React.SFC | React.Component;
}> = ({ path, component }) => {
  const isAdmin = useSelector(makeSelectCurrentUserIsAdmin);

  if (isAdmin) {
    return <AuthorizeRoute path={path} component={component} />;
  } else {
    return <Redirect to="/" />;
  }
};
