import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Comment from './Comment';
import AddComment from './AddComment';
import {
  TabHeader,
  TabContent,
  EmptyMessage,
} from 'components/TabView/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveYardCase } from 'store/yardCase/selectors';
import Button from 'components/inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { popModal, pushModal } from 'store/modal/actions';
import DefaultModal from 'components/modal/Default';
import Sizes from 'constants/Sizes';

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Header = styled(TabHeader)`
  flex: 1;
  margin: 0;
  padding-bottom: 2px;
`;

const Spacer = styled.div`
  padding-bottom: ${Sizes.padding.large}px;
`;

const AttachmentsTab: React.FC = () => {
  const yardCase = useSelector(selectActiveYardCase);
  const hasNoFinalInvoiceBatchID =
    !yardCase ||
    (yardCase.finalInvoiceBatchID !== null &&
      yardCase.finalInvoiceBatchID !== undefined);
  const [unlockOverride, setUnlockOverride] = useState(false);
  const dispatch = useDispatch();

  const isLocked = !unlockOverride && hasNoFinalInvoiceBatchID;

  const handleUnlockClick = useCallback(() => {
    if (!unlockOverride) {
      dispatch(
        pushModal(
          <DefaultModal
            buttons={[
              {
                label: 'Lås upp',
                onClick: () => {
                  setUnlockOverride(true);
                  dispatch(popModal());
                },
              },
              {
                label: 'Avbryt',
                onClick: () => dispatch(popModal()),
              },
            ]}
          >
            Ärendet är helt slutfört och fakturerat, vill du verkligen låsa upp
            ärendet?
          </DefaultModal>
        )
      );
    } else {
      setUnlockOverride(false);
    }
  }, [unlockOverride]);

  return (
    <TabContent>
      <HeaderWrap>
        <Header>Bilagor</Header>

        {hasNoFinalInvoiceBatchID && (
          <Button onClick={handleUnlockClick}>
            <FontAwesomeIcon icon={isLocked ? faLock : faUnlock} />{' '}
            {isLocked ? 'Lås upp' : 'Lås'}
          </Button>
        )}
      </HeaderWrap>

      {!isLocked && <AddComment />}
      <Spacer />

      {yardCase ? (
        yardCase.comments.length > 0 ? (
          yardCase.comments
            .filter((comment) => !comment.isRemoved)
            .sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1))
            .map((comment) => (
              <Comment
                key={comment.id}
                yardCaseStatus={yardCase.status}
                comment={comment}
                locked={isLocked}
              />
            ))
        ) : (
          <EmptyMessage>Inga kommentarer</EmptyMessage>
        )
      ) : (
        <EmptyMessage>Uppdraget har inte sparats ännu</EmptyMessage>
      )}
    </TabContent>
  );
};

export default AttachmentsTab;
