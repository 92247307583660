import ModalActionTypes from './ActionTypes';

export const pushModal = (modal: React.ReactChild) => ({
  type: ModalActionTypes.PushModal,
  modal,
});

export const popModal = () => ({
  type: ModalActionTypes.PopModal,
});
