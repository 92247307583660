import React, { useState } from 'react';
import SortedTable from 'components/Table/SortedTable';
import useApiResponse, { RequestStatus } from 'hooks/useApiResponse';
import Api from 'api';
import LoadingSpinner from 'components/LoadingSpinner';
import { EmptyMessage } from 'components/TabView/components';
import ManagedUserModel from 'gen/ManagedUserModel';
import { ColumnSetting, CellRendererProps } from 'components/Table';
import Toggle from 'components/inputs/Toggle';
import { useDispatch } from 'react-redux';
import { useDispatchBind } from 'hooks';
import { pushModal } from 'store/modal/actions';
import ErrorModal from 'components/modal/Error';
import Rolling from 'components/LoadingSpinner/Rolling';
import styled from 'styled-components';
import Colors from 'constants/Colors';
import { TableActions, TableActionsFilters } from 'components/Table/components';

const SmallRolling = styled(Rolling)`
  height: 20px;
  width: 20px;
  color: ${Colors.background.accent};
`;

const UsersTab: React.FC = () => {
  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);
  const [showInactive, setShowInactive] = useState(false);

  const users = useApiResponse(Api.getUsers, {
    errorMessage: 'Kunde inte hämta användare',
  });

  const roles = useApiResponse(Api.getRoles, {
    errorMessage: 'Kunde inte hämta roller',
  });

  const updateUserRole = async (
    userId: number,
    roleId: number,
    active: boolean
  ) => {
    try {
      const activated = await Api.setUserHasRole(userId, roleId, active);
      userRoleActivated(userId, roleId, activated);
    } catch (err) {
      onPushModal(
        <ErrorModal exception={err}>
          Ett fel inträffade när rollen skulle läggas till eller tas bort
        </ErrorModal>
      );
    }
  };

  const userRoleActivated = (
    userId: number,
    roleId: number,
    active: boolean
  ) => {
    const newUsers = [...users.response!];
    const updateUser = newUsers.find((u) => u.id === userId);

    if (!active) {
      updateUser!.roles = updateUser!.roles.filter((r) => r !== roleId);
    } else {
      updateUser!.roles.push(roleId);
    }

    users.update(newUsers);
  };

  if (
    users.status === RequestStatus.Fetching ||
    !users.response ||
    roles.status === RequestStatus.Fetching ||
    !roles.response
  ) {
    return <LoadingSpinner>Hämtar användare...</LoadingSpinner>;
  }

  return (
    <>
      <EmptyMessage>OBS: De ändringar du gör sparas direkt</EmptyMessage>
      <TableActions>
        <TableActionsFilters>
          <Toggle value={showInactive} onToggle={setShowInactive} /> Visa
          inaktiva användare
        </TableActionsFilters>
      </TableActions>
      <SortedTable
        columnSettings={[
          {
            header: 'Id',
            attribute: 'id',
          },
          {
            header: 'Namn',
            attribute: 'userName',
          },
          {
            header: 'Email',
            attribute: 'email',
          },
          ...roles.response.map(
            (
              role
            ): ColumnSetting<ManagedUserModel, keyof ManagedUserModel> => ({
              header: role.name,

              cellRenderer: ({ row, rowIndex }) => (
                <RoleToggle
                  row={row}
                  rowIndex={rowIndex}
                  roleId={role.id}
                  updateUserRole={updateUserRole}
                />
              ),
            })
          ),
        ]}
        rows={users.response.filter(
          (usr) => showInactive || usr.roles.length > 0
        )}
      />
    </>
  );
};

interface RoleToggleProps extends CellRendererProps<ManagedUserModel> {
  roleId: number;
  updateUserRole(userId: number, roleId: number, active: boolean): void;
}

const RoleToggle: React.SFC<RoleToggleProps> = ({
  row,
  roleId,
  updateUserRole,
}) => {
  const [updating, setUpdating] = useState(false);
  const active = !!row.roles.find((r) => r === roleId);

  if (updating) {
    return <SmallRolling />;
  }

  return (
    <Toggle
      value={active}
      onToggle={async () => {
        setUpdating(true);
        await updateUserRole(row.id, roleId, !active);
        setUpdating(false);
      }}
    />
  );
};
export default UsersTab;
