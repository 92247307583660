import React from 'react';

interface Props {
  style?: React.CSSProperties;
  className?: string;
}

const Rolling: React.FunctionComponent<Props> = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    width="28px"
    height="28px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <rect x="0" y="0" width="100" height="100" fill="none" />
    <circle
      cx="50"
      cy="50"
      r="40"
      strokeDasharray="150.79644737231007 100.53096491487338"
      stroke="currentColor"
      fill="none"
      strokeWidth="10"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 50 50;180 50 50;360 50 50;"
        keyTimes="0;0.5;1"
        dur="1s"
        repeatCount="indefinite"
        begin="0s"
      />
    </circle>
  </svg>
);

export default Rolling;
