import basicDataReducer, { BasicDataState } from './basicData';
import yardCaseReducer, { YardCaseState } from './yardCase';
import modalReducer, { ModalState } from './modal';
import invoiceReducer, { InvoiceState } from './invoice';
import { ImmutableReducer } from 'utils/utilityTypes';
import searchReducer, { SearchState } from './search';

// The top-level state object
export interface ApplicationState {
  basicData: BasicDataState;
  yardCase: YardCaseState;
  modal: ModalState;
  invoice: InvoiceState;
  search: SearchState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: {
  [name in keyof ApplicationState]: ImmutableReducer<
    ApplicationState[name],
    any
  >;
} = {
  basicData: basicDataReducer,
  yardCase: yardCaseReducer,
  modal: modalReducer,
  invoice: invoiceReducer,
  search: searchReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
