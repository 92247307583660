import * as React from 'react';
import './NavMenu.css';
import { LoginMenu } from 'components/api-authorization/LoginMenu';
import { NavLink, NavBar, Content, LogoItem, NavItems } from './components';
import Routes from 'constants/Routes';
import { useSelector } from 'react-redux';
import {
  makeSelectCurrentUserIsAdmin,
  makeSelectSettings,
} from 'store/basicData/selectors';
import BuildSettings from 'gen/BuildSettings';
import Colors from 'constants/Colors';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';

const SettingsProfile = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: ${Sizes.padding.mini}px;
  padding-left: ${Sizes.padding.large}px;

  color: ${Colors.bad};
  font-weight: bold;
  font-size: 15px;
`;

const NavMenu = () => {
  const isAdmin = useSelector(makeSelectCurrentUserIsAdmin);
  const settings = useSelector(makeSelectSettings);

  let settingsProfileText = null;
  switch (settings && settings.settingsProfile) {
    case BuildSettings.Production:
      settingsProfileText = null;
      break;

    case BuildSettings.TestEnvironment:
    case BuildSettings.LocalSandbox:
    default:
      settingsProfileText = settings && BuildSettings[settings.settingsProfile];
      break;
  }

  return (
    <header>
      <NavBar>
        <Content>
          <LogoItem to="/">
            <img src={'/images/BRKlogo.svg'} width="45" height="45" />
            Gårdsprogrammet
          </LogoItem>
          {settingsProfileText && (
            <SettingsProfile>{settingsProfileText}</SettingsProfile>
          )}
          <NavItems>
            <NavLink to={Routes.register}>Registrera</NavLink>
            <NavLink to={Routes.latest}>Senaste</NavLink>
            <NavLink to={Routes.search}>Sök</NavLink>
            {isAdmin && (
              <>
                <NavLink to={Routes.invoice}>Underlag</NavLink>
                <NavLink to={Routes.admin}>Admin</NavLink>
              </>
            )}
            <LoginMenu />
          </NavItems>
        </Content>
      </NavBar>
    </header>
  );
};

export default NavMenu;
