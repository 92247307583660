import InvoiceActionTypes from './ActionTypes';
import { RequestStatus } from 'hooks/useApiResponse';
import MonthlyInvoiceBatchModel from 'gen/MonthlyInvoiceBatchModel';

export const setMonthlyInvoiceBatches = (
  batches: MonthlyInvoiceBatchModel[]
) => ({
  type: InvoiceActionTypes.SetMonthlyInvoiceBatches,
  batches,
});

export const monthlyInvoiceBatchCreated = (
  batch: MonthlyInvoiceBatchModel
) => ({
  type: InvoiceActionTypes.MonthlyInvoiceBatchCreated,
  batch,
});

export const setCreatingMonthlyInvoiceBatchStatus = (
  requestStatus?: RequestStatus
) => ({
  type: InvoiceActionTypes.SetCreatingMonthlyInvoiceBatchStatus,
  requestStatus,
});
