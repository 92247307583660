import styled, { css } from 'styled-components';
import Colors, { translucent } from 'constants/Colors';
import Sizes from 'constants/Sizes';

export const FormSectionWrapper = styled.div`
  position: relative;
  width: 50%;
  padding-bottom: 50px;
  padding-right: ${Sizes.padding.large}px;
`;

export const FormSectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${Colors.foreground.main};
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: larger;
  font-weight: 600;
  color: ${Colors.foreground.main};
  border-bottom: 1px solid ${Colors.background.accent};
  margin: 0 ${Sizes.padding.mini}px;
`;

export const SectionHeaderRight = styled.div`
  flex: 1;
  font-size: 15px;
  border-color: ${Colors.background.mainDark};
  height: 40px;
  padding: ${Sizes.padding.mini}px 0;
  text-align: end;

  & > * {
    margin-left: ${Sizes.padding.mini}px;
  }
`;

interface FieldWrapperProps {
  error?: boolean;
  greyout?: boolean;
  center?: boolean;
}

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  width: 50%;
  position: relative;
  border-color: ${Colors.background.mainDark};

  ${({ center }: FieldWrapperProps) =>
    center &&
    css`
      justify-content: center;
    `}

  ${({ error }: FieldWrapperProps) =>
    error &&
    css`
      > *:last-child {
        background-color: ${translucent(Colors.bad)} !important;
      }
    `};

  ${({ greyout }: FieldWrapperProps) =>
    greyout &&
    css`
      color: ${Colors.background.mainDark};
      border-color: ${Colors.background.mainDark};
    `};
`;

export const ErrorText = styled.span`
  color: ${Colors.bad};
  font-weight: bold;
`;

export const ReadonlyField = styled.div`
  min-height: 1.5em;
  line-height: 1em;
`;
