import React, { FC } from 'react';
import {
  faBan,
  faCheckCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';
import styled, { css } from 'styled-components';
import { IFormValue } from 'components/Form/types/IFormValues';
import FieldType from 'components/Form/types/FieldType';
import { toUserDateString, toUserDateTimeString } from 'utils';

const SelectedValue = styled.button<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${Sizes.padding.nano}px ${Sizes.padding.medium}px;
  gap: ${Sizes.padding.small}px;
  margin: 0;

  background: none;
  appearance: none;
  border: none;
  font: inherit;
  border-radius: 10000px;
  border: 1px solid ${Colors.focus};

  ${({ selected }) =>
    selected &&
    css`
      padding-right: ${Sizes.padding.small}px;
      background-color: ${Colors.focus};
      color: #fff;
    `}
`;

interface Props {
  value: IFormValue;
  selected: boolean;
  onClick(): void;
  fieldType?: FieldType;
}

const SelectedValueButton: FC<Props> = ({
  value,
  selected,
  onClick,
  fieldType,
}) => {
  const getRenderedValue = () => {
    const noValueResult = (
      <i>
        <b>
          <FontAwesomeIcon icon={faBan} /> Inget värde
        </b>
      </i>
    );

    if (typeof value === 'string') {
      // Try to parse and display the value as a date if the field is of such type.
      if (fieldType === FieldType.DATE || fieldType === FieldType.DATETIME) {
        const parsedDate = new Date(value);

        // If Date constructor couldn't parse the value, just return the value
        if (isNaN(parsedDate.getTime())) return value;

        if (fieldType === FieldType.DATE) return toUserDateString(parsedDate);

        return toUserDateTimeString(parsedDate);
      }

      return value || noValueResult;
    }

    if (typeof value === 'number') return value;

    if (value === true) return 'Ja';
    if (value === false) return 'Nej';

    return value ?? noValueResult;
  };

  return (
    <SelectedValue selected={selected} onClick={onClick}>
      {getRenderedValue()}
      {selected && <FontAwesomeIcon icon={faCheckCircle} />}
    </SelectedValue>
  );
};

export default SelectedValueButton;
