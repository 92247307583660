import React, { useState, useEffect } from 'react';
import DefaultModal from './Default';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import { ResponseError } from 'api/util';
import { useSelector } from 'react-redux';
import { makeSelectSettings } from 'store/basicData/selectors';
import BuildSettings from 'gen/BuildSettings';

interface Props {
  exception?: unknown;
  reloadOnConfirm?: boolean;
}

const ExceptionBox = styled.pre`
  margin: 0;
  margin-top: ${Sizes.padding.small}px;
  max-width: 90vw;
  min-width: 100%;
  max-height: calc(90vh - 100px);
  overflow: auto;
  padding: ${Sizes.padding.small}px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
`;

const MyModal = styled(DefaultModal)`
  padding: 0;
  padding-top: ${Sizes.padding.small}px;
`;

const ErrorModal: React.FC<Props> = ({
  children,
  exception,
  reloadOnConfirm,
}) => {
  const [responseText, setResponseText] = useState('');
  const appSettings = useSelector(makeSelectSettings);

  useEffect(() => {
    const responseError =
      exception instanceof ResponseError ? exception : undefined;

    if (responseError) {
      const contentType =
        responseError.response.headers.has('content-type') &&
        responseError.response.headers.get('content-type');

      if (contentType && contentType.includes('json')) {
        responseError.response.json().then((json) => {
          console.error('ErrorModal response JSON:', json);
          setResponseText(JSON.stringify(json, null, 2));
        });
      } else {
        responseError.response.text().then((text) => {
          console.error('ErrorModal response text:', text);
          setResponseText(text);
        });
      }
    }
  }, []);

  const showExtendedErrorMessage = () => {
    switch (appSettings && appSettings.settingsProfile) {
      case BuildSettings.TestEnvironment:
      case BuildSettings.Production:
        return false;

      case BuildSettings.LocalSandbox:
      default:
        return true;
    }
  };

  return (
    <MyModal
      title="Fel"
      buttons={
        reloadOnConfirm
          ? [
              {
                label: 'Ladda om sidan',
                onClick: () => window.location.reload(),
              },
            ]
          : undefined
      }
    >
      {children}
      {exception &&
        (showExtendedErrorMessage() ? (
          <ExceptionBox>
            {responseText && (
              <>
                <h2>Response:</h2>
                {responseText}
              </>
            )}
            {exception instanceof Error && (
              <>
                <h2>Exception:</h2>
                {exception.stack}
              </>
            )}
          </ExceptionBox>
        ) : (
          <ExceptionBox>{responseText}</ExceptionBox>
        ))}
    </MyModal>
  );
};

export default ErrorModal;
