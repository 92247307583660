import React, { useState } from 'react';
import { TableActions, TableActionsTitle } from './components';
import IconButton from 'components/inputs/IconButton';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Table, { Props as TableProps } from './PaginatedTable';
import Colors from 'constants/Colors';
import styled from 'styled-components';
import Select from 'components/inputs/Select';
import Sizes from 'constants/Sizes';
import { CellRendererProps } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MySelect = styled(Select)`
  border-color: ${Colors.background.mainDark};
  margin-right: ${Sizes.padding.small}px;
`;

const RemoveButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${Colors.background.accent};
`;

export interface Props<Item extends Object, AddableValue>
  extends TableProps<Item> {
  addableItems: { name: string; value: AddableValue }[];
  onAddItem(value: AddableValue): void;
  onRemoveItem(item: Item): void;
  rows: Item[];

  title?: string;
  addButtonText: string;
}

const EditableList = <Item extends Object, AddableValue>({
  addableItems,
  onAddItem,
  onRemoveItem,
  title,
  addButtonText,
  columnSettings,
  ...restProps
}: Props<Item, AddableValue>) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);

  const handleAddItemClick = () => {
    if (selectedItemIndex >= 0) {
      onAddItem(addableItems[selectedItemIndex].value);
    }
    setSelectedItemIndex(-1);
  };

  const handleDeleteItemClick = (props: CellRendererProps<Item>) => {
    onRemoveItem(props.row);
  };

  return (
    <>
      <TableActions>
        <TableActionsTitle>{title}</TableActionsTitle>
        <MySelect
          value={selectedItemIndex}
          onChange={(eve) => setSelectedItemIndex(Number(eve.target.value))}
        >
          <option disabled value={-1}>
            Välj ett värde
          </option>

          {addableItems.map((item, index) => (
            <option key={index} value={index}>
              {item.name}
            </option>
          ))}
        </MySelect>
        <IconButton
          icon={faPlus}
          onClick={handleAddItemClick}
          disabled={selectedItemIndex === -1}
        >
          {addButtonText}
        </IconButton>
      </TableActions>

      <Table
        columnSettings={[
          ...columnSettings,
          {
            header: 'Ta bort',
            style: {
              maxWidth: 100,
            },
            cellRenderer: (props) => (
              <RemoveButton onClick={() => handleDeleteItemClick(props)}>
                <FontAwesomeIcon icon={faTrash} />
              </RemoveButton>
            ),
          },
        ]}
        {...restProps}
      />
    </>
  );
};

export default EditableList;
