import { get, post, postForm } from './util';
import BasicDataModel from 'gen/BasicDataModel';
import YardCaseModel from 'gen/YardCaseModel';
import YardCasePreviewModel from 'gen/YardCasePreviewModel';
import CustomerModel from 'gen/CustomerModel';
import YardLocationModel from 'gen/YardLocationModel';
import ALPSearchResponseModel from 'gen/ALPSearchResponseModel';
import YardCaseCommentModel from 'gen/YardCaseCommentModel';
import YardCaseCommentAttachmentModel from 'gen/YardCaseCommentAttachmentModel';
import ManagedUserModel from 'gen/ManagedUserModel';
import ManagedRoleModel from 'gen/ManagedRoleModel';
import FinalInvoiceBatchModel from 'gen/FinalInvoiceBatchModel';
import MonthlyInvoiceBatchModel from 'gen/MonthlyInvoiceBatchModel';
import YardCaseStatus from 'gen/YardCaseStatus';

const BASE_URI = '/api';

// Keep all the functions in here async to get better error logs
const Api = {
  getBasicData: async () =>
    await get<BasicDataModel>(`${BASE_URI}/Yard/basic-data`),

  getMyRoles: async () => await get<number[]>(`${BASE_URI}/Yard/my-roles`),

  getYardCase: async (id: number) =>
    await get<YardCaseModel>(`${BASE_URI}/Yard/${id}`),

  saveYardCase: async (yardCase: YardCaseModel) =>
    await post<YardCaseModel>(`${BASE_URI}/Yard`, yardCase),

  completeYardCase: async (yardCase: YardCaseModel) =>
    await post<YardCaseModel>(`${BASE_URI}/Yard/complete-yard-case`, yardCase),

  scrapYardCase: async (yardCase: YardCaseModel) =>
    await post<YardCaseModel>(`${BASE_URI}/Yard/scrap-yard-case`, yardCase),

  cancelYardCase: async (
    yardCaseId: number,
    cancelComment: YardCaseCommentModel
  ) =>
    await post<YardCaseModel>(
      `${BASE_URI}/Yard/${yardCaseId}/cancel-yard-case`,
      cancelComment
    ),

  reopenYardCase: async (yardCaseId: number) =>
    await post<YardCaseModel>(
      `${BASE_URI}/Yard/${yardCaseId}/reopen-yard-case`,
      {}
    ),

  addComment: async (yardCaseId: number, comment: YardCaseCommentModel) =>
    await post<YardCaseCommentModel>(
      `${BASE_URI}/Yard/${yardCaseId}/comment`,
      comment
    ),

  /** link to generate excel file */
  getInventoryExcel: async (
    fromDate: Date,
    toDate: Date,
    statuses: YardCaseStatus[],
    customerIds: number[]
  ): Promise<Blob> => {
    const response = await fetch(`${BASE_URI}/Yard/get-cases-excel`, {
      method: 'POST',

      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fromDate,
        toDate,
        statuses,
        customerIds,
      }),
    });

    return await response.blob();
  },

  addAttachment: async (
    file: Blob,
    yardCaseId: number,
    commentId: number,
    displayName?: string
  ) => {
    const formData = new FormData();
    formData.append('File', file);
    displayName && formData.append('DisplayName', displayName);

    return postForm<YardCaseCommentAttachmentModel>(
      `${BASE_URI}/Yard/${yardCaseId}/comment/${commentId}/attachment`,
      formData
    );
  },

  getAttachmentURL: (attachmentId: number) =>
    `${BASE_URI}/Yard/attachment/${attachmentId}`,

  getLatestYardCases: async () =>
    await get<YardCasePreviewModel[]>(`${BASE_URI}/Yard/latest`),

  searchYardCases: async (phrase: string, fromDate?: Date, toDate?: Date) =>
    await get<YardCasePreviewModel[]>(`${BASE_URI}/Yard/search`, {
      p_SearchInput: phrase,
      p_FromDate: fromDate && fromDate.toISOString(),
      p_ToDate: toDate && toDate.toISOString(),
    }),

  idsFromExternalReference: async (reference: string) =>
    await get<number[]>(`${BASE_URI}/Yard/ids-from-external-reference`, {
      reference,
    }),

  idsFromRegNumber: async (regNumber: string) =>
    await get<number[]>(`${BASE_URI}/Yard/ids-from-reg-number`, {
      regNumber,
    }),

  saveCustomer: async (customer: CustomerModel) =>
    await post<CustomerModel>(`${BASE_URI}/admin/SaveCustomer`, customer),

  saveYardLocation: async (yardLocation: YardLocationModel) =>
    await post<YardLocationModel>(
      `${BASE_URI}/admin/SaveYardLocation`,
      yardLocation
    ),

  getUsers: async () =>
    await get<ManagedUserModel[]>(`${BASE_URI}/usersAdmin/users`),

  setUserHasRole: async (userId: number, roleId: number, hasRole: boolean) =>
    await post<boolean>(
      `${BASE_URI}/usersAdmin/users/${userId}/has-role/${roleId}`,
      hasRole
    ),

  getRoles: async () =>
    await get<ManagedRoleModel[]>(`${BASE_URI}/usersAdmin/roles`),

  /** Tip: month should be between 1-12 instead of 0-11 as Date.getMonth() gives*/
  createMonthlyInvoiceBatch: async (year: number, month: number) =>
    await get<MonthlyInvoiceBatchModel>(`${BASE_URI}/Invoice/monthly/create`, {
      year,
      month,
    }),

  getMonthlyInvoiceBatches: async () =>
    await get<MonthlyInvoiceBatchModel[]>(`${BASE_URI}/Invoice/monthly`),

  monthlyInvoiceBatchDownloadLink: (monthlyInvoiceBatchId: number) =>
    `${BASE_URI}/Invoice/monthly/by-batch-id/${monthlyInvoiceBatchId}/zip-download`,

  createFinalInvoiceBatch: async () =>
    await get<FinalInvoiceBatchModel>(
      `${BASE_URI}/Invoice/final-invoice-batch/create`
    ),

  getFinalInvoiceBatches: async () =>
    await get<FinalInvoiceBatchModel[]>(
      `${BASE_URI}/Invoice/final-invoice-batch`
    ),

  finalInvoiceBatchDownloadLink: (finalInvoiceBatchId: number) =>
    `${BASE_URI}/Invoice/final-invoice-batch/${finalInvoiceBatchId}/zip-download`,

  searchALPAssignment: async (
    assignmentID?: number,
    chassiNumber?: string,
    regNumber?: string
  ) =>
    await get<ALPSearchResponseModel>(
      `${BASE_URI}/AlpService/search-assignment`,
      {
        assignmentID,
        chassiNumber,
        regNumber,
      }
    ),

  searchVTR: async (
    assignmentID?: number,
    chassiNumber?: string,
    regNumber?: string
  ) =>
    await get<ALPSearchResponseModel>(`${BASE_URI}/AlpService/search-VTR`, {
      assignmentID,
      chassiNumber,
      regNumber,
    }),

  makePdf: async (content: string) =>
    await post<string>(`${BASE_URI}/testPdfCreation/index`, content),
};

export default Api;
