import styled from 'styled-components';
import Sizes from 'constants/Sizes';

const Container = styled.div`
  width: calc(100% - ${Sizes.padding.large * 2}px);
  max-width: 1800px;
  margin: 0 auto;
`;

export default Container;
