import React, { useState } from 'react';
import SortedTable from 'components/Table/SortedTable';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeSelectCustomers,
  makeSelectTemplates,
  makeSelectUserNames,
} from 'store/basicData/selectors';
import CustomerModel from 'gen/CustomerModel';
import Colors from 'constants/Colors';
import { gotoLogin, toUserDateTimeString, copyJSON } from 'utils';
import IconButton from 'components/inputs/IconButton';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { pushModal } from 'store/modal/actions';
import { useDispatchBind } from 'hooks';
import EditCustomerModal from './EditCustomerModal';
import { TableActions, TableActionsFilters } from 'components/Table/components';
import Toggle from 'components/inputs/Toggle';

const CustomersTab: React.FC = () => {
  const customers = useSelector(makeSelectCustomers);
  const users = useSelector(makeSelectUserNames);
  const templates = useSelector(makeSelectTemplates);
  const [showRemoved, setShowRemoved] = useState(false);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const handleAddCustomerClick = () => {
    const newCustomer = copyJSON<CustomerModel>(templates!.customerModel);
    onPushModal(
      <EditCustomerModal customer={newCustomer} title="Lägg till ny kund" />
    );
  };

  const handleEditCustomerClick = (customer: CustomerModel) => {
    const newCustomer = copyJSON<CustomerModel>(customer);
    onPushModal(
      <EditCustomerModal customer={newCustomer} title="Redigera kund" />
    );
  };

  if (!customers) {
    gotoLogin();
    return null;
  }

  return (
    <>
      <TableActions>
        <TableActionsFilters>
          <Toggle value={showRemoved} onToggle={setShowRemoved} /> Visa
          borttagna
        </TableActionsFilters>

        <IconButton icon={faPlusCircle} onClick={handleAddCustomerClick}>
          Lägg till
        </IconButton>
      </TableActions>
      <SortedTable
        columnSettings={[
          { header: 'Namn', attribute: 'name' },
          {
            header: 'Senast ändrad',
            attribute: 'dateModified',
            formatter: (date) => toUserDateTimeString(new Date(date as string)),
          },
          {
            header: 'Senast ändrad av',
            attribute: 'modifiedByID',
            formatter: (userId: number) => (users && users[userId]) || '-',
          },
          ...(showRemoved
            ? [
                {
                  header: 'Borttagen',
                  attribute: 'isRemoved',
                  formatter: (v: boolean) => (v ? 'Ja' : 'Nej'),
                } as const,
              ]
            : []),
        ]}
        rows={customers.filter((c) => showRemoved || !c.isRemoved)}
        borderColor={Colors.background.mainDark}
        onRowClick={handleEditCustomerClick}
      />
    </>
  );
};

export default CustomersTab;
