import { useRef, useState, useEffect } from 'react';

const useDimensions = <E extends HTMLElement = HTMLElement>(
  delay: number = 0
) => {
  const ref = useRef<E>(null);
  const [dimensions, setDimensions] = useState({});
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const handleResize = () => {
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      if (ref && ref.current) {
        setDimensions(ref.current.getBoundingClientRect());
      }
    }, delay);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref.current]);

  return [ref, dimensions] as const;
};

export default useDimensions;
