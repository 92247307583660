import React from 'react';
import Wrapper from './Wrapper';
import ModalButtonSetting from './ModalButtonSetting';
import { useDispatch } from 'react-redux';
import { popModal } from 'store/modal/actions';
import ModalButton from './ModalButton';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';

const Title = styled.div`
  width: 100%;
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
  padding: ${Sizes.padding.mini}px ${Sizes.padding.medium}px;
  border-bottom: 1px solid ${Colors.background.mainDark};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${Sizes.padding.medium}px;
  border-color: ${Colors.background.mainDark};
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: ${Sizes.padding.small}px;
`;

interface Props {
  buttons?: ModalButtonSetting[];
  title?: string;
  className?: string;
}

const DefaultModal: React.SFC<Props> = ({
  children,
  className,
  buttons,
  title,
}) => {
  const dispatch = useDispatch();

  const defaultButtons: ModalButtonSetting[] = [
    {
      label: 'Ok',
      onClick: () => dispatch(popModal()),
    },
  ];

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <MainContent className={className}>{children}</MainContent>
      <ButtonsWrap>
        {(buttons || defaultButtons).map((button, index) => (
          <ModalButton
            key={index}
            onClick={button.onClick}
            disabled={button.disabled}
          >
            {button.label}
          </ModalButton>
        ))}
      </ButtonsWrap>
    </Wrapper>
  );
};

export default DefaultModal;
