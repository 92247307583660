import React from 'react';
import YardCaseModel from 'gen/YardCaseModel';
import YardCaseStatus from 'gen/YardCaseStatus';

import styled, { css } from 'styled-components';
import Colors from 'constants/Colors';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import YardCasePreviewModel from 'gen/YardCasePreviewModel';
import BasicDataModel from 'gen/BasicDataModel';

const Wrapper = styled.span<{ color?: string }>`
  font-size: 15px;

  ${({ color }) =>
    color
      ? css`
          color: ${color};
        `
      : ``}
`;

interface Props {
  yardCase?: YardCaseModel | YardCasePreviewModel;
  yardCaseStatuses: BasicDataModel['yardCaseStatuses'] | null;
}

const YardCaseStatusText: React.SFC<Props> = ({
  yardCase,
  yardCaseStatuses,
}) => {
  if (!yardCase || !yardCaseStatuses) {
    return null;
  }

  const getColor = () => {
    switch (yardCase.status) {
      case YardCaseStatus.Cancelled:
        return Colors.bad;

      default:
        return undefined;
    }
  };

  return (
    <Wrapper color={getColor()}>
      <YardCaseStatusIcon status={yardCase.status} />{' '}
      {yardCaseStatuses[yardCase.status]}
    </Wrapper>
  );
};

const YardCaseStatusIcon: React.SFC<{ status: YardCaseStatus }> = ({
  status,
}) => {
  switch (status) {
    case YardCaseStatus.Cancelled:
      return <FontAwesomeIcon icon={faTrashAlt} />;

    default:
      return null;
  }
};

export default YardCaseStatusText;
