enum FieldType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  SELECT = 'select',
  SELECT_NO_DEFAULT = 'select_no_default',
  EDITABLE_SELECT = 'editable_select',
  MULTI_SELECT = 'multi_select',
  DATE = 'date',
  TOGGLE = 'toggle',
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
  DATETIME = 'datetime',
  READONLY = 'readonly',
}

export default FieldType;
