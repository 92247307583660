import React from 'react';
import styled, { css } from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';
import DropZone from '../DropZone';
import CommentHeaderType from 'gen/CommentHeaderType';
import CommentHeaderTypeIcon from '../CommentHeaderTypeIcon';
import DropdownSection from 'components/Dropdown/DropDownSection';
import ListRow from 'components/Dropdown/ListRow';
import PendingAttachments from '../PendingAttachments';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import useCommentInputState from './useCommentInputState';

const DropWrapper = styled(DropZone)`
  border-radius: ${Sizes.radius.element}px;
  background-color: ${Colors.background.main};
`;

const DropInnerWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const Input = styled(AutoSizedTextArea)`
  flex: 1;
  padding: ${Sizes.padding.mini}px;
  padding-right: ${Sizes.padding.small}px;
  height: 100px;
  min-height: 80px;
  max-height: 200px;

  border: 2px solid transparent;
  background-color: inherit;
  border-radius: 0 ${Sizes.radius.element}px ${Sizes.radius.element}px 0;

  &:focus {
    outline: none;
    border-color: ${Colors.focus};
  }
`;

const ModifierButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${Sizes.radius.element}px 0 0 ${Sizes.radius.element}px;
  padding-top: ${Sizes.padding.mini}px;
  padding-left: ${Sizes.padding.mini}px;

  font-size: 12px;
  background-color: inherit;
`;

const modifierButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin: ${Sizes.padding.nano}px;
  margin-bottom: 0;

  background: transparent;
  border-radius: 50%;
  border: 1px solid ${Colors.background.mainDark};
  cursor: pointer;

  &:active {
    background-color: ${Colors.background.mainDark};
  }
  &:focus {
    outline: none;
    border-color: ${Colors.focus};
  }
`;

const TypePickerButton = styled.button`
  ${modifierButtonStyle}
`;

const hiddenFilePickerId = 'add-comment-hidden-file-picker';
// Hax to hide ugly default file picker button, clicking the styled label FilePickerLabelButton activates it
const HiddenFilePicker = styled.input`
  position: fixed;
  top: -100px;
  left: -100px;
`;

const FilePickerLabelButton = styled.label`
  ${modifierButtonStyle}
  ${'#' + hiddenFilePickerId}:focus + & {
    border-color: ${Colors.focus};
  }
`;

interface Props {
  state: ReturnType<typeof useCommentInputState>;
  className?: string;
}

const CommentInput: React.SFC<Props> = ({ state, className }) => {
  const {
    commentText,
    setCommentText,
    commentType,
    setCommentType,
    attachments,
    setAttachments,
    typePickerVisible,
    setTypePickerVisible,
    yardCase,
    handleFileDrop,
  } = state;

  const makeListRow = (commentType: CommentHeaderType, label: string) => (
    <ListRow
      clickable
      firstChildIsIcon
      onClick={() => {
        setCommentType(commentType);
        setTypePickerVisible(false);
      }}
    >
      <CommentHeaderTypeIcon headerType={commentType} />
      {label}
    </ListRow>
  );

  return (
    <>
      <PendingAttachments
        files={attachments}
        onDeleteFile={(index) =>
          setAttachments(attachments.filter((a, i) => i !== index))
        }
      />
      <DropWrapper onFilesDropped={handleFileDrop} className={className}>
        <DropInnerWrap>
          <ModifierButtonsWrapper>
            <TypePickerButton
              onClick={() => setTypePickerVisible((v) => !v)}
              title="Typ av bilaga"
              className="comment-type-ignore-click"
            >
              <CommentHeaderTypeIcon headerType={commentType} />
            </TypePickerButton>
            <DropdownSection
              visible={typePickerVisible}
              onClickOutside={() => setTypePickerVisible(false)}
              ignoreClickOutsideClassName="comment-type-ignore-click"
              fitChildren
            >
              {makeListRow(CommentHeaderType.Other, 'Övrigt')}
              {makeListRow(CommentHeaderType.InitialImages, 'In-bild')}
              {makeListRow(CommentHeaderType.ImagesOnReturn, 'Ut-bild')}
              {makeListRow(CommentHeaderType.OrderEmail, 'E-post')}
            </DropdownSection>

            <HiddenFilePicker
              id={hiddenFilePickerId}
              type="file"
              multiple={true}
              onChange={(eve) => {
                eve.currentTarget.files &&
                  handleFileDrop(eve.currentTarget.files);
                // clear input so that we can pick same file multiple times
                eve.currentTarget.value = '';
              }}
            />
            <FilePickerLabelButton
              htmlFor={hiddenFilePickerId}
              title="Bifoga fil"
            >
              <FontAwesomeIcon icon={faPaperclip} />
            </FilePickerLabelButton>
          </ModifierButtonsWrapper>

          <Input
            type="text"
            placeholder="Skriv en kommentar eller släpp filer här..."
            value={commentText}
            onChange={(eve) =>
              setCommentText((eve.target as HTMLTextAreaElement).value)
            }
            disabled={!yardCase}
          />
        </DropInnerWrap>
      </DropWrapper>
    </>
  );
};

export default CommentInput;
