import React from 'react';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors, { translucent } from 'constants/Colors';
import YardCaseEditStatus from 'store/yardCase/YardCaseEditStatus';
import CommentInput from './CommentInput';
import useCommentInputState from './useCommentInputState';

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;

  padding: ${Sizes.padding.medium}px;
  padding-bottom: 0;
  margin: 0 -${Sizes.padding.large}px;

  background: linear-gradient(
    ${Colors.background.mainLight} calc(100% - 15px),
    transparent
  );
`;

const SendButton = styled.button`
  margin-top: ${Sizes.padding.nano}px;
  margin-left: auto;
  padding: ${Sizes.padding.mini}px ${Sizes.padding.small}px;

  border-radius: ${Sizes.radius.inputElement}px;
  border: 2px solid transparent;
  background-color: transparent;
  color: ${Colors.background.accent};
  font-weight: 500;
  cursor: pointer;

  &:active {
    background-color: ${translucent(Colors.background.mainDark)};
  }
  &:focus {
    outline: none;
    border-color: ${Colors.focus};
  }
  &:disabled {
    color: ${Colors.background.mainDark};
  }
`;

const AddComment = () => {
  const commentInputState = useCommentInputState();

  return (
    <Wrapper>
      <CommentInput state={commentInputState} />
      <SendButton
        disabled={
          commentInputState.editStatus === YardCaseEditStatus.saving ||
          commentInputState.editStatus === YardCaseEditStatus.blockedSaving ||
          !commentInputState.yardCase
        }
        onClick={(eve) => {
          eve.preventDefault();
          commentInputState.handleSendClick();
        }}
      >
        Spara
      </SendButton>
    </Wrapper>
  );
};

export default AddComment;
