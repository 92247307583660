import React from 'react';
import styled from 'styled-components';
import Colors, { translucent } from 'constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Sizes from 'constants/Sizes';
import { Item } from '.';

const Wrap = styled.span<{ colr?: string }>`
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-color: ${({ colr }) => (!colr ? Colors.background.accent : colr)};
  border-radius: 1000px;
  padding: 0 ${Sizes.padding.small}px;
  margin: 2px 0;
  margin-right: ${Sizes.padding.small}px;
  font-size: 15px;

  & > a {
    text-decoration: none;
    color: ${Colors.foreground.accent};
  }

  & > a > *:first-child {
    margin-right: ${Sizes.padding.mini}px;
  }

  & > a:hover {
    text-decoration: none;
    color: ${Colors.foreground.accent};
  }

  & > a:active {
    color: ${translucent(Colors.foreground.accent)};
  }
`;

const DeleteButtonWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  background: linear-gradient(
    -90deg,
    ${Colors.background.accent} 40%,
    transparent 100%
  );
  padding-left: 15px;
`;

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: linear-gradient(
    -90deg,
    ${Colors.background.accent} 40%,
    transparent 100%
  );
  color: ${Colors.foreground.accent};
  padding-left: 5px;
  padding-right: 5px;

  &:focus {
    outline: none;
  }
`;

interface Props {
  item: Item;
}

const PillItem: React.SFC<Props> = ({ item }) => (
  <Wrap colr={item.backgroundColor}>
    <a href={item.url} title={item.name} onClick={item.onClick}>
      {item.icon}
      {item.name}
    </a>
    {item.onDelete && (
      <DeleteButtonWrap>
        <DeleteButton onClick={item.onDelete} title={'Ta bort ' + item.name}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </DeleteButton>
      </DeleteButtonWrap>
    )}
  </Wrap>
);

export default PillItem;
