import React from 'react';
import styled, { css } from 'styled-components';
import Colors, { translucent } from 'constants/Colors';
import Sizes from 'constants/Sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid ${Colors.background.mainDark};
  border-radius: ${Sizes.radius.element}px;
  overflow: hidden;
`;

interface PageProps {
  active: boolean;
}

const Page = styled.button<PageProps>`
  border: none;
  background-color: transparent;
  padding: ${Sizes.padding.mini}px ${Sizes.padding.small}px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-right: 1px solid ${Colors.background.mainDark};

  ${({ active }) =>
    active &&
    css`
      background-color: ${translucent(Colors.background.mainDark)};
    `}
`;

const NavButton = styled.button`
  border: none;
  background: transparent;
  border-radius: ${Sizes.radius.element}px 0 0 ${Sizes.radius.element}px;
  padding: 0 ${Sizes.padding.small}px;

  border: 2px solid transparent;
  border-right: 1px solid ${Colors.background.mainDark};
  &:last-child {
    border-right: 2px solid transparent;
    border-left-width: 1px;
    border-radius: 0 ${Sizes.radius.element}px ${Sizes.radius.element}px 0;
  }

  &:active {
    background-color: ${translucent(Colors.background.mainDark)};
  }

  &:focus {
    outline: none;
    border-color: ${Colors.focus};
  }
`;

interface Props {
  page: number;
  pages: number;
  onSetPage(page: number): void;
  className?: string;
}

const PaginatorNavigator: React.SFC<Props> = ({
  page,
  pages,
  onSetPage,
  className,
}) => {
  const pageComponents = [];
  for (let i = 0; i < pages; i++) {
    pageComponents.push(
      <Page active={page === i} key={i} onClick={() => onSetPage(i)}>
        {i + 1}
      </Page>
    );
  }

  return (
    <Wrapper className={className}>
      <NavButton onClick={() => onSetPage(Math.max(0, page - 1))}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </NavButton>
      {pageComponents}
      <NavButton onClick={() => onSetPage(Math.min(pages - 1, page + 1))}>
        <FontAwesomeIcon icon={faAngleRight} />
      </NavButton>
    </Wrapper>
  );
};

export default PaginatorNavigator;
