import React, { useState } from 'react';
import Select from 'components/inputs/Select';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';

const Wrapper = styled.div`
  border-color: ${Colors.background.mainDark};
`;

const MySelect = styled(Select)`
  margin: ${Sizes.padding.mini}px ${Sizes.padding.small}px;
`;

interface Props {
  onSetPickedDate(date: Date): void;
}

const MonthPicker: React.SFC<Props> = ({ onSetPickedDate }) => {
  const now = new Date();
  const oneMonthBack = new Date();
  oneMonthBack.setMonth(now.getMonth() - 1, 1);

  const [pickedDate, setPickedDate] = useState(oneMonthBack);
  const handleSetPickedDate = (date: Date) => {
    onSetPickedDate(date);
    setPickedDate(date);
  };

  const compareDate = new Date(pickedDate);

  const monthOptions: React.ReactChild[] = [];
  for (let i = 0; i < 12; i++) {
    compareDate.setMonth(i, 1);
    monthOptions.push(
      <option
        key={i}
        value={i}
        disabled={compareDate.getTime() > oneMonthBack.getTime()}
      >
        {compareDate.toLocaleDateString('sv-SE', { month: 'long' })}
      </option>
    );
  }

  const maxYear = now.getFullYear() - (now.getMonth() === 0 ? 1 : 0);
  const yearOptions: React.ReactChild[] = [];
  for (let i = 2019; i <= maxYear; i++) {
    yearOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <Wrapper>
      År:
      <MySelect
        value={pickedDate.getFullYear()}
        onChange={(eve) => {
          const newDate = new Date(pickedDate);
          newDate.setFullYear(Number(eve.target.value));
          newDate.setMonth(0, 1);
          handleSetPickedDate(newDate);
        }}
      >
        {yearOptions}
      </MySelect>
      Månad:
      <MySelect
        value={pickedDate.getMonth()}
        onChange={(eve) => {
          const newDate = new Date(pickedDate);
          newDate.setMonth(Number(eve.target.value));
          handleSetPickedDate(newDate);
        }}
      >
        {monthOptions}
      </MySelect>
    </Wrapper>
  );
};

export default MonthPicker;
