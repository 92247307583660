import React, { useState, useEffect, useMemo } from 'react';
import YardCaseModel from 'gen/YardCaseModel';
import DefaultModal from 'components/modal/Default';
import LoadingSpinner from 'components/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { useDispatchBind } from 'hooks';
import { pushModal, popModal } from 'store/modal/actions';
import ErrorModal from 'components/modal/Error';
import Api from 'api';
import { IFormValues, IFormValue } from 'components/Form/types/IFormValues';
import {
  asStringOrUndefined,
  asNumberOrUndefined,
  toFormDateString,
} from 'components/Form/utils';
import ALPSearchResponseModel from 'gen/ALPSearchResponseModel';
import ALPAssignmentModel from 'gen/ALPAssignmentModel';
import { copyJSON, gotoLogin, toUserDateString } from 'utils';
import styled from 'styled-components';
import Table from 'components/Table';
import Button from 'components/inputs/Button';
import Sizes from 'constants/Sizes';
import { selectBasicData } from 'store/basicData/selectors';
import MergeIntoFormModal from './MergeIntoFormModal';
import useFormFieldsByName from '../useFormFieldsByName';

const ResultsModal = styled(DefaultModal)`
  min-width: 900px;
  max-height: 95vh;
  max-width: 95vw;
  overflow: auto;
  padding: 0;

  & > * {
    width: 100%;
  }

  .table-column:last-child .table-cell {
    padding: 0 ${Sizes.padding.mini}px;
    justify-content: center;
  }
`;

interface Props {
  formValues: IFormValues<keyof YardCaseModel>;
  onResultPicked(updatedYardCase: IFormValues<keyof YardCaseModel>): void;
  searchAssistMode: boolean;
}

const VTRSearchModal: React.FC<Props> = ({
  formValues,
  onResultPicked,
  searchAssistMode,
}) => {
  const [searching, setSearching] = useState(true);
  const [invalidFormState, setInvalidFormState] = useState(false);
  const [response, setResponse] = useState<ALPSearchResponseModel>({
    result: [],
  });

  const basicData = useSelector(selectBasicData);
  const fieldsByFieldName = useFormFieldsByName();

  const dispatch = useDispatch();
  const onPopModal = useDispatchBind(popModal, dispatch);
  const onPushModal = useDispatchBind(pushModal, dispatch);

  if (!basicData) {
    gotoLogin();
    return null;
  }

  const defaultYardCase = useMemo(
    () => copyJSON<YardCaseModel>(basicData.templates.yardCaseModel),
    [basicData]
  );

  useEffect(() => {
    if (
      formValues.assistAssignmentID ||
      formValues.chassisNumber ||
      formValues.regNumber
    ) {
      searchVTR();
    } else {
      setInvalidFormState(true);
    }
  }, []);

  const searchVTR = async () => {
    setSearching(true);

    try {
      let newResponse: ALPSearchResponseModel;

      if (searchAssistMode) {
        newResponse = await Api.searchALPAssignment(
          asNumberOrUndefined(formValues.assistAssignmentID),
          asStringOrUndefined(formValues.chassisNumber),
          asStringOrUndefined(formValues.regNumber)
        );
      } else {
        newResponse = await Api.searchVTR(
          asNumberOrUndefined(formValues.assistAssignmentID),
          asStringOrUndefined(formValues.chassisNumber),
          asStringOrUndefined(formValues.regNumber)
        );
      }

      setResponse(newResponse);
    } catch (err) {
      onPopModal();
      onPushModal(
        <ErrorModal exception={err}>
          Ett fel inträffade under VTR- eller Assist-sökningen
        </ErrorModal>
      );
    }
    setSearching(false);
  };

  const handleCopyClick = (item: ALPAssignmentModel) => {
    const newFormValues = copyJSON(formValues);
    const conflictingFields = {} as Partial<IFormValues<keyof YardCaseModel>>;

    const mergeIntoForm = <
      FieldName extends keyof IFormValues<keyof YardCaseModel>
    >(
      fieldName: FieldName,
      value: IFormValue | null
    ) => {
      if (value === null || value === undefined) return;

      const defaultYardcaseValue = defaultYardCase[fieldName];
      const fieldHasBeenEdited =
        newFormValues[fieldName] != defaultYardcaseValue;

      if (!fieldHasBeenEdited) {
        newFormValues[fieldName] = value;
      } else if (newFormValues[fieldName] !== value) {
        conflictingFields[fieldName] = value;
      }
    };

    mergeIntoForm('assistAssignmentID', item.assignmentID);
    mergeIntoForm('brandName', item.brandName);
    mergeIntoForm('chassisNumber', item.chassisNumber);
    mergeIntoForm('color', item.color);
    mergeIntoForm('country', item.country);
    mergeIntoForm('hasFrontAndRearAxleDrive', item.hasFrontAndRearAxleDrive);
    mergeIntoForm('insuranceCompany', item.insuranceCompany);
    mergeIntoForm('ownerCity', item.ownerCity);
    mergeIntoForm('ownerFullName', item.ownerFullName);
    mergeIntoForm('ownerSsnOrOrgNr', item.ownerSsnOrOrgNr);
    mergeIntoForm('ownerStreetAddress', item.ownerStreetAddress);
    mergeIntoForm('ownerZipCode', item.ownerZipCode);
    mergeIntoForm('radioNr', item.radioNr);
    mergeIntoForm('regNumber', item.regNumber);
    mergeIntoForm('totalWeightKg', item.totalWeightKg);
    mergeIntoForm('vehicleType', item.vehicleType);
    mergeIntoForm('fuelType', item.fuelType);
    mergeIntoForm('assistComment', item.notes);
    mergeIntoForm('assistConcept', item.concept);
    mergeIntoForm(
      'activeFrom',
      item.started ? toFormDateString(new Date(item.started)) : undefined
    );

    if (Object.keys(conflictingFields).length > 0) {
      onPopModal();
      onPushModal(
        <MergeIntoFormModal
          formValues={newFormValues}
          conflictingFields={conflictingFields}
          fieldsByFieldName={fieldsByFieldName}
          onMergeCompleted={onResultPicked}
          onAbort={() => onResultPicked(formValues)}
        />
      );
    } else {
      onResultPicked(newFormValues);
      onPopModal();
    }
  };

  if (invalidFormState) {
    return (
      <DefaultModal>
        Ange ett assist ärende-id, chassisnummer eller regnummer för att kunna
        utföra en VTR- eller Assist-sökning.
      </DefaultModal>
    );
  } else if (searching) {
    return (
      <DefaultModal buttons={[]}>
        <LoadingSpinner>
          Söker {searchAssistMode ? 'Assist' : 'VTR'}...
        </LoadingSpinner>
      </DefaultModal>
    );
  }

  if (response.result.length === 0) {
    return (
      <DefaultModal>
        {searchAssistMode ? 'Assist' : 'VTR'}
        -sökningen gav inga resultat.
      </DefaultModal>
    );
  }

  return (
    <ResultsModal
      title={searchAssistMode ? 'Assist sökresultat' : 'VTR sökresultat'}
      buttons={[
        {
          label: 'Avbryt',
          onClick: () => onPopModal(),
        },
      ]}
    >
      <Table
        columnSettings={
          searchAssistMode
            ? [
                {
                  header: 'Uppdrags-id',
                  attribute: 'assignmentID',
                },
                {
                  header: 'Regnummer',
                  attribute: 'regNumber',
                },
                {
                  header: 'Startdatum',
                  attribute: 'started',
                  formatter: (v) =>
                    v ? toUserDateString(new Date(v as string)) : '-',
                },
                {
                  header: 'Fordonstyp',
                  attribute: 'vehicleType',
                },
                {
                  header: 'Bränsletyp',
                  attribute: 'fuelType',
                },
                {
                  header: 'Radionummer',
                  attribute: 'radioNr',
                },
                {
                  header: '',
                  cellRenderer: ({ row }) => (
                    <Button smaller onClick={() => handleCopyClick(row)}>
                      Välj
                    </Button>
                  ),
                },
              ]
            : [
                {
                  header: 'Regnummer',
                  attribute: 'regNumber',
                },
                {
                  header: 'Chassisnummer',
                  attribute: 'chassisNumber',
                },
                {
                  header: 'Modell',
                  attribute: 'brandName',
                },
                {
                  header: 'Fordonstyp',
                  attribute: 'vehicleType',
                },
                {
                  header: 'Bränsletyp',
                  attribute: 'fuelType',
                },
                {
                  header: 'Ägare',
                  attribute: 'ownerFullName',
                },
                {
                  header: '',
                  cellRenderer: ({ row }) => (
                    <Button smaller onClick={() => handleCopyClick(row)}>
                      Välj
                    </Button>
                  ),
                },
              ]
        }
        rows={response.result}
      />
    </ResultsModal>
  );
};

export default VTRSearchModal;
