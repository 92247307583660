import React from 'react';
import styled, { css } from 'styled-components';
import { Link, LinkProps, useRouteMatch } from 'react-router-dom';
import Colors, { translucent } from 'constants/Colors';
import Sizes from 'constants/Sizes';
import Container from 'components/Container';

const HeaderNavItem = styled.li`
  box-sizing: border-box;
  display: list-item;
`;

interface StyledLinkProps {
  routeMatch?: boolean;
}

// Link puts all its received props into DOM, which React doesn't like
const FilterPropsLink = ({
  routeMatch,
  ...props
}: LinkProps & StyledLinkProps) => <Link {...props} />;
const StyledLink = styled(FilterPropsLink)<StyledLinkProps>`
  &,
  &:hover,
  &:visited,
  &:active {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-decoration: none;
    color: ${Colors.foreground.main};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    padding: ${Sizes.padding.small}px;
    margin: -${Sizes.padding.small}px ${Sizes.padding.nano}px;

    border-radius: ${Sizes.radius.inputElement}px;
    transition: background-color 0.2s;
  }

  &:hover {
    background: ${translucent(Colors.background.accent)};
  }

  ${({ routeMatch }) =>
    routeMatch &&
    css`
      background: ${translucent(Colors.background.accent)};
    `}
`;

type MyLinkProps = LinkProps & { to: string; noRouteMatch?: boolean };
const MyLink = ({ to, noRouteMatch, ...props }: MyLinkProps) => {
  const match = useRouteMatch({ path: to, exact: true });

  return (
    <StyledLink {...{ to, ...props }} routeMatch={!noRouteMatch && !!match} />
  );
};

export const NavLink = (props: MyLinkProps) => (
  <HeaderNavItem>
    <MyLink {...props} />
  </HeaderNavItem>
);

export const NavBar = styled.nav`
  box-shadow: ${Colors.boxShadow.card};
  padding: ${Sizes.padding.small}px;
  padding-bottom: ${Sizes.padding.large}px;
`;

export const NavItems = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Content = styled(Container)`
  display: flex;
  flex-direction: row;
`;

export const LogoItem = styled(Link)`
  &,
  &:hover,
  &:active {
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    color: ${Colors.foreground.main};
    font-size: 1.5em;
  }

  & > img {
    position: relative;
    top: 5px;
  }
`;
