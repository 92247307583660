import { ApplicationState } from 'store';
import { createSelector } from 'reselect';

export const selectInvoice = (state: ApplicationState) => state.invoice;

export const selectCreatingMonthlyInvoiceStatus = createSelector(
  selectInvoice,
  (state) => state.creatingMonthlyInvoiceStatus
);

export const selectMonthlyInvoiceBatches = createSelector(
  selectInvoice,
  (state) => state.monthlyInvoiceBatches
);
