export const translucent = (color: string) => color + '60';

const Colors = {
  background: {
    accent: '#ff6659',
    accentLight: '#ffa49c',
    accentDark: '#d32f2f',
    main: '#f1f1f1',
    mainLight: '#FFFFFF',
    mainDark: '#d7d7d7',
  },
  foreground: {
    accent: '#FFFFFF',
    accentLight: '#FFFFFF',
    accentDark: '#FFFFFF',
    main: '#000000',
    mainLight: '#000000',
    mainDark: '#000000',
  },
  boxShadow: {
    button: '0px 1px 3px 0px rgba(0, 0, 0, 0.50)',
    modal: '0px 5px 30px 0px rgba(0,0,0,0.15), 0 5px 6px 0px rgba(0,0,0,0.1)',
    card: '0 5px 40px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(0, 0, 0, 0.1)',
    element:
      '0px 4px 15px 0px rgba(0, 0, 0, 0.05), 0 1px 5px 0px rgba(0, 0, 0, 0.2)',
  },
  focus: '#7da8ff',
  good: '#00ff00',
  bad: '#ff0000',
} as const;

export default Colors;
