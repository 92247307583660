import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectActiveYardCase,
  selectYardCaseEditStatus,
} from 'store/yardCase/selectors';
import Button from 'components/inputs/Button';
import YardCaseStatus from 'gen/YardCaseStatus';
import YardCaseEditStatus from 'store/yardCase/YardCaseEditStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatchBind } from 'hooks';
import { pushModal } from 'store/modal/actions';
import YardCaseActionButton from './YardCaseActionButton';
import CancelYardCaseModal from './CancelYardCaseModal';

export const CancelYardCaseButtonText = 'Makulera';

const CancelYardCaseButton: React.SFC = () => {
  const yardCase = useSelector(selectActiveYardCase);
  const editStatus = useSelector(selectYardCaseEditStatus);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const handleCancelYardCase = (eve: React.MouseEvent) => {
    eve.preventDefault();
    eve.stopPropagation();

    onPushModal(<CancelYardCaseModal />);
  };

  if (!yardCase) {
    return null;
  }

  const disableButton = editStatus !== YardCaseEditStatus.saved;

  switch (yardCase.status) {
    case YardCaseStatus.Active:
      return (
        <YardCaseActionButton
          disabled={disableButton}
          onClick={handleCancelYardCase}
        >
          <FontAwesomeIcon icon={faTrash} /> {CancelYardCaseButtonText}
        </YardCaseActionButton>
      );

    default:
      return null;
  }
};

export default CancelYardCaseButton;
