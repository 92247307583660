import { ImmutableReducer, ActionReturns } from 'utils/utilityTypes';
import * as InvoiceActions from './actions';
import InvoiceActionTypes from './ActionTypes';
import MonthlyInvoiceBatchModel from 'gen/MonthlyInvoiceBatchModel';
import { RequestStatus } from 'hooks/useApiResponse';

export interface InvoiceState {
  monthlyInvoiceBatches: MonthlyInvoiceBatchModel[];
  creatingMonthlyInvoiceStatus?: RequestStatus;
}

const initialState: InvoiceState = {
  monthlyInvoiceBatches: [],
  creatingMonthlyInvoiceStatus: undefined,
};

type actions = ActionReturns<typeof InvoiceActions>;

const invoiceReducer: ImmutableReducer<InvoiceState, actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case InvoiceActionTypes.SetCreatingMonthlyInvoiceBatchStatus:
      return { ...state, creatingMonthlyInvoiceStatus: action.requestStatus };

    case InvoiceActionTypes.SetMonthlyInvoiceBatches:
      return { ...state, monthlyInvoiceBatches: action.batches };

    case InvoiceActionTypes.MonthlyInvoiceBatchCreated:
      return {
        ...state,
        monthlyInvoiceBatches: [...state.monthlyInvoiceBatches, action.batch],
      };

    default:
      return state;
  }
};

export default invoiceReducer;
