import styled from 'styled-components';
import Button from 'components/inputs/Button';
import Sizes from 'constants/Sizes';

const YardCaseActionButton = styled(Button)`
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2),
    0 1px 5px 0px rgba(0, 0, 0, 0.2);
`;

export default YardCaseActionButton;
