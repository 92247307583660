import React from 'react';
import YardCaseCommentAttachmentModel from 'gen/YardCaseCommentAttachmentModel';
import PillItems, { Item } from 'components/PillItems';
import FileIcon from './FileIcon';
import styled from 'styled-components';
import Colors from 'constants/Colors';
import Api from 'api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const MyPillItems = styled(PillItems)`
  border-bottom: 1px solid ${Colors.background.mainDark};
`;

interface Props {
  attachments: YardCaseCommentAttachmentModel[];
  editMode: boolean;
  onDeleteAttachment(attachment: YardCaseCommentAttachmentModel): void;
  locked: boolean;
}

const AttachmentItems: React.SFC<Props> = ({
  attachments,
  editMode,
  onDeleteAttachment,
  locked,
}) => {
  return (
    <MyPillItems
      items={attachments.map(
        (item): Item => ({
          name: item.displayName,
          icon: locked ? (
            <FontAwesomeIcon icon={faLock} />
          ) : (
            <FileIcon fileEnding={item.fileEnding} />
          ),
          url: locked ? undefined : Api.getAttachmentURL(item.id),
          backgroundColor: locked ? Colors.background.mainDark : undefined,
        })
      )}
      onDeleteItem={
        editMode
          ? (item: Item, index: number) =>
              onDeleteAttachment(attachments[index])
          : undefined
      }
    />
  );
};

export default AttachmentItems;
