import React, { useState } from 'react';
import ConfirmModal from 'components/modal/Confirm';
import MonthPicker from './MonthPicker';
import { RequestStatus } from 'hooks/useApiResponse';
import { useDispatch } from 'react-redux';
import { useDispatchBind } from 'hooks';
import { pushModal } from 'store/modal/actions';
import Api from 'api';
import ErrorModal from 'components/modal/Error';
import DefaultModal from 'components/modal/Default';
import {
  setCreatingMonthlyInvoiceBatchStatus,
  monthlyInvoiceBatchCreated,
} from 'store/invoice/actions';

const CreateMonthlyInvoiceModal: React.SFC = () => {
  const oneMonthBack = new Date();
  oneMonthBack.setMonth(new Date().getMonth() - 1, 1);
  const [pickedDate, setPickedDate] = useState(oneMonthBack);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const onStatusChange = useDispatchBind(
    setCreatingMonthlyInvoiceBatchStatus,
    dispatch
  );
  const onMonthlyInvoiceBatchCreated = useDispatchBind(
    monthlyInvoiceBatchCreated,
    dispatch
  );

  const handleConfirm = async () => {
    try {
      onStatusChange(RequestStatus.Fetching);
      const response = await Api.createMonthlyInvoiceBatch(
        pickedDate.getFullYear(),
        pickedDate.getMonth() + 1
      );

      onMonthlyInvoiceBatchCreated(response);
      onStatusChange(RequestStatus.Fetched);
      onPushModal(
        <DefaultModal>
          Månadsunderlag har nu skapats för {pickedDate.getFullYear()}-
          {pickedDate.getMonth() + 1}.
        </DefaultModal>
      );
    } catch (err) {
      onStatusChange(RequestStatus.Error);
      onPushModal(
        <ErrorModal exception={err}>
          Ett fel inträffade när månadsunderlaget skulle skapas.
        </ErrorModal>
      );
    }
  };

  return (
    <ConfirmModal
      title="Skapa månadsunderlag"
      onConfirm={handleConfirm}
      confirmLabel="Skapa månadsunderlag"
      cancelLabel="Avbryt"
    >
      <p>
        Skapande av månadsunderlag för alla kunder kan ta upp till 2 minuter att
        färdigställas.
      </p>

      <MonthPicker onSetPickedDate={setPickedDate} />
    </ConfirmModal>
  );
};

export default CreateMonthlyInvoiceModal;
