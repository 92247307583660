import React, { useState, useEffect } from 'react';
import Table, { Props as TableProps } from './.';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import PaginatorNavigator from 'components/PaginatorNavigator';

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${Sizes.padding.small}px;
`;

export interface Props<Obj extends Object> extends TableProps<Obj> {
  rowsPerPage?: number;
}

const PaginatedTable = <Obj extends Object>({
  rows,
  rowsPerPage,
  ...props
}: Props<Obj>) => {
  const [page, setPage] = useState(0);
  const rowsSegment = rowsPerPage
    ? rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    : rows;
  const pages = rowsPerPage ? Math.ceil(rows.length / rowsPerPage) : 1;

  useEffect(() => {
    setPage(0);
  }, [rows]);

  return (
    <>
      {pages > 1 && (
        <PaginatorWrapper>
          <PaginatorNavigator pages={pages} page={page} onSetPage={setPage} />
        </PaginatorWrapper>
      )}
      <Table rows={rowsSegment} {...props} />
    </>
  );
};

export default PaginatedTable;
