import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import Arrow from './Arrow';
import PageIndicator from './PageIndicator';
import useDimensions from 'hooks/useDimensions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
`;

const SliderWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

interface ContentProps {
  translation: number;
  transition: number;
  minHeight: number;
}

const Content = styled.div<ContentProps>`
  transform: translateX(${props => -props.translation}px);
  transition: transform ease-out ${props => props.transition}s;
  display: flex;
`;

const Slide = styled.img<{ dwidth: number }>`
  object-fit: contain;
  padding: 10px;
  width: ${({ dwidth }) => dwidth}px;
`;

interface Props {
  slides: string[];
  /** defines aspect ratio that the images should keep */
  imageWidth: number;
  /** defines aspect ratio that the images should keep */
  imageHeight: number;

  imageCount?: number;

  index?: number;
  onIndexChange?(index: number): void;
}

const Slider = (props: Props) => {
  const {
    slides,
    imageHeight,
    imageWidth,
    imageCount = 1,
    onIndexChange,
  } = props;

  const [wrapperRef, wrapperDimensions] = useDimensions<HTMLDivElement>(200);
  const contentRef = useRef<HTMLDivElement>(null);
  const preloadedImages = useRef<HTMLImageElement[]>([]);

  const [state, setState] = useState({
    index: 0,
    transition: 0.45,
    maxImageWidth: imageWidth,
    maxImageHeight: imageHeight,
  });
  const { transition, maxImageHeight, maxImageWidth } = state;

  // because the handle functions doesn't get state updates
  const activeIndexRef = useRef<number>(
    props.index === undefined ? state.index : props.index
  );
  activeIndexRef.current =
    props.index === undefined ? state.index : props.index;
  const wrapperDimensionsRef = useRef<typeof wrapperDimensions>(
    wrapperDimensions
  );
  wrapperDimensionsRef.current = wrapperDimensions;

  const setIndex = (i: number) => {
    if (props.index === undefined) {
      setState(state => ({ ...state, index: i }));
    } else {
      onIndexChange!(i);
    }
  };

  // preload images and get their dimensions
  useEffect(() => {
    for (let image of slides) {
      const toLoad = new Image();
      preloadedImages.current.push(toLoad);
      toLoad.src = image;

      toLoad.onload = function() {
        const anyThis = this as any;
        const { width, height } = anyThis;

        setState(state => {
          let { maxImageWidth, maxImageHeight } = state;
          if (width > maxImageWidth) {
            maxImageWidth = width;
          }
          if (height > maxImageHeight) {
            maxImageHeight = height;
          }
          return { ...state, maxImageHeight, maxImageWidth };
        });
      };
    }
  }, []);

  const startX = useRef<number>(0);
  const currX = useRef<number>(0);

  useEffect(() => {
    contentRef.current!.addEventListener('touchstart', handleTouchStart);
    contentRef.current!.addEventListener('touchmove', handleTouchMove);
    contentRef.current!.addEventListener('touchend', handleTouchEnd);

    return () => {
      contentRef.current!.removeEventListener('touchend', handleTouchEnd);
      contentRef.current!.removeEventListener('touchmove', handleTouchMove);
      contentRef.current!.removeEventListener('touchstart', handleTouchStart);
    };
  }, [contentRef.current]);

  const handleTouchStart = (eve: TouchEvent) => {
    startX.current = eve.touches[0].clientX;
    currX.current = eve.touches[0].clientX;
  };

  const handleTouchMove = (eve: TouchEvent) => {
    currX.current = eve.touches[0].clientX;
    contentRef.current!.style.transform = `translateX(${-calcRefTranslation()}px)`;
    contentRef.current!.style.transition = 'none';
  };

  const handleTouchEnd = (eve: TouchEvent) => {
    contentRef.current!.style.transition = '';
    contentRef.current!.style.transform = '';
    setIndex(translationToActiveIndex(calcRefTranslation()));
  };

  const calcRefTranslation = () =>
    getTranslation(activeIndexRef.current) + (startX.current - currX.current);

  const getWidth = () => {
    if (
      !wrapperDimensionsRef.current ||
      !(wrapperDimensionsRef.current as DOMRect).width
    ) {
      return 0;
    }
    return (wrapperDimensionsRef.current as DOMRect).width;
  };

  const getImageWidth = () => getWidth() / imageCount;

  const getImageHeight = () =>
    (maxImageHeight / maxImageWidth) * getImageWidth();

  const translationToActiveIndex = (translation: number) =>
    Math.min(
      Math.max(0, Math.round(translation / getImageWidth())),
      slides.length - 1
    );

  const getTranslation = (activeIndex: number) => activeIndex * getImageWidth();

  const nextSlide = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setIndex(
      activeIndexRef.current >= slides.length - 1
        ? 0
        : activeIndexRef.current + 1
    );
  };

  const prevSlide = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setIndex(
      activeIndexRef.current <= 0
        ? slides.length - 1
        : activeIndexRef.current - 1
    );
  };

  const multiPage = slides.length > 1;

  return (
    <Wrapper>
      {multiPage && <Arrow direction="left" handleClick={prevSlide} />}
      <SliderWrapper ref={wrapperRef}>
        <Content
          translation={getTranslation(activeIndexRef.current)}
          transition={transition}
          minHeight={getImageHeight()}
          ref={contentRef}
        >
          {slides.map((slide, i) => (
            <Slide dwidth={getImageWidth()} key={slide + i} src={slide} />
          ))}
        </Content>
      </SliderWrapper>
      {multiPage && <Arrow direction="right" handleClick={nextSlide} />}
      {multiPage && (
        <PageIndicator
          pages={slides.length}
          currentPage={activeIndexRef.current}
          onPageClick={setIndex}
        />
      )}
    </Wrapper>
  );
};

export default Slider;
