import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectActiveYardCase,
  selectYardCaseEditStatus,
} from 'store/yardCase/selectors';
import Button from 'components/inputs/Button';
import YardCaseStatus from 'gen/YardCaseStatus';
import YardCaseEditStatus from 'store/yardCase/YardCaseEditStatus';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useDispatchBind } from 'hooks';
import FinalizeYardCaseModal from './FinalizeYardCaseModal';
import { pushModal } from 'store/modal/actions';
import ConfirmModal from 'components/modal/Confirm';
import ErrorModal from 'components/modal/Error';
import Api from 'api';
import {
  setYardCaseEditStatus,
  setActiveYardCase,
} from 'store/yardCase/actions';
import YardCaseActionButton from './YardCaseActionButton';

export const RestoreYardCaseButtonText = 'Återställ och redigera uppdrag';
export const FinalizeYardCaseButtonText = 'Markera som hämtad';

const RestoreYardCaseButton: React.FC = () => {
  const yardCase = useSelector(selectActiveYardCase);
  const editStatus = useSelector(selectYardCaseEditStatus);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);
  const onSetEditStatus = useDispatchBind(setYardCaseEditStatus, dispatch);
  const onSetActiveYardCase = useDispatchBind(setActiveYardCase, dispatch);

  const handleRestoreYardCase = (eve: React.MouseEvent) => {
    eve.preventDefault();
    eve.stopPropagation();

    onPushModal(
      <ConfirmModal
        onConfirm={async () => {
          try {
            onSetEditStatus(YardCaseEditStatus.blockedSaving);
            const updatedCase = await Api.reopenYardCase(yardCase!.id);
            onSetActiveYardCase(updatedCase);
            onSetEditStatus(YardCaseEditStatus.saved);
          } catch (err) {
            onPushModal(
              <ErrorModal exception={err} reloadOnConfirm>
                Ett fel inträffade när uppdragets status skulle återställas
              </ErrorModal>
            );
          }
        }}
      >
        Är du säker på att du vill öppna uppdraget igen?
        {yardCase!.finalInvoiceBatchID !== undefined &&
          yardCase!.finalInvoiceBatchID !== null &&
          ' Om du återställer uppdraget kommer slutfakturan skrivas ut igen i nästa fakturaunderlag.'}
      </ConfirmModal>
    );
  };

  if (!yardCase) {
    return null;
  }

  const disableButton = editStatus !== YardCaseEditStatus.saved;

  switch (yardCase.status) {
    case YardCaseStatus.Returned:
    case YardCaseStatus.Scrapped:
    case YardCaseStatus.Cancelled:
      return (
        <YardCaseActionButton
          disabled={disableButton}
          onClick={handleRestoreYardCase}
        >
          <FontAwesomeIcon icon={faEdit} /> {RestoreYardCaseButtonText}
        </YardCaseActionButton>
      );

    default:
      return null;
  }
};

export default RestoreYardCaseButton;
