import React from 'react';
import styled, { css } from 'styled-components';
import YardCaseCommentAttachmentModel from 'gen/YardCaseCommentAttachmentModel';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Api from 'api';

const Image = styled.img`
  max-width: 100%;
  max-height: 30vh;
`;

const Wrap = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: ${Sizes.padding.small}px;
  margin-right: ${Sizes.padding.small}px;
  border-radius: ${Sizes.radius.inputElement}px;
  border: 1px solid ${Colors.background.mainDark};
  overflow: hidden;
`;

const Label = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: ${Sizes.padding.small}px;
  padding-left: ${Sizes.padding.large}px;
  cursor: pointer;
  transition: opacity 0.3s;
  background: linear-gradient(0deg, #000a 0%, #0000 60px);
  color: white;

  &:hover {
    opacity: 1;
  }
`;

interface RemoveButtonProps {
  deletable: boolean;
}
const RemoveButton = styled.button<RemoveButtonProps>`
  opacity: 0;
  position: absolute;
  top: ${Sizes.padding.small}px;
  right: -100px;
  border: none;
  background: #2a2a2aaa;
  color: #fff;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  transition: right 0.1s, transform 0.1s, font-size 0.1s;

  &:hover {
    transform: scale(1.1);
    font-size: 1.1em;
  }

  &:focus {
    outline: none;
    border: 2px solid ${Colors.focus};
  }

  ${({ deletable }) =>
    deletable &&
    css`
      right: ${Sizes.padding.small}px;
      opacity: 1;
    `}
`;

interface Props {
  attachment: YardCaseCommentAttachmentModel;
  onClick(): void;

  deletable: boolean;
  onDeleteClick(): void;
}

const ImagePreview: React.SFC<Props> = ({
  attachment,
  onClick,
  deletable,
  onDeleteClick,
}) => {
  return (
    <Wrap onClick={onClick}>
      <Image src={Api.getAttachmentURL(attachment.id)} />
      <Label>{attachment.displayName}</Label>
      <RemoveButton
        deletable={deletable}
        onClick={(eve) => {
          eve.preventDefault();
          eve.stopPropagation();
          onDeleteClick();
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </RemoveButton>
    </Wrap>
  );
};

export default ImagePreview;
