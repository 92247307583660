import IFormSectionField from 'components/Form/types/IFormSectionField';
import YardCaseModel from 'gen/YardCaseModel';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectBasicData } from 'store/basicData/selectors';
import getFormSections from './formSections';

const useFormFieldsByName = (
  onVTRClick?: () => void,
  onSearchAssistClick?: () => void
) => {
  const basicData = useSelector(selectBasicData);

  return useMemo(() => {
    if (!basicData) return new Map<keyof YardCaseModel, IFormSectionField>();

    const formSections = getFormSections(
      basicData,
      onVTRClick ?? (() => {}),
      onSearchAssistClick ?? (() => {})
    );

    return new Map(
      formSections
        .map((section) =>
          section.fields.map(
            (field) =>
              [
                field.name as keyof YardCaseModel,
                field as IFormSectionField,
              ] as const
          )
        )
        .flat(1)
    );
  }, [basicData, onVTRClick, onSearchAssistClick]);
};

export default useFormFieldsByName;
