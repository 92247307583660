import styled, { css } from 'styled-components';
import Sizes from 'constants/Sizes';

export const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${Sizes.padding.large}px;
  padding-right: ${Sizes.padding.small}px;
  border-bottom: 1px solid;
  border-color: inherit;

  &:last-child {
    border: none;
  }
`;

interface HeadProps {
  clickable?: boolean;
}

export const Head = styled(Cell)<HeadProps>`
  border-color: inherit;
  font-weight: bold;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  border-right: 1px solid;
  border-color: inherit;
  overflow: hidden;

  &:last-child {
    border-right: none;
  }
`;

export const Rowclickers = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

export const RowClicker = styled.div`
  background: transparent;
  cursor: pointer;
  &:hover {
    background: #0000001a;
  }
`;

export const TableActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: ${Sizes.padding.small}px;
`;

export const TableActionsFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${Sizes.padding.small}px;
  flex: 1;
  gap: ${Sizes.padding.small}px;
`;

export const TableActionsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: larger;
  font-weight: bold;
  padding-left: ${Sizes.padding.small}px;
  flex: 1;
`;
