import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Arrow, Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import styled, { keyframes } from 'styled-components';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const popoverArrowClassname = 'popover-arrow';

const MyContent = styled(Content)`
  padding: 10px 20px;
  background-color: ${Colors.background.main};
  box-shadow: 0 5px 80px rgba(0, 0, 0, 0.2), 0 1px 20px rgba(0, 0, 0, 0.1);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  border-radius: ${Sizes.radius.element}px;

  &[data-state='open'][data-side='top'] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state='open'][data-side='right'] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state='open'][data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state='open'][data-side='left'] {
    animation-name: ${slideRightAndFade};
  }
`;

const MyArrow = styled(Arrow)`
  fill: ${Colors.background.mainLight};
`;

interface Props {
  content: ReactNode;
  side?: 'right' | 'top' | 'bottom' | 'left' | undefined;
  sideOffset?: number;
  align?: 'start' | 'end' | 'center' | undefined;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?(open: boolean): void;
  modal?: boolean;
  autoFocusOnOpen?: boolean;

  className?: string;
}

const Popover: FC<Props> = ({
  side,
  sideOffset,
  align,
  defaultOpen,
  open,
  onOpenChange,
  modal,
  children,
  content,
  autoFocusOnOpen = true,
  className,
}) => (
  <Root
    defaultOpen={defaultOpen}
    open={open}
    onOpenChange={onOpenChange}
    modal={modal}
  >
    <Trigger asChild>{children}</Trigger>
    <Portal>
      <MyContent
        onOpenAutoFocus={
          autoFocusOnOpen ? undefined : (event: Event) => event.preventDefault()
        }
        className={className}
        side={side}
        sideOffset={sideOffset || 5}
        align={align}
      >
        {content}
        <MyArrow className={popoverArrowClassname} />
      </MyContent>
    </Portal>
  </Root>
);

export default Popover;
