import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectModalStack } from 'store/modal/selectors';

const Modal: React.SFC = () => {
  const modalStack = useSelector(makeSelectModalStack);

  if (modalStack.length > 0) {
    return <div>{modalStack[modalStack.length - 1]}</div>;
  } else {
    return null;
  }
};

export default Modal;
