import YardCaseModel from 'gen/YardCaseModel';
import { ImmutableReducer, ActionReturns } from 'utils/utilityTypes';
import * as YardCaseActions from './actions';
import YardCaseActionTypes from './ActionTypes';
import YardCaseEditStatus from './YardCaseEditStatus';

export interface YardCaseState {
  activeYardCase?: YardCaseModel;
  editStatus: YardCaseEditStatus;
}

const initialState: YardCaseState = {
  activeYardCase: undefined,
  editStatus: YardCaseEditStatus.blockedSaving,
};

type actions = ActionReturns<typeof YardCaseActions>;

const yardCaseReducer: ImmutableReducer<YardCaseState, actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case YardCaseActionTypes.SetActiveYardCase:
      return { ...state, activeYardCase: action.yardCase };

    case YardCaseActionTypes.ClearActiveYardCase:
      return { ...state, activeYardCase: undefined };

    case YardCaseActionTypes.SetYardCaseEditStatus:
      return { ...state, editStatus: action.status };

    default:
      return state;
  }
};

export default yardCaseReducer;
