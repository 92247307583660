import { css } from 'styled-components';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';

const baseInputStyle = css`
  border: 1px solid;
  border-color: inherit;
  border-radius: ${Sizes.radius.inputElement}px;
  background-color: ${Colors.background.mainLight};
  padding: 5px;
  font-size: ${Sizes.font.input}px;
  color: inherit;
  height: 30px;

  &:focus {
    outline: none;
    border-color: ${Colors.focus};
    box-shadow: 0 0 0 1px ${Colors.focus};
  }
`;

export default baseInputStyle;
