import React from 'react';
import styled, { css } from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors, { translucent } from 'constants/Colors';

const Button = styled.button<{ activated: boolean }>`
  width: 35px;
  height: 35px;
  margin: ${Sizes.padding.nano}px 0;
  margin-right: ${Sizes.padding.small}px;
  padding: ${Sizes.padding.nano}px;

  border-radius: ${Sizes.radius.inputElement}px;
  border: 2px solid ${Colors.background.mainDark};
  background: ${Colors.background.main};
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${Colors.focus};
  }

  ${({ activated }) =>
    activated &&
    css`
      background-color: ${translucent(Colors.background.accent)};
      border-color: transparent;
    `}
`;

interface Props {
  onToggle(on: boolean): void;
  value: boolean;
}

const DateSpanToggle: React.SFC<Props> = ({ value, onToggle, children }) => (
  <Button
    type="button"
    title="Sök inom ett datumspann"
    activated={value}
    onClick={(eve) => {
      eve.preventDefault();
      eve.stopPropagation();
      onToggle(!value);
    }}
  >
    {children}
  </Button>
);

export default DateSpanToggle;
