import React, { PropsWithRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sizes from 'constants/Sizes';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Button from './Button';

const Icon = styled(FontAwesomeIcon)`
  margin-right: ${Sizes.padding.mini}px;
`;

interface Props {
  icon: IconProp;

  onClick?(eve: React.MouseEvent): void;
  disabled?: boolean;
  className?: string;
}

const IconButton: React.SFC<Props> = ({ children, icon, ...props }) => (
  <Button {...props}>
    <Icon icon={icon} />
    {children}
  </Button>
);

export default IconButton;
