import styled, { css } from 'styled-components';
import Colors from 'constants/Colors';

interface ListRowProps {
  clickable?: boolean;
  firstChildIsIcon?: boolean;
}
const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;
  min-width: 100px;

  border-bottom: 1px solid ${Colors.background.mainDark};

  & > *:first-child {
    margin-right: 10px;
  }

  &:last-child {
    border-bottom: none;
  }

  ${({ clickable }: ListRowProps) =>
    clickable &&
    css`
      cursor: pointer;
      &:hover {
        background: ${Colors.background.main};
      }
      :active {
        background: ${Colors.background.mainDark};
      }
    `};
`;

export default ListRow;
