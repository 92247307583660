import React from 'react';
import { useDispatch } from 'react-redux';
import DefaultModal from './Default';
import { popModal } from 'store/modal/actions';

interface Props {
  title?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm(): void;
  onCancel?(): void;
  disableConfirm?: boolean;
  disableCancel?: boolean;

  className?: string;
}

const ConfirmModal: React.SFC<Props> = ({
  title,
  confirmLabel = 'Ja',
  cancelLabel = 'Nej',
  onConfirm,
  onCancel,
  disableConfirm,
  disableCancel,
  children,
  className,
}) => {
  const dispatch = useDispatch();

  return (
    <DefaultModal
      title={title}
      buttons={[
        {
          label: confirmLabel,
          onClick: () => {
            onConfirm();
            dispatch(popModal());
          },
          disabled: disableConfirm,
        },
        {
          label: cancelLabel,
          onClick: () => {
            onCancel && onCancel();
            dispatch(popModal());
          },
          disabled: disableCancel,
        },
      ]}
      className={className}
    >
      {children}
    </DefaultModal>
  );
};

export default ConfirmModal;
