
enum YardCaseSubType {
    Other = 1,
    Confiscation = 2,
    LongMove = 3,
    TBL7 = 4,
    TechnicalExamination = 5,
    FireDamage = 6,
    Stolen = 7,
    Collision = 8,
    Reclaim = 9,
}

export default YardCaseSubType;