import React from 'react';
import KeyValueList from 'components/KeyValueList';
import { useSelector } from 'react-redux';
import { selectActiveYardCase } from 'store/yardCase/selectors';
import { toUserDateTimeString } from 'utils';
import {
  TabContent,
  TabHeader,
  EmptyMessage,
} from 'components/TabView/components';
import { makeSelectUserNames } from 'store/basicData/selectors';

const MetadataTab: React.SFC = () => {
  const yardCase = useSelector(selectActiveYardCase);
  const userNames = useSelector(makeSelectUserNames);

  const getUserName = (userID: number) =>
    (userNames && userNames[userID]) || '-';

  if (!yardCase) {
    return (
      <TabContent>
        <TabHeader>Information</TabHeader>
        <EmptyMessage>Uppdraget har inte sparats ännu</EmptyMessage>
      </TabContent>
    );
  }

  return (
    <TabContent>
      <TabHeader>Information</TabHeader>
      <KeyValueList
        keyValueList={[{ key: 'Uppdrags-id', value: yardCase.id }]}
      />
      <br />
      <KeyValueList
        keyValueList={[
          {
            key: 'Senast ändrad',
            value: toUserDateTimeString(new Date(yardCase.dateModified)),
          },
          {
            key: 'Senast ändrad av',
            value: getUserName(yardCase.modifiedByID),
          },
        ]}
      />
      <br />
      <KeyValueList
        keyValueList={[
          {
            key: 'Skapad',
            value: toUserDateTimeString(new Date(yardCase.dateCreated)),
          },
          {
            key: 'Skapad av',
            value: getUserName(yardCase.createdByID),
          },
        ]}
      />
    </TabContent>
  );
};

export default MetadataTab;
