import React, { useState } from 'react';
import ErrorModal from 'components/modal/Error';
import { useDispatch } from 'react-redux';
import { useDispatchBind } from 'hooks';
import { pushModal, popModal } from 'store/modal/actions';
import { ThenArg } from './useApiResponse';

export enum RequestStatus {
  Idle,
  Fetching,
  Fetched,
  Error,
}

export interface UseApiCallOptions {
  /** if you want to replace the currently visible modal with error modal if an error occurs */
  popModalBeforeError?: boolean;
  errorMessage?: string;
}

export interface ApiCall<Response, ApiFuncParams extends any[]> {
  run(
    ...extra: ApiFuncParams
  ): Promise<[response: Response | undefined, error: undefined | unknown]>;
  response?: Response;
  status: RequestStatus;
  setResponse: React.Dispatch<React.SetStateAction<Response | undefined>>;
  setStatus: React.Dispatch<React.SetStateAction<RequestStatus>>;
}

const useApiCall = <
  ApiFunc extends (...args: any[]) => Promise<any>,
  R extends ReturnType<ApiFunc>,
  Response extends ThenArg<R> = ThenArg<R>
>(
  apiFunc: ApiFunc,
  options?: UseApiCallOptions
): ApiCall<Response, Parameters<ApiFunc>> => {
  const [response, setResponse] = useState<Response>();
  const [status, setStatus] = useState(RequestStatus.Idle);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);
  const onPopModal = useDispatchBind(popModal, dispatch);

  const run = async (
    ...extra: Parameters<ApiFunc>
  ): Promise<[response: Response | undefined, error: undefined | unknown]> => {
    setStatus(RequestStatus.Fetching);
    try {
      const response = await apiFunc(...extra);
      setResponse(response);
      setStatus(RequestStatus.Fetched);

      return [response, undefined];
    } catch (err) {
      options && options.popModalBeforeError && onPopModal();
      setStatus(RequestStatus.Error);

      console.error('API error: ', err);

      onPushModal(
        <ErrorModal exception={err}>
          {(options && options.errorMessage) || 'Ett fel har inträffat'}
        </ErrorModal>
      );

      return [undefined, err];
    }
  };

  return { run, response, status, setResponse, setStatus };
};

export default useApiCall;
