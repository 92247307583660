import React from 'react';
import IFormSection from './types/IFormSection';
import FormSectionField from './FormSectionField';
import {
  FormSectionWrapper,
  SectionHeader,
  SectionHeaderRight,
  FormSectionContent,
} from './components';

interface OwnProps {
  formSection: IFormSection;
  id: string;

  /** Callback for when user starts modifying form state.
   * When a fieldDelay has passed, the value will be inputted to FinalForm */
  changeBegin?(): void;
}

const FormSection: React.FunctionComponent<OwnProps> = ({
  formSection,
  id,
  changeBegin,
}) => {
  return (
    <FormSectionWrapper id={id}>
      <SectionHeader>
        {formSection.title}
        <SectionHeaderRight>
          {formSection.headerFields &&
            formSection.headerFields.map(field => (
              <FormSectionField
                headerField
                key={field.name}
                formSectionField={field}
                changeBegin={changeBegin}
              />
            ))}
        </SectionHeaderRight>
      </SectionHeader>
      <FormSectionContent>
        {formSection.fields.map(field => (
          <FormSectionField
            key={field.name}
            formSectionField={field}
            changeBegin={changeBegin}
          />
        ))}
      </FormSectionContent>
    </FormSectionWrapper>
  );
};

export default FormSection;
