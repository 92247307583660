import React, { useMemo } from 'react';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import baseInputStyle from './baseStyle';
import Colors from 'constants/Colors';
import { FakeCheckBox } from './CheckBox';
import Popover from 'components/Popover';
import Sizes from 'constants/Sizes';

const MyPopover = styled(Popover)`
  padding: 0;
`;

const FakeSelect = styled.button`
  ${baseInputStyle}
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const SelectedValue = styled.div`
  flex: 1;
  min-height: 1.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const OverflowHiddenWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 50vh;
  max-width: 50vw;
  border-radius: ${Sizes.radius.element}px;
`;

export const Items = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Item = styled.button<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${Sizes.padding.mini}px ${Sizes.padding.large}px;
  margin: 0;
  gap: 10px;

  border: none;
  background-color: ${Colors.background.mainLight};
  color: ${Colors.foreground.main};
  font: inherit;
  text-align: start;
  white-space: nowrap;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.background.mainDark};
  }
  &:last-child {
    border-bottom-left-radius: ${Sizes.radius.element}px;
    border-bottom-right-radius: ${Sizes.radius.element}px;
  }

  &:first-child {
    border-top-left-radius: ${Sizes.radius.element}px;
    border-top-right-radius: ${Sizes.radius.element}px;
  }

  &:hover {
    background: ${Colors.focus};
    color: #fff;
  }
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px ${Colors.focus};
  }
`;

export interface Option<V> {
  value: V;
  label: string;
}

interface Props<V> {
  options: Option<V>[];
  onChange(selection: Set<V>): void;
  value: Set<V>;
  renderSelected?(item: Option<V> | undefined): ReactNode | null;
  className?: string;
}

const MultiSelect = <V,>({
  options,
  onChange,
  value,
  renderSelected,
  className,
}: Props<V>) => {
  const [open, setOpen] = useState(false);

  const allSelected = useMemo(() => {
    return !options.find((opt) => !value.has(opt.value));
  }, [options, value]);

  return (
    <MyPopover
      open={open}
      onOpenChange={(o) => setOpen(o)}
      content={
        <OverflowHiddenWrap>
          <Items>
            <Item
              onClick={() => {
                if (allSelected) {
                  onChange(new Set());
                } else {
                  onChange(new Set(options.map((opt) => opt.value)));
                }
              }}
            >
              <FakeCheckBox
                checked={allSelected}
                partiallyChecked={!!value.size}
              />
              <b>Välj alla</b>
            </Item>

            {options.map((option, i) => (
              <Item
                key={i}
                onClick={(eve) => {
                  eve.preventDefault();
                  const newValue = new Set(value);
                  if (value.has(option.value)) {
                    newValue.delete(option.value);
                  } else {
                    newValue.add(option.value);
                  }
                  onChange(newValue);
                }}
              >
                <FakeCheckBox checked={value.has(option.value)} />
                {option.label}
              </Item>
            ))}
          </Items>
        </OverflowHiddenWrap>
      }
    >
      <FakeSelect
        className={className}
        onClick={(eve) => {
          eve.preventDefault();
          setOpen((o) => !o);
        }}
      >
        {renderSelected ? (
          Array.from(value).map((v) =>
            renderSelected(options.find((o) => o.value === v))
          )
        ) : (
          <SelectedValue>
            {Array.from(value)
              .map((v) => options.find((o) => o.value === v)?.label)
              .join(', ')}
          </SelectedValue>
        )}
        <FontAwesomeIcon icon={faAngleDown} />
      </FakeSelect>
    </MyPopover>
  );
};

export default MultiSelect;
