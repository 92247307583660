import { DeepReadonly, DeepReadonlyAble } from './utilityTypes';

export const copyJSON = <T extends DeepReadonlyAble>(
  value: DeepReadonly<T> | T
) => JSON.parse(JSON.stringify(value)) as T;

export const gotoLogin = () => {
  window.location.assign('/Identity/Account/Login');
};

export const toUserDateString = (date: Date) =>
  `${date.getFullYear().toString().padStart(4, '0')}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

export const toUserDateTimeString = (date: Date) =>
  `${toUserDateString(date)} ${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()!.split(';').shift();
  }
};
