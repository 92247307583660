import React, { useState } from 'react';
import CommentHeaderType from 'gen/CommentHeaderType';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectActiveYardCase,
  selectYardCaseEditStatus,
} from 'store/yardCase/selectors';
import { makeSelectTemplates } from 'store/basicData/selectors';
import { useDispatchBind } from 'hooks';
import {
  setYardCaseEditStatus,
  setActiveYardCase,
} from 'store/yardCase/actions';
import { pushModal } from 'store/modal/actions';
import YardCaseEditStatus from 'store/yardCase/YardCaseEditStatus';
import { copyJSON } from 'utils';
import YardCaseCommentModel from 'gen/YardCaseCommentModel';
import Api from 'api';
import ErrorModal from 'components/modal/Error';
import YardCaseModel from 'gen/YardCaseModel';

const useCommentInputState = () => {
  const [commentText, setCommentText] = useState('');
  const [commentType, setCommentType] = useState(CommentHeaderType.Other);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [typePickerVisible, setTypePickerVisible] = useState(false);
  const yardCase = useSelector(selectActiveYardCase);
  const editStatus = useSelector(selectYardCaseEditStatus);
  const templates = useSelector(makeSelectTemplates);

  const dispatch = useDispatch();
  const onSetEditStatus = useDispatchBind(setYardCaseEditStatus, dispatch);
  const onSetActiveYardCase = useDispatchBind(setActiveYardCase, dispatch);
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const handleSendClick = async () => {
    if (!templates || !yardCase) {
      return;
    }

    const statusBeforeSaving = editStatus;
    onSetEditStatus(YardCaseEditStatus.blockedSaving);

    const newComment = copyJSON<YardCaseCommentModel>(
      templates.yardCaseCommentModel
    );
    newComment.text = commentText;
    newComment.commentHeaderType = commentType;

    try {
      const addedComment = await Api.addComment(yardCase.id, newComment);

      await uploadAttachments(addedComment);
    } catch (err) {
      onPushModal(
        <ErrorModal exception={err} reloadOnConfirm>
          Ett fel inträffade när kommentaren skulle skapas.
        </ErrorModal>
      );
    } finally {
      onSetEditStatus(statusBeforeSaving);
    }
  };

  const uploadAttachments = async (uploadedComment: YardCaseCommentModel) => {
    const updatedYardCase = copyJSON<YardCaseModel>(yardCase!);
    updatedYardCase.comments.push(uploadedComment);

    try {
      for (let attachment of attachments) {
        uploadedComment.attachments.push(
          await Api.addAttachment(attachment, yardCase!.id, uploadedComment.id)
        );
      }

      setAttachments([]);
      setCommentText('');
      onSetActiveYardCase(updatedYardCase);
    } catch (err) {
      onPushModal(
        <ErrorModal exception={err} reloadOnConfirm>
          Ett fel inträffade när bilagorna skulle läggas in på kommentaren.
        </ErrorModal>
      );
    }
  };

  const handleFileDrop = async (files: FileList) => {
    const newAttachments = [...attachments];
    for (let i = 0; i < files.length; i++) {
      const item = files.item(i);
      if (item) {
        newAttachments.push(item);
      }
    }
    setAttachments(newAttachments);
  };

  return {
    commentText,
    setCommentText,
    commentType,
    setCommentType,
    attachments,
    setAttachments,
    typePickerVisible,
    setTypePickerVisible,
    yardCase,
    editStatus,

    handleFileDrop,
    handleSendClick,
  };
};

export default useCommentInputState;
