import React, { useState } from 'react';
import DefaultModal from './Default';
import Slider from 'components/Slider';
import styled from 'styled-components';

const MyModal = styled(DefaultModal)`
  height: 85vh;
  width: 85vh;
`;

interface Props {
  images: { imageUrl: string; title: string }[];
  startIndex?: number;
}

const ImageViewerModal: React.SFC<Props> = ({ images, startIndex = 0 }) => {
  const [index, setIndex] = useState(startIndex);

  return (
    <MyModal title={images[index].title}>
      <Slider
        imageHeight={500}
        imageWidth={500}
        slides={images.map((i) => i.imageUrl)}
        index={index}
        onIndexChange={setIndex}
      />
    </MyModal>
  );
};

export default ImageViewerModal;
