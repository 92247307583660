import React, { useState } from 'react';
import ConfirmModal from 'components/modal/Confirm';
import Input from 'components/inputs/Input';
import { toFormDateString } from 'components/Form/utils';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';
import Api from 'api';
import { selectActiveYardCase } from 'store/yardCase/selectors';
import { useDispatchBind } from 'hooks';
import { pushModal } from 'store/modal/actions';
import { useDispatch, useSelector } from 'react-redux';
import ErrorModal from 'components/modal/Error';
import {
  setActiveYardCase,
  setYardCaseEditStatus,
} from 'store/yardCase/actions';
import YardCaseEditStatus from 'store/yardCase/YardCaseEditStatus';
import { copyJSON } from 'utils';

const MyModal = styled(ConfirmModal)`
  align-items: flex-start;
`;

export const InputRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${Sizes.padding.large}px 0;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: ${Sizes.padding.medium}px;
  border-color: ${Colors.background.mainDark};
`;

interface Props {
  scrapYardCase: boolean;
}

const FinalizeYardCaseModal: React.FC<Props> = ({ scrapYardCase }) => {
  const yardCase = useSelector(selectActiveYardCase);

  const [outDate, setOutDate] = useState(new Date());
  const [claimedBy, setClaimedBy] = useState(yardCase!.claimedBy || '');

  const dispatch = useDispatch();
  const onSetEditStatus = useDispatchBind(setYardCaseEditStatus, dispatch);
  const setYardCase = useDispatchBind(setActiveYardCase, dispatch);
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const handleFinalizeYardCase = async () => {
    onSetEditStatus(YardCaseEditStatus.blockedSaving);
    try {
      const yardCaseCopy = copyJSON(yardCase!);
      yardCaseCopy.activeTo = outDate;
      yardCaseCopy.claimedBy = claimedBy;

      const updatedCase = scrapYardCase
        ? await Api.scrapYardCase(yardCaseCopy)
        : await Api.completeYardCase(yardCaseCopy);
      setYardCase(updatedCase);
      onSetEditStatus(YardCaseEditStatus.saved);
    } catch (err) {
      onPushModal(
        <ErrorModal exception={err}>
          Ett fel inträffade när uppdraget skulle markeras som hämtat.
        </ErrorModal>
      );
      onSetEditStatus(YardCaseEditStatus.formInvalid);
    }
  };

  return (
    <MyModal
      title={`Vill du markera uppdraget som ${
        scrapYardCase ? 'skrotad' : 'hämtad'
      } och generera utskrift?`}
      confirmLabel={`Markera som ${scrapYardCase ? 'skrotad' : 'hämtad'}`}
      cancelLabel="Avbryt"
      onConfirm={handleFinalizeYardCase}
      disableConfirm={isNaN(outDate.getTime()) || !claimedBy.trim()}
    >
      Se till att följande fält är korrekta:
      <InputRowsContainer>
        <InputWrap>
          UT-datum
          <Input
            type="date"
            value={toFormDateString(outDate)}
            onChange={(eve) => setOutDate(new Date(eve.target.value))}
          />
        </InputWrap>
        <InputWrap>
          {scrapYardCase ? 'Skrotad av' : 'Hämtad av'}
          <Input
            type="text"
            value={claimedBy}
            onChange={(eve) => setClaimedBy(eve.target.value)}
          />
        </InputWrap>
      </InputRowsContainer>
    </MyModal>
  );
};

export default FinalizeYardCaseModal;
