import React from 'react';
import PillItems, { Item } from 'components/PillItems';
import FileIcon from './FileIcon';

interface Props {
  files: File[];
  onDeleteFile(index: number): void;
}

const PendingAttachments: React.SFC<Props> = ({ files, onDeleteFile }) => {
  const getFileEnding = (fileName: string) => {
    const file = fileName.split('.');
    return file[file.length - 1];
  };

  const attachments = files.map(
    (file): Item => ({
      name: file.name,
      icon: <FileIcon fileEnding={getFileEnding(file.name)} />,
    })
  );

  return (
    <PillItems
      items={attachments}
      onDeleteItem={(_file, index) => onDeleteFile(index)}
    />
  );
};

export default PendingAttachments;
