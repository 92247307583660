import React from 'react';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';

const Darken = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  background-color: ${Colors.background.main};
  border-radius: ${Sizes.radius.inputElement}px;
  box-shadow: ${Colors.boxShadow.modal};
  overflow: hidden;
  min-width: 20vw;
  max-height: calc(100vh - ${Sizes.padding.small}px);
  max-width: calc(100vw - ${Sizes.padding.small}px);
`;

interface Props {
  className?: string;
}

const ModalWrapper: React.SFC<Props> = ({ children, className }) => (
  <Darken>
    <Wrapper className={className}>{children}</Wrapper>
  </Darken>
);

export default ModalWrapper;
