import React from 'react';
import styled from 'styled-components';
import YardCaseCommentAttachmentModel from 'gen/YardCaseCommentAttachmentModel';
import Sizes from 'constants/Sizes';
import ImagePreview from './ImagePreview';
import { useDispatch } from 'react-redux';
import { useDispatchBind } from 'hooks';
import { pushModal } from 'store/modal/actions';
import ImageViewerModal from 'components/modal/ImageViewer';
import Api from 'api';

const Wrapper = styled.div`
  margin-top: ${Sizes.padding.small}px;
  margin-bottom: ${-Sizes.padding.small}px;
  margin-right: ${-Sizes.padding.small}px;
`;

interface Props {
  attachments: YardCaseCommentAttachmentModel[];
  editMode: boolean;
  onDeleteAttachment(attachment: YardCaseCommentAttachmentModel): void;
}

const isImage = (fileEnding: string) => {
  switch (fileEnding.replace('.', '').toLowerCase()) {
    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'gif':
      return true;
    default:
      return false;
  }
};

const ImagePreviews: React.SFC<Props> = ({
  attachments,
  editMode,
  onDeleteAttachment,
}) => {
  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const imageAttachments = attachments.filter((a) => isImage(a.fileEnding));

  const handleImageClick = (index: number) => {
    onPushModal(
      <ImageViewerModal
        images={imageAttachments.map((a) => ({
          imageUrl: Api.getAttachmentURL(a.id),
          title: a.displayName,
        }))}
        startIndex={index}
      />
    );
  };

  if (imageAttachments.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {imageAttachments.map((attachment, index) => (
        <ImagePreview
          onClick={() => handleImageClick(index)}
          deletable={editMode}
          onDeleteClick={() => onDeleteAttachment(attachment)}
          key={attachment.id}
          attachment={attachment}
        />
      ))}
    </Wrapper>
  );
};

export default ImagePreviews;
