import FieldType from './types/FieldType';
import IFormSection from './types/IFormSection';
import IFormSectionField, {
  ISelectOption,
  ISelectField,
  ISelectOptionsCreator,
} from './types/IFormSectionField';
import { IFormValue, IFormValues } from './types/IFormValues';
import { toUserDateString, toUserDateTimeString } from 'utils';

/** Returns optimal delay in milliseconds for given field type */
export const getFieldDelay = (fieldType: FieldType): number => {
  switch (fieldType) {
    case FieldType.SELECT:
    case FieldType.SELECT_NO_DEFAULT:
    case FieldType.BUTTON:
    case FieldType.TOGGLE:
      return 0;
    default:
      return 900;
  }
};

export const showFieldLabel = (fieldType: FieldType): boolean => {
  switch (fieldType) {
    case FieldType.BUTTON:
    case FieldType.CHECKBOX:
      return false;
    default:
      return true;
  }
};

export const asDateOrUndefined = (value: any): Date | undefined => {
  if (typeof value === 'string') {
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }
  return undefined;
};

export const asStringOrUndefined = (value: any) => {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return String(value);
  }
  return undefined;
};

export const asNumberOrUndefined = (value: any) => {
  if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'string') {
    const converted = Number(value);
    if (!isNaN(converted)) {
      return converted;
    }
  }
  return undefined;
};

export const toFormDateString = (date?: Date) => {
  if (date) {
    return `${date
      .getFullYear()
      .toString()
      .padStart(4, '0')}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  }
  return '';
};

export const toFormDateTimeString = (date?: Date) => {
  if (date) {
    return `${date
      .getFullYear()
      .toString()
      .padStart(4, '0')}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}T${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  }
  return '';
};

export const toReadonlyFormSections = (
  formsections: IFormSection[],
  formValues: IFormValues
) => {
  const getFormatter = (
    fieldType: FieldType,
    selectOptions?: ISelectOption[] | ISelectOptionsCreator<string>
  ): ((value: IFormValue) => IFormValue) => {
    switch (fieldType) {
      case FieldType.DATE:
        return v => toUserDateString(new Date((v as string) || ''));
      case FieldType.DATETIME:
        return v => toUserDateTimeString(new Date((v as string) || ''));

      case FieldType.TOGGLE:
      case FieldType.CHECKBOX:
        return v => (v ? 'Ja' : 'Nej');

      case FieldType.SELECT:
      case FieldType.SELECT_NO_DEFAULT:
      case FieldType.MULTI_SELECT:
        let options: ISelectOption[] = [];

        if (Array.isArray(selectOptions)) {
          options = selectOptions;
        } else if (selectOptions) {
          options = selectOptions(formValues);
        }

        return v => {
          const opt = options.find(o => o.value == v);
          return (opt && opt.label) || v;
        };

      default:
        return v => v;
    }
  };

  return formsections.map(
    (section): IFormSection => ({
      ...section,
      headerFields: undefined,
      fields: section.fields.map(
        (field): IFormSectionField => ({
          ...field,
          required: false,
          type: FieldType.READONLY,
          format: v =>
            getFormatter(
              field.type,
              (field as ISelectField).selectOptions
            )(field.format ? field.format(v) : v),
        })
      ),
    })
  );
};
