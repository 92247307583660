import React, { useState } from 'react';
import SortedTable from 'components/Table/SortedTable';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeSelectTemplates,
  makeSelectYardLocations,
  makeSelectUserNames,
} from 'store/basicData/selectors';
import Colors from 'constants/Colors';
import { gotoLogin, toUserDateTimeString, copyJSON } from 'utils';
import IconButton from 'components/inputs/IconButton';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import { pushModal } from 'store/modal/actions';
import { useDispatchBind } from 'hooks';
import YardLocationModel from 'gen/YardLocationModel';
import EditYardLocationModal from './EditYardLocationModal';
import { TableActionsFilters } from 'components/Table/components';
import Toggle from 'components/inputs/Toggle';

const TableActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: ${Sizes.padding.small}px;
`;

const YardLocationsTab: React.FC = () => {
  const yardLocations = useSelector(makeSelectYardLocations);
  const users = useSelector(makeSelectUserNames);
  const templates = useSelector(makeSelectTemplates);
  const [showRemoved, setShowRemoved] = useState(false);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const handleAddYardLocationClick = () => {
    const newYardLocation = copyJSON<YardLocationModel>(
      templates!.yardLocationModel
    );
    onPushModal(
      <EditYardLocationModal
        location={newYardLocation}
        title="Lägg till ny gård"
      />
    );
  };

  const handleEditYardLocationClick = (yardLocation: YardLocationModel) => {
    const newCustomer = copyJSON<YardLocationModel>(yardLocation);
    onPushModal(
      <EditYardLocationModal location={newCustomer} title="Redigera gård" />
    );
  };

  if (!yardLocations) {
    gotoLogin();
    return null;
  }

  return (
    <>
      <TableActions>
        <TableActionsFilters>
          <Toggle value={showRemoved} onToggle={setShowRemoved} /> Visa
          borttagna
        </TableActionsFilters>

        <IconButton icon={faPlusCircle} onClick={handleAddYardLocationClick}>
          Lägg till
        </IconButton>
      </TableActions>
      <SortedTable
        columnSettings={[
          { header: 'Namn', attribute: 'name' },
          {
            header: 'Senast ändrad',
            attribute: 'dateModified',
            formatter: (date) => toUserDateTimeString(new Date(date as string)),
          },
          {
            header: 'Senast ändrad av',
            attribute: 'modifiedByID',
            formatter: (userId: number) => (users && users[userId]) || '-',
          },
          ...(showRemoved
            ? [
                {
                  header: 'Borttagen',
                  attribute: 'isRemoved',
                  formatter: (v: boolean) => (v ? 'Ja' : 'Nej'),
                } as const,
              ]
            : []),
        ]}
        rows={yardLocations.filter((y) => showRemoved || !y.isRemoved)}
        borderColor={Colors.background.mainDark}
        onRowClick={handleEditYardLocationClick}
      />
    </>
  );
};

export default YardLocationsTab;
