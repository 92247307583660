import React from 'react';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Button from 'components/inputs/Button';

const ModalButton = styled(Button)`
  margin: ${Sizes.padding.small}px;
`;

export default ModalButton;
