import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';
import Button from 'components/inputs/Button';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';

const TextArea = styled(AutoSizedTextArea)`
  border: none;
  background-color: ${Colors.background.main};
  border-radius: ${Sizes.radius.inputElement}px;
  border: 1px solid ${Colors.background.mainDark};
  margin-bottom: ${Sizes.padding.medium}px;
  margin-top: ${Sizes.padding.medium}px;

  &:focus {
    outline: none;
    border: 1px solid ${Colors.focus};
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;

  & > *:first-child {
    margin-right: ${Sizes.padding.small}px;
  }

  & > * {
    flex: 1;
  }
`;

interface Props {
  initialValue: string;
  onSave(newValues: string): void;
  onAbort(): void;
}

const EditComment: React.SFC<Props> = ({ initialValue, onSave, onAbort }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <>
      <TextArea
        value={value}
        onChange={eve => setValue((eve.target as any).value)}
      />
      <ButtonsWrap>
        <Button smaller onClick={() => onSave(value)}>
          Spara
        </Button>
        <Button smaller onClick={onAbort}>
          Avbryt
        </Button>
      </ButtonsWrap>
    </>
  );
};

export default EditComment;
