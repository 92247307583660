import React from 'react';
import CustomerModel from 'gen/CustomerModel';
import YardLocationModel from 'gen/YardLocationModel';
import { DeepReadonly } from './utilityTypes';
import { ColumnSetting } from 'components/Table';
import YardCasePreviewModel from 'gen/YardCasePreviewModel';
import BasicDataModel from 'gen/BasicDataModel';
import YardCaseStatusText from 'components/pages/EditYardCasePage/YardCaseStatusText';
import { SortedTableColumnSetting } from 'components/Table/SortedTable';

const yardCasePreviewColumnSettings = (
  customers: DeepReadonly<CustomerModel[]> | null,
  locations: DeepReadonly<YardLocationModel[]> | null,
  statuses: BasicDataModel['yardCaseStatuses'] | null
): SortedTableColumnSetting<YardCasePreviewModel>[] => [
  {
    attribute: 'id',
    header: 'Id',
  },
  {
    header: 'Status',
    cellRenderer: ({ row }) => (
      <YardCaseStatusText yardCase={row} yardCaseStatuses={statuses} />
    ),
    sorter: statuses
      ? (a, b) => statuses[a.status].localeCompare(statuses[b.status])
      : undefined,
  },
  {
    attribute: 'regNumber',
    header: 'Regnummer',
  },
  {
    attribute: 'dateModified',
    header: 'Senast ändrad',
    formatter: (v) => new Date(v as string).toLocaleString(),
  },
  {
    attribute: 'customerID',
    header: 'Kund',
    formatter: (v) => {
      const customer =
        customers && customers.find((customer) => customer.id === v);
      return customer ? customer.name : '-';
    },
  },
  {
    attribute: 'yardLocationID',
    header: 'Gård',
    formatter: (v) => {
      const location =
        locations && locations.find((location) => location.id === v);
      return location ? location.name : '-';
    },
  },
];

export default yardCasePreviewColumnSettings;
