import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { NavLink } from '../NavMenu/components';
import Sizes from 'constants/Sizes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${Sizes.padding.large}px;
`;

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);
    this.setState({
      isAuthenticated,
      userName: user && user.name,
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = {
        pathname: `${ApplicationPaths.LogOut}`,
        state: { local: true },
      };
      return this.authenticatedView(userName, profilePath, logoutPath);
    }
  }

  authenticatedView(userName, profilePath, logoutPath) {
    return (
      <Wrapper>
        <NavLink noRouteMatch to={profilePath}>
          Profil
        </NavLink>
        <NavLink noRouteMatch to={logoutPath}>
          Logga ut
        </NavLink>
      </Wrapper>
    );
  }

  anonymousView(registerPath, loginPath) {
    return (
      <Wrapper>
        <NavLink noRouteMatch to={registerPath}>
          Skapa konto
        </NavLink>
        <NavLink noRouteMatch to={loginPath}>
          Logga in
        </NavLink>
      </Wrapper>
    );
  }
}
