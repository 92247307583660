import React from 'react';
import styled from 'styled-components';
import Colors from 'constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const Link = styled.a`
  & > *:last-child {
    opacity: 0;
    color: ${Colors.foreground.mainLight};
    margin-left: 10px;
  }

  &:hover > *:last-child {
    opacity: 1;
  }
`;

interface Props {
  href: string;
}

const DownloadLink: React.SFC<Props> = ({ href, children }) => (
  <Link download href={href}>
    {children}
    <FontAwesomeIcon icon={faDownload} />
  </Link>
);

export default DownloadLink;
