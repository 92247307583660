import React from 'react';
import DefaultModal from 'components/modal/Default';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

const MyModal = styled(DefaultModal)`
  align-items: flex-start;
`;

const MyList = styled.ul`
  list-style: none;
  padding: 0;

  & > li:before {
    display: inline-block;
    content: '';
    width: 1em;
    height: 0.7em;
    margin-right: ${Sizes.padding.mini}px;
    background: url('/images/angleRight.svg') center/contain no-repeat;
  }
`;

const SearchInfoModal: React.SFC = () => (
  <MyModal title="Vad kan jag söka på?">
    Du kan söka efter uppdrag med hjälp av:
    <MyList>
      <li>Regnummer</li>
      <li>Referens</li>
      <li>Ägarens namn</li>
      <li>Ägarens Person-/Org.nummer</li>
      <li>Uppdragets id</li>
      <li>Assist uppdrags-id</li>
    </MyList>
    <p>
      Du kan också klicka på <FontAwesomeIcon icon={faCalendar} /> för att
      avgränsa sökningen inom ett visst datumspann då uppdraget skapades.
    </p>
  </MyModal>
);

export default SearchInfoModal;
