import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 30px;
`;

const ClickWrap = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;

  border: none;
  background: transparent !important;
  color: inherit;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

const Box = styled.div<{ disableStyle?: boolean; checked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  margin-right: ${Sizes.padding.mini}px;

  border: 2px solid ${Colors.background.accent};
  border-radius: ${Sizes.radius.inputElement}px;
  color: ${Colors.background.accent};
  font-size: 11px;

  ${({ checked, disableStyle }) =>
    checked
      ? disableStyle
        ? css`
            border-color: ${Colors.background.mainDark};
            color: ${Colors.foreground.mainDark};
            background-color: ${Colors.background.mainDark};
          `
        : css`
            background-color: ${Colors.background.accent};
            color: ${Colors.foreground.accent};
          `
      : disableStyle &&
        css`
          border-color: ${Colors.background.mainDark};
          color: ${Colors.background.mainDark};
        `}
`;

export const FakeCheckBox: FC<{
  checked?: boolean;
  partiallyChecked?: boolean;
  disabled?: boolean;
  className?: string;
}> = ({ checked, partiallyChecked, disabled, className }) => (
  <Box
    className={'checkbox-input ' + className}
    disableStyle={disabled}
    checked={!!checked}
  >
    {checked ? (
      <FontAwesomeIcon icon={faCheck} />
    ) : partiallyChecked ? (
      <FontAwesomeIcon icon={faMinus} />
    ) : null}
  </Box>
);

interface Props {
  value?: boolean;
  onToggle(on: boolean): void;
  disabled?: boolean;
}

const CheckBox: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ value, onToggle, disabled, children }, ref) => (
    <Wrapper ref={ref}>
      <ClickWrap
        onClick={(eve) => {
          eve.preventDefault();
          eve.stopPropagation();
          if (!disabled) {
            onToggle(!value);
          }
        }}
        disabled={disabled}
      >
        <FakeCheckBox checked={value} />
        {children}
      </ClickWrap>
    </Wrapper>
  )
);

export default CheckBox;
