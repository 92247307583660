import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAlt,
  faEnvelope,
  faSignInAlt,
  faSignOutAlt,
  faFileInvoiceDollar,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import CommentHeaderType from 'gen/CommentHeaderType';
import styled from 'styled-components';
import Colors from 'constants/Colors';

const Icon = styled(FontAwesomeIcon)`
  color: ${({ color }: { color: string }) => color};
`;

interface Props {
  headerType: CommentHeaderType;
}

const CommentHeaderTypeIcon: React.SFC<Props> = ({ headerType }) => {
  switch (headerType) {
    case CommentHeaderType.InitialImages:
      return <Icon icon={faSignInAlt} color="#0e2" />;

    case CommentHeaderType.ImagesOnReturn:
      return <Icon icon={faSignOutAlt} color="#e90" />;

    case CommentHeaderType.OrderEmail:
      return <Icon icon={faEnvelope} color="#0bf" />;

    case CommentHeaderType.FinalInvoice:
      return <Icon icon={faFileInvoiceDollar} color="#000" />;

    case CommentHeaderType.CancelCase:
      return <Icon icon={faTrashAlt} color="#f00" />;

    case CommentHeaderType.Other:
      return <Icon icon={faCommentAlt} color={Colors.background.accent} />;
  }
};

export default CommentHeaderTypeIcon;
