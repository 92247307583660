import styled from 'styled-components';
import baseInputStyle from './baseStyle';

interface TextAreaProps {
  rows?: number;
}

const TextArea = styled.textarea`
  ${baseInputStyle}
  height: ${({ rows }: TextAreaProps) =>
    rows !== undefined ? `calc(12px + ${rows * 1.5}em)` : '100%'}; 
`;

export default TextArea;
