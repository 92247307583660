import React, { FunctionComponent, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/inputs/Button';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';
import useApiCall, { RequestStatus } from 'hooks/useApiCall';
import Api from 'api';
import Rolling from 'components/LoadingSpinner/Rolling';
import { toUserDateString } from 'utils';
import YardCaseStatus from 'gen/YardCaseStatus';
import { toFormDateTimeString } from 'components/Form/utils';
import Input from 'components/inputs/Input';
import MultiSelect from 'components/inputs/MultiSelect';
import { useSelector } from 'react-redux';
import { selectBasicData } from 'store/basicData/selectors';

const Wrapper = styled.div`
  padding: ${Sizes.padding.large}px;
`;

const ExportItem = styled.div`
  margin-bottom: ${Sizes.padding.large}px;
`;

const ExportItemTitle = styled.div`
  margin-bottom: ${Sizes.padding.large}px;

  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid ${Colors.background.mainDark};
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${Sizes.padding.small}px;
  gap: ${Sizes.padding.medium}px;
  border-color: ${Colors.background.mainDark};
`;

const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  border-color: inherit;
`;

const MyMultiSelect = styled(MultiSelect)`
  min-width: 300px;
  max-width: 300px;
` as typeof MultiSelect;

const Spinner = styled(Rolling)`
  width: 35px;
  height: 35px;
  margin-left: 20px;
  margin-right: 10px;
  color: ${Colors.background.accent};
`;

const ExportDataTab: FunctionComponent = () => {
  const basicData = useSelector(selectBasicData);
  const downloadInventoryExcelCall = useApiCall(Api.getInventoryExcel);

  const now = new Date();
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const [fromDate, setFromDate] = useState(toFormDateTimeString(startOfMonth));
  const [toDate, setToDate] = useState(toFormDateTimeString(endOfMonth));
  const [statuses, setStatuses] = useState<Set<YardCaseStatus>>(
    new Set([YardCaseStatus.Active])
  );
  const [customerIds, setCustomerIds] = useState<Set<number>>(new Set([]));

  const handleDownloadClick = async () => {
    if (!fromDate || !toDate) return;

    const [excelBlob, error] = await downloadInventoryExcelCall.run(
      new Date(fromDate),
      new Date(toDate),
      Array.from(statuses),
      Array.from(customerIds)
    );

    if (excelBlob && !error) {
      const a = document.createElement('a');
      const excelBlobURL = window.URL.createObjectURL(excelBlob);
      const now = new Date();

      a.href = excelBlobURL;
      a.download = `BRK-export-${toUserDateString(now)}.xlsx`;
      a.click();

      window.URL.revokeObjectURL(excelBlobURL);
    }
  };

  const downloading =
    downloadInventoryExcelCall.status === RequestStatus.Fetching;
  const invalidInputs = !fromDate || !toDate;

  return (
    <Wrapper>
      <ExportItem>
        <ExportItemTitle>
          Exportera uppställda fordon (Excel-fil)
        </ExportItemTitle>

        {downloading ? (
          <>
            <Spinner /> Skapar export...
          </>
        ) : (
          <>
            <Horizontal>
              <InputLabel>
                Från
                <Input
                  type="datetime-local"
                  value={fromDate}
                  onChange={(eve) => setFromDate(eve.target.value)}
                />
              </InputLabel>

              <InputLabel>
                Till
                <Input
                  type="datetime-local"
                  value={toDate}
                  onChange={(eve) => setToDate(eve.target.value)}
                />
              </InputLabel>

              <InputLabel>
                Statusar
                <MyMultiSelect
                  options={
                    basicData
                      ? [
                          {
                            label:
                              basicData.yardCaseStatuses[YardCaseStatus.Active],
                            value: YardCaseStatus.Active,
                          },
                          {
                            label:
                              basicData.yardCaseStatuses[
                                YardCaseStatus.Returned
                              ],
                            value: YardCaseStatus.Returned,
                          },
                          {
                            label:
                              basicData.yardCaseStatuses[
                                YardCaseStatus.Scrapped
                              ],
                            value: YardCaseStatus.Scrapped,
                          },
                        ]
                      : []
                  }
                  value={statuses}
                  onChange={setStatuses}
                />
              </InputLabel>

              <InputLabel>
                Kunder
                <MyMultiSelect
                  options={
                    basicData?.customers
                      .filter((customer) => !customer.isRemoved)
                      .map((customer) => ({
                        label: customer.name,
                        value: customer.id,
                      })) ?? []
                  }
                  value={customerIds}
                  onChange={setCustomerIds}
                />
              </InputLabel>
            </Horizontal>

            <Button
              onClick={handleDownloadClick}
              disabled={downloading || invalidInputs}
            >
              Skapa export
            </Button>
          </>
        )}
      </ExportItem>
    </Wrapper>
  );
};

export default ExportDataTab;
