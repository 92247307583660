
const UserSettings = {
    adminRoleID: 1,
    adminRole: "Admin",
    userRoleID: 2,
    userRole: "User",
    viewErrorsRoleID: 3,
    autoSyncUserID: 99
}

export default UserSettings;