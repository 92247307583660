import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDispatchBind } from 'hooks';
import SortedTable from 'components/Table/SortedTable';
import Colors from 'constants/Colors';
import { toUserDateTimeString, gotoLogin, toUserDateString } from 'utils';
import { pushModal } from 'store/modal/actions';
import { makeSelectUserNames } from 'store/basicData/selectors';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/inputs/IconButton';
import useApiResponse, { RequestStatus } from 'hooks/useApiResponse';
import Api from 'api';
import LoadingSpinner from 'components/LoadingSpinner';
import ErrorModal from 'components/modal/Error';
import DownloadLink from './DownloadLink';
import { ResponseError } from 'api/util';
import DefaultModal from 'components/modal/Default';

const TableAction = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${Sizes.padding.small}px;
  border-color: ${Colors.background.mainDark};
`;

const FinalInvoiceTab: React.SFC = () => {
  const users = useSelector(makeSelectUserNames);
  const finalInvoices = useApiResponse(Api.getFinalInvoiceBatches, {
    errorMessage: 'Ett fel inträffade när fakturaunderlagen skulle hämtas',
  });
  const [creatingBatch, setCreatingBatch] = useState(false);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);

  const handleCreateFinalInvoiceBatch = async () => {
    setCreatingBatch(true);
    try {
      const response = await Api.createFinalInvoiceBatch();
      finalInvoices.update([...finalInvoices.response!, response]);
    } catch (err) {
      // http No content
      if (err instanceof ResponseError && err.response.status === 204) {
        onPushModal(
          <DefaultModal title="Det finns inga uppdrag att skapa fakturaunderlag för">
            Det finns inga uppdrag att skapa fakturaunderlag för. Se till att
            alla uppdrag du vill ha med i fakturaunderlaget har status 'hämtad'
            eller kontrollera om de finns med i föregående fakturaunderlag.
          </DefaultModal>
        );
      } else {
        onPushModal(
          <ErrorModal exception={err}>
            Ett fel inträffade när fakturaunderlagen skulle skapas.
          </ErrorModal>
        );
      }
    }
    setCreatingBatch(false);
  };

  if (!users) {
    gotoLogin();
    return null;
  }

  if (
    finalInvoices.status === RequestStatus.Fetching ||
    !finalInvoices.response
  ) {
    return <LoadingSpinner>Laddar...</LoadingSpinner>;
  }

  if (creatingBatch) {
    return <LoadingSpinner>Skapar fakturaunderlag...</LoadingSpinner>;
  }

  return (
    <>
      <TableAction>
        <IconButton icon={faPlus} onClick={handleCreateFinalInvoiceBatch}>
          Skapa fakturaunderlag
        </IconButton>
      </TableAction>
      <SortedTable
        borderColor={Colors.background.mainDark}
        columnSettings={[
          {
            header: 'Fakturaunderlag',
            cellRenderer: ({ row }) => (
              <DownloadLink
                href={Api.finalInvoiceBatchDownloadLink(row.id)}
              >{`Slutfaktura-underlag_${toUserDateString(
                new Date(row.dateCreated)
              )}_ID-${row.id}.zip`}</DownloadLink>
            ),
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
          },
          {
            attribute: 'dateCreated',
            header: 'Skapad',
            formatter: (dateStr) => toUserDateTimeString(new Date(dateStr)),
          },
          {
            attribute: 'createdByID',
            header: 'Skapad av',
            formatter: (userId: number) => users[userId],
          },
        ]}
        defaultSortingColumn={1}
        sortDescendingByDefault={true}
        rows={finalInvoices.response}
        rowsPerPage={20}
      />
    </>
  );
};

export default FinalInvoiceTab;
