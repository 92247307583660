import React, { useState } from 'react';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';

const DropZoneHoverClass = 'dropzone-hover';

const Wrapper = styled.div`
  position: relative;

  /* hack to create an area around children that detects entering file drags */
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &.${DropZoneHoverClass}::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    content: 'Släpp dina filer här för att ladda upp dem';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${Sizes.radius.element}px;
    border: 2px dashed ${Colors.background.mainDark};
    background-color: ${Colors.background.main};
  }
`;

interface Props {
  className?: string;
  onFilesDropped?(files: FileList): void;

  /** The type strings allowed in DataTransfer.type (generated by onDragEnter).
   * Defaults to ['Files'] */
  allowedTypes?: string[];
}

const DropZone: React.SFC<Props> = ({
  className,
  children,
  onFilesDropped,
  allowedTypes = ['Files'],
}) => {
  const [fileHover, setFileHover] = useState(false);

  return (
    <Wrapper
      onDragEnter={(eve) => {
        eve.preventDefault();
        eve.stopPropagation();

        if (
          eve.dataTransfer.types.findIndex(
            (type) =>
              allowedTypes.findIndex((allowedType) => allowedType === type) !==
              -1
          ) !== -1
        ) {
          setFileHover(true);
        }
      }}
      onDragLeave={(eve) => {
        eve.preventDefault();
        eve.stopPropagation();
        setFileHover(false);
      }}
      onDragOver={(eve) => {
        eve.preventDefault();
        eve.stopPropagation();
      }}
      onDrop={(eve) => {
        eve.preventDefault();
        eve.stopPropagation();

        if (!fileHover) {
          return;
        }
        setFileHover(false);
        onFilesDropped && onFilesDropped(eve.dataTransfer.files);

        /*
        const reader = new FileReader();
        reader.onload = e => console.log('Reader onLoad', e);
        reader.readAsText(eve.dataTransfer.files[0]);

        const formData = new FormData();
        formData.append('file', eve.dataTransfer.files[0]);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/yard');
        xhr.send(formData); */
      }}
      className={fileHover ? `${className} ${DropZoneHoverClass}` : className}
    >
      {children}
    </Wrapper>
  );
};

export default DropZone;
