import React from 'react';
import styled from 'styled-components';
import Rolling from './Rolling';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${Sizes.padding.large}px;
`;

const Spinner = styled(Rolling)`
  width: 100px;
  height: 100px;
  color: ${({ color }: { color: string }) => color};
  margin: ${Sizes.padding.medium}px;
`;

const LoadingSpinner: React.SFC<{ className?: string }> = ({
  children,
  className,
}) => (
  <Wrapper className={className}>
    <Spinner color={Colors.background.accent} />
    {children}
  </Wrapper>
);

export default LoadingSpinner;
