import React, { useState, useRef, useEffect } from 'react';
import IFormSectionField from './types/IFormSectionField';
import { FieldWrapper, ErrorText, ReadonlyField } from './components';
import Input from 'components/inputs/Input';
import TextArea from 'components/inputs/TextArea';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import Toggle from 'components/inputs/Toggle';
import FieldType from './types/FieldType';
import FieldLabel from './FieldLabel';
import { useField } from 'react-final-form';
import {
  getFieldDelay,
  asDateOrUndefined,
  asStringOrUndefined,
  showFieldLabel,
  toFormDateString,
  toFormDateTimeString,
} from './utils';
import { IFormValues, IFormValue, IFormValidation } from './types/IFormValues';
import { IFieldMetaData } from './FormChangeHandler';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import CheckBox from 'components/inputs/CheckBox';

interface OwnProps {
  formSectionField: IFormSectionField;
  /** Callback for when user starts modifying form state.
   * When a fieldDelay has passed, the value will be inputted to FinalForm */
  changeBegin?(): void;
  headerField?: boolean;
}

const FormSectionField: React.FunctionComponent<OwnProps> = ({
  formSectionField,
  changeBegin,
  headerField,
}) => {
  const field = useField<IFormValue>(formSectionField.name, {
    validate:
      formSectionField.validation &&
      ((value, allValues, fieldState) =>
        formSectionField.validation!(
          value,
          allValues as IFormValues,
          fieldState!.name
        )),
  });
  const fieldMetaData: IFieldMetaData | undefined = field.meta.data;

  const [ownValue, setOwnValue] = useState<IFormValue>(field.input.value);
  const inputDelayTimeout = useRef<number>(-1);
  const validation: IFormValidation =
    (fieldMetaData && fieldMetaData.validation) || {};

  const formattedValue = formSectionField.format
    ? formSectionField.format(ownValue)
    : ownValue;

  useEffect(() => {
    return () => {
      clearTimeout(inputDelayTimeout.current);
    };
  }, []);

  useEffect(() => {
    const inputValue = field.input.value;
    const parseFunc = formSectionField.parse;

    if (field.input.value !== formattedValue) {
      setOwnValue(parseFunc ? parseFunc(inputValue) : inputValue);
    }
  }, [field.input.value]);

  // provides user input delay, and puts the event on the event queue (allows for some rendering before handling inputted value)
  const handleChange = (value: IFormValue) => {
    if (inputDelayTimeout.current !== -1) {
      clearTimeout(inputDelayTimeout.current);
    } else {
      changeBegin && changeBegin();
    }

    const parseFunc = formSectionField.parse;
    const parsedValue = parseFunc ? parseFunc(value) : value;
    setOwnValue(parsedValue);

    inputDelayTimeout.current = window.setTimeout(() => {
      field.input.onChange(parsedValue);
      inputDelayTimeout.current = -1;
    }, getFieldDelay(formSectionField.type));
  };

  const getInputComponent = () => {
    switch (formSectionField.type) {
      case FieldType.TEXT:
        return (
          <Input
            name={formSectionField.name}
            onChange={(eve) => handleChange(eve.target.value)}
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            disabled={validation.disabled}
            value={asStringOrUndefined(formattedValue)}
            type="text"
          />
        );
      case FieldType.NUMBER:
        return (
          <Input
            name={formSectionField.name}
            onChange={(eve) => handleChange(Number(eve.target.value))}
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            disabled={validation.disabled}
            value={asStringOrUndefined(formattedValue)}
            type="number"
          />
        );
      case FieldType.DATE: {
        const date = asDateOrUndefined(formattedValue);
        return (
          <Input
            name={formSectionField.name}
            onChange={(eve) => handleChange(eve.target.value)}
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            disabled={validation.disabled}
            value={(date && toFormDateString(date)) || ''}
            type="date"
          />
        );
      }

      case FieldType.DATETIME: {
        const date = asDateOrUndefined(formattedValue);
        return (
          <Input
            name={formSectionField.name}
            onChange={(eve) => handleChange(eve.target.value)}
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            disabled={validation.disabled}
            value={date && toFormDateTimeString(date)}
            type="datetime-local"
          />
        );
      }

      case FieldType.TEXTAREA:
        return (
          <TextArea
            name={formSectionField.name}
            onChange={(eve) => handleChange(eve.target.value)}
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            disabled={validation.disabled}
            value={asStringOrUndefined(formattedValue)}
            rows={formSectionField.textAreaRows}
          />
        );

      case FieldType.SELECT:
        return (
          <Select
            name={formSectionField.name}
            onChange={(eve) =>
              handleChange(
                validation.selectOptions &&
                  validation.selectOptions[Number(eve.target.value)].value
              )
            }
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            disabled={validation.disabled}
            value={
              validation.selectOptions &&
              validation.selectOptions.findIndex(
                (opt) => opt.value == formattedValue
              )
            }
          >
            {validation.selectOptions &&
              validation.selectOptions.map((option, index) => (
                <option key={index} value={index} disabled={option.disabled}>
                  {option.label}
                </option>
              ))}
          </Select>
        );

      case FieldType.SELECT_NO_DEFAULT:
        return (
          <Select
            name={formSectionField.name}
            onChange={(eve) => {
              const option =
                validation.selectOptions &&
                validation.selectOptions[Number(eve.target.value)];
              handleChange(option ? option.value : undefined);
            }}
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            disabled={validation.disabled}
            value={
              validation.selectOptions
                ? validation.selectOptions.findIndex(
                    (opt) => opt.value == formattedValue
                  )
                : -1
            }
          >
            <option value={'-1'}></option>
            {validation.selectOptions &&
              validation.selectOptions.map((option, index) => (
                <option key={index} value={index} disabled={option.disabled}>
                  {option.label}
                </option>
              ))}
          </Select>
        );

      case FieldType.EDITABLE_SELECT:
      case FieldType.MULTI_SELECT:
        return <b>Unimplemented field type</b>;

      case FieldType.TOGGLE:
        return (
          <Toggle
            value={formattedValue === true}
            onToggle={(value) => handleChange(String(value))}
            disabled={validation.disabled}
          />
        );

      case FieldType.CHECKBOX:
        return (
          <CheckBox
            value={formattedValue === true}
            onToggle={(value) => handleChange(value)}
            disabled={validation.disabled}
          >
            {formSectionField.label}
          </CheckBox>
        );

      case FieldType.BUTTON:
        return (
          <Button
            smaller
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            disabled={validation.disabled}
            onClick={(eve) => {
              eve.preventDefault();
              formSectionField.onClick(eve);
            }}
          >
            {formSectionField.label}
          </Button>
        );

      case FieldType.READONLY:
        return <ReadonlyField>{formattedValue}</ReadonlyField>;
    }
  };

  if (!headerField) {
    return (
      <FieldWrapper
        greyout={validation.disabled}
        error={!!validation.invalid}
        center={formSectionField.type === FieldType.CHECKBOX}
      >
        <FieldLabel asterisk={validation.required}>
          {showFieldLabel(formSectionField.type) ? formSectionField.label : ' '}
        </FieldLabel>
        <Tooltip
          overlay={<ErrorText>{validation.invalid}</ErrorText>}
          trigger={'hover'}
          placement="right"
          {...(!validation.invalid ? { visible: false } : {})} // because visible={undefined} isn't the same as Not setting the 'visible' prop
        >
          {getInputComponent()}
        </Tooltip>
        {validation.warning && <ErrorText>{validation.warning}</ErrorText>}
      </FieldWrapper>
    );
  } else {
    return getInputComponent();
  }
};

export default FormSectionField;
