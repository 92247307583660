import React from 'react';
import styled, { css } from 'styled-components';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;
`;

interface StylingProps {
  value: boolean;
  disabled?: boolean;
}

const grooveHeight = Sizes.font.input + 10;
const ToggleGroove = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: ${({ value: on }: StylingProps) =>
    on ? `flex-end` : `flex-start`};
  border: 2px solid
    ${({ disabled, value }) =>
      disabled
        ? Colors.background.mainDark
        : !value
        ? Colors.background.mainDark
        : Colors.background.accent};
  height: ${grooveHeight}px;
  width: ${grooveHeight * 1.7}px;
  border-radius: ${grooveHeight / 2}px;
  background-color: ${Colors.background.main};
  ${({ value, disabled }) =>
    value &&
    css`
      background-color: ${disabled
        ? Colors.background.mainDark
        : Colors.background.accent};
    `}
`;

const overlap = -2;
const size = grooveHeight + overlap * 2;
const ToggleHandle = styled.div`
  background-color: ${Colors.background.mainLight};
  height: ${size}px;
  width: ${size}px;
  border-radius: ${size / 2}px;
  margin: ${-2 - overlap}px 0px;
  box-shadow: ${Colors.boxShadow.button};

  ${({ disabled }: StylingProps) =>
    disabled &&
    css`
      border: 1px solid ${Colors.background.mainDark};
      box-shadow: none;
    `}
`;

interface Props {
  onToggle(on: boolean): void;
  value: boolean;
  disabled?: boolean;
}

const Toggle: React.FunctionComponent<Props> = ({
  value,
  disabled,
  onToggle,
}) => (
  <Wrapper
    onClick={!disabled ? () => onToggle(!value) : undefined}
    className="toggle-input"
  >
    <ToggleGroove value={value} disabled={disabled}>
      <ToggleHandle value={value} disabled={disabled} />
    </ToggleGroove>
  </Wrapper>
);

export default Toggle;
