import { useEffect } from 'react';
import useApiCall, { UseApiCallOptions, RequestStatus } from './useApiCall';

export { RequestStatus };

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer V>
  ? V
  : T;

interface useApiResponseOptions extends UseApiCallOptions {}

type ApiResponseStatus =
  | RequestStatus.Error
  | RequestStatus.Fetched
  | RequestStatus.Fetching;

export interface ApiResponse<Response> {
  response: Response | undefined;
  status: ApiResponseStatus;
  setStatus(status: ApiResponseStatus): void;
  update: (newResponse?: Response) => Promise<void>;
}

const useApiResponse = <
  ApiFunc extends (...args: any[]) => Promise<any>,
  R extends ReturnType<ApiFunc>,
  Response extends ThenArg<R> = ThenArg<R>
>(
  apiFunc: ApiFunc,
  options: useApiResponseOptions,
  ...apiParams: Parameters<ApiFunc>
): ApiResponse<Response> => {
  const apiCall = useApiCall(apiFunc, options);

  useEffect(() => {
    apiCall.run(...apiParams);
  }, []);

  const update = async (newResponse?: Response) => {
    if (newResponse !== undefined) {
      apiCall.setResponse(newResponse);
    } else {
      await apiCall.run(...apiParams);
    }
  };

  return {
    response: apiCall.response,
    status:
      apiCall.status === RequestStatus.Idle
        ? RequestStatus.Fetching
        : apiCall.status,
    setStatus: apiCall.setStatus,
    update,
  };
};

export default useApiResponse;
