import React from 'react';
import styled, { css } from 'styled-components';
import Colors from 'constants/Colors';

interface ValueProps {
  fullSize: boolean;
}

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  font-size: 0.9em;
  overflow: hidden;

  .full-size > div {
    width: 100%;
  }
`;

const FiftyP = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  white-space: nowrap;
`;

const Value = styled(FiftyP)`
  ${(props: ValueProps) =>
    props.fullSize &&
    css`
      white-space: pre-wrap;
      width: 100%;
    `};
`;

const Key = styled(FiftyP)``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.error-row {
    background: ${Colors.bad};
    font-weight: bold;
  }
`;

export interface KeyValueRow {
  key?: React.ReactChild;
  value?: React.ReactChild;
  error?: boolean;
}

const safeTitle = (value?: React.ReactChild): string | undefined => {
  if (
    value == undefined ||
    (typeof value !== 'string' && typeof value !== 'number')
  ) {
    return undefined;
  }
  return String(value);
};

interface propTypes {
  keyValueList: KeyValueRow[];
  className?: string;
  useColon?: boolean;
}

const KeyValueList: React.SFC<propTypes> = ({
  keyValueList,
  className,
  useColon = false,
}) => {
  return (
    <Container className={className}>
      {keyValueList.map(
        ({ key, value, error = false }: KeyValueRow, index: number) => (
          <Row key={index} className={error ? 'error-row' : ''}>
            {key && (
              <Key title={safeTitle(key)}>
                <b>
                  {key}
                  {useColon ? ':' : ''}
                </b>
              </Key>
            )}
            <Value title={safeTitle(value)} fullSize={!key}>
              {value}
            </Value>
          </Row>
        )
      )}
    </Container>
  );
};

export default KeyValueList;
