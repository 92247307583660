import { DeepReadonly } from 'utils/utilityTypes';
import IFormSection from 'components/Form/types/IFormSection';
import FormSectionId from 'components/Form/types/FormSectionId';
import BasicDataModel from 'gen/BasicDataModel';
import YardCaseModel from 'gen/YardCaseModel';
import FieldType from 'components/Form/types/FieldType';
import YardCaseType from 'gen/YardCaseType';
import YardCaseSubType from 'gen/YardCaseSubType';
import YardCaseStatus from 'gen/YardCaseStatus';
import { asDateOrUndefined } from 'components/Form/utils';
import CarKeyStatus from 'gen/CarKeyStatus';
import { IDisabledEvaluator } from 'components/Form/types/IFormSectionField';
import { toUserDateString } from 'utils';

type FieldNames = keyof YardCaseModel | 'button1' | 'button2';

const VTRSearchDisabled: IDisabledEvaluator<FieldNames> = (
  _,
  { regNumber, chassisNumber, assistAssignmentID }
) => !(regNumber || chassisNumber || assistAssignmentID);

const getFormSections = (
  basicData: DeepReadonly<BasicDataModel>,
  onVTRClick: () => void,
  onSearchAssistClick: () => void
): IFormSection<FieldNames>[] => [
  vehicleInfoFormSection(basicData, onVTRClick, onSearchAssistClick),
  statusFormSection(basicData, onVTRClick, onSearchAssistClick),
  ownerFormSection(basicData, onVTRClick, onSearchAssistClick),
  assignmentFormSection(basicData, onVTRClick, onSearchAssistClick),
];

const statusFormSection = (
  basicData: DeepReadonly<BasicDataModel>,
  onVTRClick: () => void,
  onSearchAssistClick: () => void
): IFormSection<FieldNames> => {
  return {
    title: 'Info',
    id: FormSectionId.STATUS,
    headerFields: [
      {
        label: 'Sök Assist',
        type: FieldType.BUTTON,
        name: 'button2',
        onClick: onSearchAssistClick,
        disabled: VTRSearchDisabled,
      },
      {
        label: 'Sök VTR',
        type: FieldType.BUTTON,
        name: 'button1',
        onClick: onVTRClick,
        disabled: VTRSearchDisabled,
      },
    ],
    fields: [
      {
        label: 'Kund',
        name: 'customerID',
        type: FieldType.SELECT_NO_DEFAULT,
        required: true,
        selectOptions: ({ customerID }) =>
          basicData.customers
            .filter(
              (customer) => !customer.isRemoved || customer.id === customerID
            )
            .map((customer) => ({
              label: customer.name,
              value: customer.id,
            })),
      },
      {
        label: 'Typ',
        name: 'yardCaseType',
        type: FieldType.SELECT_NO_DEFAULT,
        required: true,
        selectOptions: ({ customerID }) => {
          const customer = basicData.customers.find(
            (customer) => customer.id === customerID
          );

          return customer
            ? customer.enabledTypes.map((et) => ({
                label: basicData.yardCaseTypes[et.yardCaseType],
                value: et.yardCaseType,
              }))
            : [];
        },
      },
      {
        label: 'Undertyp',
        name: 'yardCaseSubType',
        type: FieldType.SELECT_NO_DEFAULT,
        required: (_v, values) => !(values.yardCaseType === YardCaseType.Other),
        disabled: (_v, values) =>
          values.yardCaseType === YardCaseType.Other ||
          values.yardCaseType === undefined,
        selectOptions: ({ customerID }) => {
          const customer = basicData.customers.find(
            (customer) => customer.id === customerID
          );

          return customer
            ? customer.enabledSubTypes.map((et) => ({
                label: basicData.yardCaseSubTypes[et.yardCaseSubType],
                value: et.yardCaseSubType,
              }))
            : [];
        },
      },
      {
        label: 'Typ, beskrivning',
        name: 'typeDescription',
        type: FieldType.TEXT,
        required: (value, values) =>
          values.yardCaseSubType === YardCaseSubType.Other ||
          values.yardCaseType === YardCaseType.Other
            ? true
            : false,
      },
      {
        label: 'Status',
        name: 'status',
        type: FieldType.SELECT,
        selectOptions: [
          {
            label: basicData.yardCaseStatuses[YardCaseStatus.Active],
            value: YardCaseStatus.Active,
          },
          {
            label: basicData.yardCaseStatuses[YardCaseStatus.Returned],
            value: YardCaseStatus.Returned,
            disabled: true,
          },
          {
            label: basicData.yardCaseStatuses[YardCaseStatus.Scrapped],
            value: YardCaseStatus.Scrapped,
            disabled: true,
          },
          {
            label: basicData.yardCaseStatuses[YardCaseStatus.Cancelled],
            value: YardCaseStatus.Cancelled,
            disabled: true,
          },
        ],
      },
      {
        label: 'Assist uppdrags-ID',
        name: 'assistAssignmentID',
        type: FieldType.TEXT,
        parse: (value) =>
          value == '' ? undefined : Number(String(value).replace(/\D/g, '')),
      },
      { label: 'Radionummer', name: 'radioNr', type: FieldType.TEXT },
      {
        label: 'Skade-/Ref.nummer',
        name: 'externalReference',
        required: (_, { customerID, yardCaseType }) => {
          const customer = basicData.customers.find(
            (customer) => customer.id === customerID
          );
          const customerEnabledType =
            customer &&
            customer.enabledTypes.find(
              (type) => type.yardCaseType === yardCaseType
            );
          return customerEnabledType && customerEnabledType.requireReference;
        },
        type: FieldType.TEXT,
      },
      {
        label: 'Assist kommentar',
        name: 'assistComment',
        type: FieldType.TEXTAREA,
      },
      {
        label: 'Assist koncept',
        name: 'assistConcept',
        type: FieldType.TEXT,
      },
    ],
  };
};

const assignmentFormSection = (
  basicData: DeepReadonly<BasicDataModel>,
  onVTRClick: () => void,
  onSearchAssistClick: () => void
): IFormSection<FieldNames> => {
  return {
    title: 'Ärende',
    id: FormSectionId.ASSIGNMENT,
    fields: [
      {
        label: 'Plats',
        name: 'yardLocationID',
        type: FieldType.SELECT_NO_DEFAULT,
        required: true,
        selectOptions: ({ yardLocationID }) =>
          basicData.yardLocations
            .filter(
              (location) =>
                !location.isRemoved || location.id === yardLocationID
            )
            .map((location) => ({
              label: location.name,
              value: location.id,
            })),
      },
      {
        label: 'IN-datum',
        name: 'activeFrom',
        required: true,
        type: FieldType.DATE,
      },
      {
        label: 'UT-datum',
        name: 'activeTo',
        type: FieldType.READONLY,
        format: (v) => (v ? toUserDateString(new Date(String(v))) : '-'),
      },
      {
        label: 'Har nycklar',
        name: 'carKeyStatus',
        type: FieldType.SELECT_NO_DEFAULT,
        required: true,
        selectOptions: [
          {
            label: basicData.carKeyStatuses[CarKeyStatus.Unknown],
            value: CarKeyStatus.Unknown,
          },
          {
            label: basicData.carKeyStatuses[CarKeyStatus.HasKeys],
            value: CarKeyStatus.HasKeys,
          },
          {
            label: basicData.carKeyStatuses[CarKeyStatus.NoKeys],
            value: CarKeyStatus.NoKeys,
          },
        ],
      },
      {
        label: 'Hämtad av / Skrotad av',
        name: 'claimedBy',
        type: FieldType.TEXT,
      },
      {
        label: 'Värderad',
        name: 'valueAsserted',
        type: FieldType.CHECKBOX,
        disabled: (_, values) =>
          values.id === basicData.templates.yardCaseModel.id,
      },
    ],
  };
};

const vehicleInfoFormSection = (
  basicData: DeepReadonly<BasicDataModel>,
  onVTRClick: () => void,
  onSearchAssistClick: () => void
): IFormSection<FieldNames> => {
  return {
    title: 'Objekt',
    id: FormSectionId.VEHICLE_INFO,
    fields: [
      {
        label: 'Regnummer',
        name: 'regNumber',
        type: FieldType.TEXT,
        required: (_v, values) => !values.internalIdentifier,
        parse: (val) => (typeof val === 'string' ? val.toUpperCase() : val),
      },
      {
        label: 'Märkning',
        name: 'internalIdentifier',
        type: FieldType.TEXT,
        required: (_v, values) => !values.regNumber,
      },
      {
        label: 'Chassisnummer',
        name: 'chassisNumber',
        type: FieldType.TEXT,
      },
      {
        label: 'Modell',
        name: 'brandName',
        type: FieldType.TEXT,
      },
      {
        label: 'Fordonstyp',
        name: 'vehicleType',
        type: FieldType.TEXT,
        required: true,
      },
      {
        label: 'Bränsletyp',
        name: 'fuelType',
        type: FieldType.TEXT,
      },
      {
        label: 'Försäkringsbolag',
        name: 'insuranceCompany',
        type: FieldType.TEXT,
      },
      {
        label: 'Färg',
        name: 'color',
        type: FieldType.TEXT,
      },
      {
        label: 'Fram- och bakhjulsdrift',
        name: 'hasFrontAndRearAxleDrive',
        type: FieldType.CHECKBOX,
      },
      {
        label: 'Totalvikt (kg)',
        name: 'totalWeightKg',
        type: FieldType.NUMBER,
      },
      {
        label: 'Nationalitet',
        name: 'country',
        type: FieldType.TEXT,
      },
    ],
  };
};

const ownerFormSection = (
  basicData: DeepReadonly<BasicDataModel>,
  onVTRClick: () => void,
  onSearchAssistClick: () => void
): IFormSection<FieldNames> => {
  return {
    title: 'Ägare',
    id: FormSectionId.OWNER,
    fields: [
      {
        label: 'Ägare',
        name: 'ownerFullName',
        type: FieldType.TEXT,
      },
      {
        label: 'Person-/Org.nummer',
        name: 'ownerSsnOrOrgNr',
        type: FieldType.TEXT,
      },
      {
        label: 'Adress',
        name: 'ownerStreetAddress',
        type: FieldType.TEXT,
      },
      {
        label: 'Postkod',
        name: 'ownerZipCode',
        type: FieldType.TEXT,
      },
      {
        label: 'Stad',
        name: 'ownerCity',
        type: FieldType.TEXT,
      },
    ],
  };
};

export default getFormSections;
