import * as React from 'react';
import NavMenu from './NavMenu';
import Modal from './modal';

export default (props: { children?: React.ReactNode }) => (
  <React.Fragment>
    <Modal />
    <NavMenu />
    {props.children}
  </React.Fragment>
);
