export class ResponseError extends Error {
  response: Response;
  invalidContentType: boolean;
  responseJson?: any;

  constructor(
    response: Response,
    invalidContentType?: boolean,
    responseJson?: any
  ) {
    super(invalidContentType ? 'content-type not Json' : response.statusText);
    this.response = response;
    this.invalidContentType = !!invalidContentType;
    this.responseJson = responseJson;
  }
}

const checkAndJsonifyResponse = async (response: Response): Promise<any> => {
  if (!response.ok) {
    throw new ResponseError(response);
  }

  const contentType =
    response.headers.has('content-type') &&
    response.headers.get('content-type');

  if (!(contentType && contentType.includes('application/json'))) {
    throw new ResponseError(response, true);
  }

  const json = await response.json();
  return json;
};

export interface QueryParams {
  [variableName: string]: any;
}

export async function get<T>(uri: string, queryParams?: QueryParams) {
  const response = await fetch(
    uri + (queryParams ? '?' + buildQueryString(queryParams) : '')
  );

  const json: T = await checkAndJsonifyResponse(response);
  return json;
}

export async function post<T>(uri: string, body: any) {
  const response = await fetch(uri, {
    method: 'POST',

    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  const json: T = await checkAndJsonifyResponse(response);
  return json;
}

export async function postForm<T>(uri: string, body: FormData) {
  const response = await fetch(uri, {
    method: 'POST',
    body,
  });

  const json: T = await checkAndJsonifyResponse(response);
  return json;
}

const buildQueryString = (params: QueryParams) =>
  Object.keys(params)
    .filter(k => !!params[k])
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
