const Sizes = {
  font: {
    input: 13,
  },
  padding: {
    large: 20,
    medium: 15,
    small: 10,
    mini: 5,
    nano: 2,
  },
  radius: {
    card: 15,
    element: 10,
    inputElement: 5,
  },
  searchBoxHeight: 40,
  headerHeight: 50,
  mapPinSize: 50,
} as const;

export default Sizes;
