import React from 'react';
import styled from 'styled-components';
import PillItem from './PillItem';
import Sizes from 'constants/Sizes';

const Wrapper = styled.div`
  margin-top: ${Sizes.padding.small}px;
`;

export interface Item {
  name: string;
  url?: string;
  icon?: React.ReactChild;
  backgroundColor?: string;

  /** Override default handlers */
  onClick?(): void;
  onDelete?(): void;
}

interface Props {
  items: Item[];

  /** Default handlers to apply to all items if they have none applied */
  onDeleteItem?(item: Item, index: number): void;
  onItemClick?(item: Item, index: number): void;

  className?: string;
}

const PillItems: React.SFC<Props> = ({
  items,
  onDeleteItem,
  onItemClick,
  className,
}) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Wrapper className={className}>
      {items.map((item, index) => (
        <PillItem
          key={index}
          item={{
            ...item,
            onClick:
              item.onClick || (onItemClick && (() => onItemClick(item, index))),
            onDelete:
              item.onDelete ||
              (onDeleteItem && (() => onDeleteItem(item, index))),
          }}
        />
      ))}
    </Wrapper>
  );
};

export default PillItems;
