import { createSelector } from 'reselect';
import { ApplicationState } from '..';
import UserSettings from 'gen/UserSettings';

export const selectBasicData = (state: ApplicationState) => state.basicData;

export const makeSelectCurrentUser = createSelector(
  selectBasicData,
  (basicData) => basicData && basicData.user
);

export const makeSelectCurrentUserIsAdmin = createSelector(
  selectBasicData,
  (basicData) =>
    basicData &&
    !!basicData.user.roles.find((role) => role === UserSettings.adminRoleID)
);

export const makeSelectCustomers = createSelector(
  selectBasicData,
  (basicData) => basicData && basicData.customers
);

export const makeSelectYardLocations = createSelector(
  selectBasicData,
  (basicData) => basicData && basicData.yardLocations
);

export const makeSelectSettings = createSelector(
  selectBasicData,
  (basicData) => basicData && basicData.settings
);

export const makeSelectTemplates = createSelector(
  selectBasicData,
  (basicData) => basicData && basicData.templates
);

export const makeSelectUserNames = createSelector(
  selectBasicData,
  (basicData) => basicData && basicData.userNames
);

export const makeSelectYardCaseTypes = createSelector(
  selectBasicData,
  (basicData) => basicData && basicData.yardCaseTypes
);

export const makeSelectYardCaseSubTypes = createSelector(
  selectBasicData,
  (basicData) => basicData && basicData.yardCaseSubTypes
);

export const makeSelectYardCaseStatuses = createSelector(
  selectBasicData,
  (basicData) => basicData && basicData.yardCaseStatuses
);

export const makeSelectYardCaseTypesArray = createSelector(
  makeSelectYardCaseTypes,
  (yardCaseTypes) =>
    yardCaseTypes &&
    Object.keys(yardCaseTypes).map((id) => ({
      name: yardCaseTypes[id],
      value: Number(id),
    }))
);

export const makeSelectYardCaseSubTypesArray = createSelector(
  makeSelectYardCaseSubTypes,
  (yardCaseSubTypes) =>
    yardCaseSubTypes &&
    Object.keys(yardCaseSubTypes).map((id) => ({
      name: yardCaseSubTypes[id],
      value: Number(id),
    }))
);
