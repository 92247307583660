import React from 'react';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

interface Props {
  direction: string;
  handleClick: (e: any) => void;
}

const Arrow = (props: Props) => {
  const { direction, handleClick } = props;

  const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    ${direction === 'right' ? `right: 50px` : `left: 50px`};
    z-index: 1;
    margin: auto;
    padding: ${Sizes.padding.small}px;
    width: 40px;
    height: 40px;

    background-color: #000a;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: transform ease-in 0.1s;
    &:hover {
      transform: scale(1.1);
    }
    img {
      transform: translateX(${direction === 'left' ? '-2' : '2'}px);
      &:focus {
        outline: 0;
      }
    }
  `;

  return (
    <Wrapper onClick={handleClick}>
      {direction === 'right' ? (
        <FontAwesomeIcon icon={faAngleRight} />
      ) : (
        <FontAwesomeIcon icon={faAngleLeft} />
      )}
    </Wrapper>
  );
};

export default Arrow;
