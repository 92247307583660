import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors, { translucent } from 'constants/Colors';
import YardCaseCommentModel from 'gen/YardCaseCommentModel';
import { toUserDateTimeString, copyJSON } from 'utils';
import CommentHeaderTypeIcon from './CommentHeaderTypeIcon';
import { useSelector, useDispatch } from 'react-redux';
import { makeSelectUserNames } from 'store/basicData/selectors';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownSection from 'components/Dropdown/DropDownSection';
import ListRow from 'components/Dropdown/ListRow';
import { selectActiveYardCase } from 'store/yardCase/selectors';
import { useDispatchBind } from 'hooks';
import { pushModal, popModal } from 'store/modal/actions';
import ErrorModal from 'components/modal/Error';
import Api from 'api';
import YardCaseModel from 'gen/YardCaseModel';
import { setActiveYardCase } from 'store/yardCase/actions';
import ConfirmModal from 'components/modal/Confirm';
import EditComment from './EditComment';
import AttachmentItems from './AttachmentItems';
import ImagePreviews from './ImagePreviews';
import YardCaseCommentAttachmentModel from 'gen/YardCaseCommentAttachmentModel';
import CommentHeaderType from 'gen/CommentHeaderType';
import { RestoreYardCaseButtonText } from '../../YardCaseActionButtons/RestoreYardCaseButton';
import YardCaseStatus from 'gen/YardCaseStatus';

const Wrapper = styled.div<{ isInvoice: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: ${Sizes.radius.element}px;
  box-shadow: ${Colors.boxShadow.element};
  padding: ${Sizes.padding.medium}px;
  padding-top: ${Sizes.padding.mini}px;
  margin: 0 -${Sizes.padding.mini}px;
  margin-bottom: ${Sizes.padding.small}px;
  overflow: hidden;
  overflow-wrap: break-word;

  ${({ isInvoice }) =>
    isInvoice &&
    css`
      border: 2px solid ${translucent(Colors.background.accent)};
    `}
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  color: ${translucent(Colors.foreground.mainLight)};
  margin-bottom: ${Sizes.padding.mini}px;
`;

const IconDateWrap = styled.span`
  & > *:first-child {
    font-size: 14px;
    margin-right: ${Sizes.padding.small}px;
  }
`;

const ActionsButton = styled.button<{ greyOut: boolean }>`
  background: transparent;
  border: none;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin-left: ${Sizes.padding.mini}px;
  margin-right: -${Sizes.padding.small}px;

  :hover {
    background-color: ${translucent(Colors.background.mainDark)};
  }

  :active {
    background-color: ${translucent(Colors.foreground.mainLight)};
  }

  :focus {
    outline: none;
    box-shadow: 0 0 0 1px ${Colors.focus};
  }

  ${({ greyOut }) =>
    greyOut &&
    css`
      & > * {
        opacity: 0.1;
      }
      :hover {
        background-color: transparent;
      }
    `}
`;

const ActionItem = styled(ListRow)`
  color: ${Colors.foreground.mainLight};
`;

const EditDisabledText = styled(ListRow)`
  color: ${translucent(Colors.foreground.main)};
`;

const CommentText = styled.div`
  padding-top: ${Sizes.padding.mini}px;
  white-space: pre-wrap;
`;

interface Props {
  comment: YardCaseCommentModel;
  yardCaseStatus: YardCaseStatus;
  locked: boolean;
}

const Comment: React.SFC<Props> = ({ comment, yardCaseStatus, locked }) => {
  const [actionsVisible, setActionsVisible] = useState(false);
  const [editingComment, setEditingComment] = useState(false);
  const actionsButtonIgnoreClickClassName = useRef(
    `actionsButtonIgnoreClick-${comment.id}`
  );

  const users = useSelector(makeSelectUserNames);
  const userName = (users && users[comment.modifiedByID]) || '-';
  const yardCase = useSelector(selectActiveYardCase);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);
  const onPopModal = useDispatchBind(popModal, dispatch);
  const onSetActiveYardCase = useDispatchBind(setActiveYardCase, dispatch);

  const handleDeleteCommentClick = () => {
    setActionsVisible(false);
    const newYardCase = copyJSON(yardCase!);
    newYardCase.comments.forEach((c) => {
      if (c.id === comment.id) {
        c.isRemoved = true;
      }
    });

    onPushModal(
      <ConfirmModal
        onConfirm={() => {
          saveYardCase(newYardCase);
          onPopModal();
        }}
        confirmLabel="Ta bort"
        cancelLabel="Avbryt"
      >
        Är du säker på att du vill ta bort kommentaren?
      </ConfirmModal>
    );
  };

  const handleDeleteAttachment = (
    attachment: YardCaseCommentAttachmentModel
  ) => {
    const newYardCase = copyJSON(yardCase!);
    const updatedComment = newYardCase.comments.find(
      (c) => c.id === comment.id
    );
    const toRemove =
      updatedComment &&
      updatedComment.attachments.find((a) => a.id === attachment.id);

    if (toRemove) {
      toRemove.isRemoved = true;
      onPushModal(
        <ConfirmModal
          confirmLabel="Ta bort"
          cancelLabel="Avbryt"
          onConfirm={() => {
            saveYardCase(newYardCase);
          }}
        >
          Är du säker på att du vill ta bort filen "{attachment.displayName}"?
        </ConfirmModal>
      );
    } else {
      console.error('Could not find attachment to remove :/');
    }
  };

  const handleEditCommentClick = () => {
    setActionsVisible(false);
    setEditingComment(true);
  };

  const handleSaveEditedComment = (updatedComment: string) => {
    const newYardCase = copyJSON(yardCase!);
    const toModify = newYardCase.comments.find((c) => c.id === comment.id);
    if (toModify) {
      toModify.text = updatedComment;
    }
    setEditingComment(false);
    saveYardCase(newYardCase);
  };

  const saveYardCase = async (toSave: YardCaseModel) => {
    try {
      const response = await Api.saveYardCase(toSave);
      onSetActiveYardCase(response);
    } catch (err) {
      pushModal(
        <ErrorModal exception={err}>
          Det gick inte att uppdatera ärendet
        </ErrorModal>
      );
    }
  };

  const filteredAttachments = comment.attachments.filter(
    (atta) => !atta.isRemoved
  );

  const isInvoice =
    comment.commentHeaderType === CommentHeaderType.FinalInvoice;
  const disableDeleteEdit =
    yardCaseStatus === YardCaseStatus.Returned ||
    yardCaseStatus === YardCaseStatus.Scrapped;

  return (
    <Wrapper isInvoice={isInvoice}>
      <Head>
        <IconDateWrap>
          <CommentHeaderTypeIcon headerType={comment.commentHeaderType} />
          {toUserDateTimeString(new Date(comment.dateModified))}
        </IconDateWrap>
        <span>
          {userName}
          {!isInvoice && (
            <>
              <ActionsButton
                onClick={() => setActionsVisible((v) => !v)}
                greyOut={disableDeleteEdit}
                className={actionsButtonIgnoreClickClassName.current}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </ActionsButton>
              <DropdownSection
                visible={actionsVisible}
                onClickOutside={() => setActionsVisible(false)}
                ignoreClickOutsideClassName={
                  actionsButtonIgnoreClickClassName.current
                }
                fitChildren
                alignRight
              >
                {disableDeleteEdit ? (
                  <EditDisabledText>
                    Klicka på "{RestoreYardCaseButtonText}" för att kunna
                    redigera och ta bort kommentarer.
                  </EditDisabledText>
                ) : (
                  <>
                    <ActionItem clickable onClick={handleDeleteCommentClick}>
                      Ta bort
                    </ActionItem>
                    <ActionItem clickable onClick={handleEditCommentClick}>
                      Redigera
                    </ActionItem>
                  </>
                )}
              </DropdownSection>
            </>
          )}
        </span>
      </Head>
      <ImagePreviews
        editMode={editingComment}
        onDeleteAttachment={handleDeleteAttachment}
        attachments={filteredAttachments}
      />
      <AttachmentItems
        attachments={filteredAttachments}
        editMode={editingComment}
        onDeleteAttachment={handleDeleteAttachment}
        locked={locked}
      />
      {editingComment ? (
        <EditComment
          initialValue={comment.text}
          onSave={handleSaveEditedComment}
          onAbort={() => setEditingComment(false)}
        />
      ) : (
        <CommentText>{comment.text}</CommentText>
      )}
    </Wrapper>
  );
};

export default Comment;
