import YardCasePreviewModel from 'gen/YardCasePreviewModel';
import SearchActionTypes from './ActionTypes';

export const setSearchResults = (results: YardCasePreviewModel[] | null) => ({
  type: SearchActionTypes.SetResults,
  results,
});
export const setSearchString = (searchString: string) => ({
  type: SearchActionTypes.SetSearchString,
  searchString,
});

export const setEnableDateInput = (enableDateInput: boolean) => ({
  type: SearchActionTypes.SetEnableDateInput,
  enableDateInput,
});
export const setDateSpanStart = (dateSpanStart?: Date) => ({
  type: SearchActionTypes.SetDateSpanStart,
  dateSpanStart,
});
export const setDateSpanEnd = (dateSpanEnd?: Date) => ({
  type: SearchActionTypes.SetDateSpanEnd,
  dateSpanEnd,
});
