import {
  ActionReturns,
  ImmutableReducer,
  DeepReadonly,
} from '../../utils/utilityTypes';
import * as BasicDataActions from './actions';
import BasicDataModel from 'gen/BasicDataModel';
import BasicDataActionTypes from './ActionTypes';

export type BasicDataState = DeepReadonly<BasicDataModel> | null;

type actions = ActionReturns<typeof BasicDataActions>;

const basicDataReducer: ImmutableReducer<BasicDataState, actions> = (
  state = null,
  action
) => {
  switch (action.type) {
    case BasicDataActionTypes.UpdateBasicData:
      return action.basicData;

    default:
      return state;
  }
};

export default basicDataReducer;
