
enum CommentHeaderType {
    InitialImages = 1,
    ImagesOnReturn = 2,
    OrderEmail = 3,
    Other = 4,
    FinalInvoice = 5,
    CancelCase = 6,
}

export default CommentHeaderType;