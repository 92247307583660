import { useRef, useCallback } from 'react';
import { Dispatch, AnyAction } from 'redux';

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  const previous = ref.current;
  ref.current = value;
  return previous;
};

export const useDispatchBind = <Args extends Array<any>>(
  action: (...args: Args) => AnyAction,
  dispatch: Dispatch<any>
) => useCallback((...args: Args) => dispatch(action(...args)), [dispatch]);
