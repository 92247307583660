import React from 'react';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import FinalizeYardCaseButton from './FinalizeYardCaseButton';
import RestoreYardCaseButton from './RestoreYardCaseButton';
import CancelYardCaseButton from './CancelYardCaseButton';

const Wrapper = styled.div`
  position: fixed;
  bottom: ${Sizes.padding.large}px;
  display: flex;
  flex-direction: row;
  gap: ${Sizes.padding.small}px;
  padding: ${Sizes.padding.small}px ${Sizes.padding.mini}px;
  width: max-content;
`;

const RightAlignment = styled.div`
  position: fixed;
  bottom: ${Sizes.padding.large}px;
  display: flex;
  flex-direction: row;
  gap: ${Sizes.padding.small}px;
  padding: ${Sizes.padding.small}px;
  padding-right: ${Sizes.padding.large + Sizes.padding.mini}px;
  width: max-content;
  align-self: flex-end;
`;

const YardCaseActionButtons: React.FC = () => {
  return (
    <>
      <Wrapper>
        <RestoreYardCaseButton />
        <FinalizeYardCaseButton scrapYardCase={false} />
        <FinalizeYardCaseButton scrapYardCase={true} />
      </Wrapper>

      <RightAlignment>
        <CancelYardCaseButton />
      </RightAlignment>
    </>
  );
};

export default YardCaseActionButtons;
