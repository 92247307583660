import React from 'react';
import styled, { keyframes } from 'styled-components';
import Colors from 'constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faSave,
  faKeyboard,
  faTimes,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import EditStatus from 'store/yardCase/YardCaseEditStatus';

export const Container = styled.div`
  display: inline-block;
  margin: 0 20px;
  font-size: 18px;
  font-weight: normal;
`;

const pulsingKeyFrames = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

export const SavingIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  animation: ${pulsingKeyFrames} 0.7s ease-in-out 0s infinite;
`;

export const TypingIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
`;

export const SavedIcon = styled(FontAwesomeIcon)`
  color: ${Colors.good};
  margin-left: 5px;
`;

export const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${Colors.bad};
  margin-left: 5px;
`;

interface PropTypes {
  editStatus: EditStatus;
}

const SavingIndicator: React.SFC<PropTypes> = ({ editStatus }) => {
  switch (editStatus) {
    case EditStatus.blockedSaving:
    case EditStatus.saving:
      return (
        <Container>
          <SavingIcon icon={faSave} />
        </Container>
      );

    case EditStatus.typing:
      return (
        <Container>
          <TypingIcon icon={faKeyboard} />
        </Container>
      );

    case EditStatus.saved:
      return (
        <Container>
          Sparat <SavedIcon icon={faCheck} />
        </Container>
      );

    case EditStatus.formInvalid:
      return (
        <Container>
          Inte sparat, kontrollera alla fält <ErrorIcon icon={faTimes} />
        </Container>
      );
  }
};

export default SavingIndicator;
