import React from 'react';
import DefaultModal from 'components/modal/Default';
import CommentInput from './SideTray/AttachmentsTab/AddComment/CommentInput';
import useCommentInputState from './SideTray/AttachmentsTab/AddComment/useCommentInputState';
import Colors from 'constants/Colors';
import styled from 'styled-components';
import { useDispatchBind } from 'hooks';
import { popModal } from 'store/modal/actions';
import { useDispatch } from 'react-redux';

const MyModal = styled(DefaultModal)`
  align-items: flex-start;
  width: 450px;
`;

const MyCommentInput = styled(CommentInput)`
  background-color: ${Colors.background.mainLight};
  width: 100%;
`;

const ValueAssertedCheckedModal: React.SFC = () => {
  const commentInputState = useCommentInputState();

  const dispatch = useDispatch();
  const onPopModal = useDispatchBind(popModal, dispatch);

  return (
    <MyModal
      title="Värdering"
      buttons={[
        {
          label: 'Spara',
          onClick: () => {
            commentInputState.handleSendClick();
            onPopModal();
          },
        },
        { label: 'Avbryt', onClick: onPopModal },
      ]}
    >
      <MyCommentInput state={commentInputState} />
    </MyModal>
  );
};

export default ValueAssertedCheckedModal;
