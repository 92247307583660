
enum BuildSettings {
    NOT_SET = 0,
    ENVIRONMENT_BASED = 1,
    Production = 2,
    TestEnvironment = 3,
    LocalSandbox = 4,
    UnitTestInMemory = 5,
}

export default BuildSettings;