import React, { useState } from 'react';
import Api from 'api';

const TestPage = () => {
  const [x, setX] = useState('');
  const [links, setLinks] = useState<string[]>([]);

  const send = async () => {
    const response = await Api.makePdf(x);
    setLinks([...links, `data:application/pdf;base64,${response}`]);
  };

  return (
    <div>
      Test Page
      <textarea value={x} onChange={e => setX(e.target.value)} />
      <button onClick={send}>send!</button>
      {links.map((link, index) => (
        <a href={link}>file {index}</a>
      ))}
    </div>
  );
};

export default TestPage;
