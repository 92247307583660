import React, { useState } from 'react';
import DefaultModal from 'components/modal/Default';
import Input from 'components/inputs/Input';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { popModal, pushModal } from 'store/modal/actions';
import { useDispatchBind } from 'hooks';
import ErrorModal from 'components/modal/Error';
import Api from 'api';
import { copyJSON } from 'utils';
import LoadingSpinner from 'components/LoadingSpinner';
import { selectBasicData } from 'store/basicData/selectors';
import BasicDataModel from 'gen/BasicDataModel';
import { updateBasicData } from 'store/basicData/actions';
import YardLocationModel from 'gen/YardLocationModel';
import { InputRowsContainer, InputRow, InputWrap } from './components';
import Toggle from 'components/inputs/Toggle';

const MahModal = styled(DefaultModal)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

interface Props {
  title: string;
  location: YardLocationModel;
}

const EditYardLocationModal: React.SFC<Props> = ({ location, title }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [locationEdit, setLocationEdit] = useState(
    copyJSON<YardLocationModel>(location)
  );

  const basicData = useSelector(selectBasicData);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);
  const onPopModal = useDispatchBind(popModal, dispatch);
  const onUpdateBasicData = useDispatchBind(updateBasicData, dispatch);

  const handleSaveClick = async () => {
    try {
      setIsSaving(true);
      const updatedLocation = await Api.saveYardLocation(locationEdit);
      const newBasicData = copyJSON<BasicDataModel>(basicData!);

      // insert new location into basicData
      const index = newBasicData.yardLocations.findIndex(
        (location) => location.id === updatedLocation.id
      );

      if (index < 0) {
        newBasicData.yardLocations.push(updatedLocation);
      } else {
        newBasicData.yardLocations[index] = updatedLocation;
      }
      onUpdateBasicData(newBasicData);
    } catch (err) {
      onPushModal(
        <ErrorModal exception={err}>
          Ett fel inträffade när gården skulle läggas till. Prova att ladda om
          sidan innan du försöker lägga till gården igen.
        </ErrorModal>
      );
    }
    onPopModal();
    setIsSaving(false);
  };

  const handleCancelClick = () => {
    onPopModal();
  };

  if (isSaving) {
    return (
      <MahModal buttons={[]}>
        <LoadingSpinner>Sparar gård</LoadingSpinner>
      </MahModal>
    );
  }

  return (
    <MahModal
      title={title}
      buttons={[
        { label: 'Spara', onClick: handleSaveClick },
        { label: 'Avbryt', onClick: handleCancelClick },
      ]}
    >
      <InputRowsContainer>
        <InputWrap>
          Namn
          <Input
            type="text"
            value={locationEdit.name}
            onChange={(eve) =>
              setLocationEdit({ ...locationEdit, name: eve.target.value })
            }
          />
        </InputWrap>
        <InputWrap>
          Ta bort som alternativ i registrerings-formuläret
          <Toggle
            value={locationEdit.isRemoved}
            onToggle={(value) =>
              setLocationEdit({ ...locationEdit, isRemoved: value })
            }
          />
        </InputWrap>
      </InputRowsContainer>
    </MahModal>
  );
};

export default EditYardLocationModal;
