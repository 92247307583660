import React, { useEffect, useRef } from 'react';
import {
  IFormValues,
  IFormValidations,
  IFormValidation,
  IFormValue,
} from './types/IFormValues';
import { useFormState, useForm } from 'react-final-form';
import IFormSection from './types/IFormSection';
import IFormSectionField, {
  IInvalidEvaluator,
  ISelectOption,
} from './types/IFormSectionField';
import FieldType from './types/FieldType';
import { AnyObject, FormApi } from 'final-form';
import { usePrevious } from 'hooks';
import deepEqual from 'deep-equal';

/** Metadata that FormSectionField can handle*/
export interface IFieldMetaData {
  validation?: IFormValidation;
}

interface Props {
  formSections: IFormSection[];
  formChanged(
    formValues: IFormValues,
    formValidations: IFormValidations,
    formApi: FormApi<AnyObject>
  ): void;
}

/** Listens for changes in FinalForm and performs some evaluations on it. Doesn't render anything*/
const FormChangeHandler: React.FunctionComponent<Props> = ({
  formSections,
  formChanged,
}) => {
  // subscribe to active field name,
  // field values and field errors (validations made by FormSectionFields)
  const formApi = useForm();
  const formState = useFormState<IFormValues>({
    subscription: {
      values: true,
      errors: true,
    },
  });

  const previousValues = usePrevious(formState.values);
  const previousErrors = usePrevious(formState.errors);

  useEffect(() => {
    if (
      deepEqual(previousErrors, formState.errors) &&
      deepEqual(previousValues, formState.values)
    ) {
      // sometimes for some reason, the state objects have same values, but different instances
      return;
    }

    const formValues = formState.values;
    const formValidations: IFormValidations = {};
    const allFields: IFormSectionField[] = formSections.reduce(
      (acc, formSection) => {
        formSection.headerFields &&
          formSection.headerFields.forEach((field) => acc.push(field));
        formSection.fields.forEach((field) => acc.push(field));
        return acc;
      },
      [] as IFormSectionField[]
    );

    // copy over validations from FormSectionFields
    // (keeping the validation in the FinalForm fields
    // prevents FinalForm.Form.onSubmit() if there are any errors)
    const formStateErrors: { [name: string]: ReturnType<IInvalidEvaluator> } =
      formState.errors ?? {};
    for (let name in formStateErrors) {
      const value = formStateErrors[name];
      if (value !== false && value !== undefined) {
        formValidations[name] = { invalid: value };
      }
    }

    // evaluate rest of validations for each field
    for (let field of allFields) {
      const fieldState = formApi.getFieldState(field.name);
      const previousFieldValidation: IFormValidation | undefined =
        fieldState && fieldState.data && fieldState.data.validation;

      formValidations[field.name] = formValidations[field.name] || {};

      formValidations[field.name].disabled =
        field.disabled === true ||
        (field.disabled &&
          field.disabled(formValues[field.name], formValues, field.name));

      formValidations[field.name].required =
        field.required === true ||
        (field.required &&
          field.required(formValues[field.name], formValues, field.name));

      formValidations[field.name].warning =
        field.warning &&
        field.warning(formValues[field.name], formValues, field.name);

      // Mark field as invalid if field is empty and required
      // (if field's validator returned an error, it was copied over in the step above and is prioritized)
      formValidations[field.name].invalid =
        formValidations[field.name].invalid ||
        (fieldIsEmpty(field.type, formValues[field.name]) &&
          formValidations[field.name].required &&
          'Ange ett värde');

      // Generate select options if the field type requires them
      switch (field.type) {
        case FieldType.SELECT:
        case FieldType.SELECT_NO_DEFAULT:
        case FieldType.EDITABLE_SELECT:
        case FieldType.MULTI_SELECT:
          formValidations[field.name].selectOptions = Array.isArray(
            field.selectOptions
          )
            ? field.selectOptions
            : field.selectOptions(formValues);
          break;
      }

      const optionsDiffer =
        !previousFieldValidation ||
        selectOptionsDiffer(
          formValidations[field.name].selectOptions,
          previousFieldValidation.selectOptions
        );

      // reset select field value if selected option doesn't exist anymore
      if (
        optionsDiffer &&
        formValidations[field.name].selectOptions &&
        !formValidations[field.name].selectOptions!.find(
          (option) => option.value === formValues[field.name]
        )
      ) {
        formApi.change(field.name, -1);
      }

      if (
        optionsDiffer ||
        validationsDiffer(formValidations[field.name], previousFieldValidation)
      ) {
        const fieldMetaData: IFieldMetaData = {
          validation: { ...formValidations[field.name] },
        };
        // this mutator is added in the Form component
        formApi.mutators.setFieldData(field.name, fieldMetaData);
      }
    }

    formChanged(formState.values, formValidations, formApi);
  }, [formState.values, formState.errors]);

  return null;
};

const validationsDiffer = (
  validation: IFormValidation,
  previousValidation?: IFormValidation
) => {
  if (!previousValidation) {
    return (
      validation.disabled ||
      validation.invalid ||
      validation.required ||
      validation.warning
    );
  } else {
    return (
      validation.disabled !== previousValidation.disabled ||
      validation.invalid !== previousValidation.invalid ||
      validation.required !== previousValidation.required ||
      validation.warning !== previousValidation.warning
    );
  }
};

const selectOptionsDiffer = (
  a: ISelectOption[] | undefined,
  b: ISelectOption[] | undefined
) => {
  if (!!a !== !!b) {
    return true;
  }
  if (a && b) {
    if (a.length !== b.length) {
      return true;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i].label !== b[i].label || a[i].value !== b[i].value) {
        return true;
      }
    }
  }

  return false;
};

const fieldIsEmpty = (fieldType: FieldType, fieldValue: IFormValue) => {
  switch (fieldType) {
    case FieldType.SELECT_NO_DEFAULT:
      return !fieldValue || (typeof fieldValue === 'number' && fieldValue < 0);
    default:
      return !fieldValue;
  }
};

export default FormChangeHandler;
