import { createSelector } from 'reselect';
import { ApplicationState } from 'store';

const selectSearch = (state: ApplicationState) => state.search;

export const makeSelectSearchResults = createSelector(
  selectSearch,
  (state) => state.results
);

export const makeSelectSearchString = createSelector(
  selectSearch,
  (state) => state.searchString
);

export const makeSelectEnableDateInput = createSelector(
  selectSearch,
  (state) => state.enableDateInput
);

export const makeSelectDateSpanStart = createSelector(
  selectSearch,
  (state) => state.dateSpanStart
);

export const makeSelectDateSpanEnd = createSelector(
  selectSearch,
  (state) => state.dateSpanEnd
);
