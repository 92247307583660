import React from 'react';
import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

const RequiredSymbol = styled.span`
  font-size: 8px;
  color: ${({ beGentle }: { beGentle: boolean }) =>
    beGentle ? Colors.background.mainDark : Colors.bad};
`;

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: ${Sizes.font.input}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: inherit;
  margin-bottom: 0;
  box-shadow: none;
  min-height: 1.4em;
`;

interface LabelProps {
  /** show a red asterisk */
  asterisk?: boolean;
  /** make asterisk less prominent */
  beGentle?: boolean;
}

const FieldLabel: React.FunctionComponent<LabelProps> = props => {
  const { children, asterisk = false, beGentle = false } = props;
  return (
    <StyledLabel>
      {children}
      {asterisk && (
        <RequiredSymbol beGentle={beGentle}>
          <FontAwesomeIcon icon={faAsterisk} />
        </RequiredSymbol>
      )}
    </StyledLabel>
  );
};

export default FieldLabel;
