import styled from 'styled-components';
import Sizes from 'constants/Sizes';
import Colors from 'constants/Colors';

const Card = styled.div`
  border-radius: ${Sizes.radius.card}px;
  box-shadow: ${Colors.boxShadow.card};
  overflow: hidden;
  background-color: ${Colors.background.mainLight};
`;

export default Card;
